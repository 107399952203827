import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import * as Constants from '../../../../config/Constants'
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility'
import { useParams, useHistory } from 'react-router-dom'
import getText from '../../../shared/i18n/labels'

import { Tabs } from '../../../shared/Tabs'
import { RelationshipsList } from './NewCoraRelationshipsList'

// COMPONENTS
import { MonthlyList } from '../communications/NewMonthlyList'
import { ConsistencyHistoryList } from '../receipts/NewConsistencyHistoryList'
import { CommunicationsTab } from '../communications/CommunicationsTab'
import { YearlyBalanceList } from '../balances/NewYearlyBalanceList'
import { CheckLicenseAndRedirect } from '../../../shared/auth/license/LicenseChecker'

import calendariotab from '../../../../styles/images/calendarTab.png'
import arrow_l from '../../../../styles/images/arrow_l.png'
import elenco from '../../../../styles/images/elenco.png'
import spedire from '../../../../styles/images/spedire.png'
import storico from '../../../../styles/images/storico.png'
import photo from '../../../../styles/images/camera.png'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'

//pulisce la riga di errore della fotografia
export function cleanErrorString(string) {
  if (string && string.includes('<<')) {
    let arr = string.split('<')
    arr.shift()
    return arr.join('<<')
  }
  return string
}

export function Relationships({ setId }) {
  const params = useParams()

  if (params.id) setId(params.id)

  const dispatch = useDispatch()
  const history = useHistory()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [subjectId, setSubjectId] = useState('')
  const [filterParams, setFilterParams] = useState({})
  const [showTotalRelationshipResults, setShowTotalRelationshipResults] = useState(false)

  const prevCustomerApplicationsRef = useRef()

  const auth = useSelector((state) => state.auth)
  const relationships = useSelector((state) => state.relationships.data)
  const subject = useSelector((state) => state.subject.data)

  const [activeTab, setActiveTab] = useState('relationships')
  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  useEffect(() => {
    const currentCustomerApplications = auth?.user?.customer?.customerApplications
    const prevCustomerApplications = prevCustomerApplicationsRef.current

    // Confronta se le customerApplications sono cambiate
    if (prevCustomerApplications !== currentCustomerApplications) {
      // Esegui CheckLicenseAndRedirect solo se c'è stato un cambiamento
      CheckLicenseAndRedirect(
        history,
        '/app/cora/dashboard',
        currentCustomerApplications,
        Constants.APPLICATION_CORA,
        setId
      )
    }

    // Aggiorna la variabile di riferimento con il valore attuale
    prevCustomerApplicationsRef.current = currentCustomerApplications
  }, [auth?.user?.customer?.customerApplications, history])

  useEffect(() => {
    setSubjectId(params.id)
    getSubject(params.id)
    getRelationshipLocations()
    getAllCurrency()
    getAllRole()
    getCommunicationTypes()
    getRelationshipTypes()
  }, [])

  const getRelationshipLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getAllCurrency = () => {
    dispatch(actions.getAllCurrency())
  }

  const getAllRole = () => {
    dispatch(actions.getAllRole())
  }

  const getSubject = (subjectId) => {
    dispatch(actions.getCoraSubject(subjectId))
  }

  const getCommunicationTypes = () => {
    dispatch(actions.getAllCommunicationTypes())
  }

  const getRelationshipTypes = () => {
    dispatch(actions.getRelationshipTypes())
  }

  let newTitle =
    subject?.personType === Constants.PNF
      ? subject?.companyName
        ? subject?.companyName
        : ''
      : subject?.lastName && subject?.firstName
        ? subject?.lastName + ' ' + subject?.firstName
        : ''

  const truncateTitle = () => {
    let truncate = 60
    if (window.innerWidth < 1050) {
      truncate = 8
    } else if (window.innerWidth < Constants.BREAKPOINT_MD) {
      truncate = 12
    } else if (window.innerWidth <= 1300) {
      truncate = 27
    } else if (window.innerWidth <= Constants.BREAKPOINT_LG) {
      truncate = 35
    }
    if (newTitle.length > truncate) {
      return newTitle.slice(0, truncate) + '...'
    }
    return newTitle
  }

  const goToDashboard = () => {
    setId()
    history.push('/app/cora/dashboard')
  }

  const title = () => {
    return (
      <div className={`d-flex title-page justify-content-between m-0 p-0 me-3`}>
        <div className="cursor-pointer" onClick={goToDashboard}>
          <img
            src={arrow_l}
            className="ms-2 me-3"
            width="10"
            height="19"
            alt={labels.RETURN_TO_SUBJECT_LIST}
          />
          <StarTooltip text={window.innerWidth < Constants.BREAKPOINT_LG ? newTitle : ''}>
            <span>{truncateTitle()}</span>
          </StarTooltip>
        </div>
        <div>
          {isNotEmpty(delayedCommunicationDate) && (
            <div className="me-auto" style={{ fontSize: '1.5rem' }}>
              <strong className="pulse text-light px-2">
                {labels.DELAYED_COMMUNICATION_IMPOSED_AT}{' '}
                {formatDateForDisplay(delayedCommunicationDate)}
              </strong>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="table-custom">
        <Tabs className="mt-2" title={title()} activeTab={activeTab} setActiveTab={setActiveTab}>
          <div label={labels.LIST_OF_RELATIONSHIPS} icon={elenco} id={'relationships'}>
            <RelationshipsList
              subjectId={subjectId}
              subject={subject}
              relationships={relationships}
              filterParams={filterParams}
              setFilterParams={(params) => setFilterParams(params)}
              showTotalResults={showTotalRelationshipResults}
              onImportedBalances={() => setActiveTab('balance')}
              setShowTotalResults={(showTotalResults) => {
                setShowTotalRelationshipResults(showTotalResults)
              }}
            />
          </div>
          <div label={labels.RELATIONSHIPS_TO_SEND} icon={spedire} id={'toSend'}>
            <MonthlyList subjectId={subjectId} subject={subject} />
          </div>
          <div label={labels.ANNUAL_BALANCES} icon={calendariotab} id={'balance'}>
            <YearlyBalanceList subjectId={subjectId} />
          </div>
          <div label={labels.COMMUNICATION_HISTORY} icon={storico} id={'history'}>
            <CommunicationsTab subjectId={subjectId} />
          </div>
          <div label={labels.PHOTOGRAPHY_RESULTS} icon={photo} id={'photo'}>
            <ConsistencyHistoryList subjectId={subjectId} />
          </div>
        </Tabs>
      </div>
    </>
  )
}
