import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { FormRapporto } from './rapporti/FormRapporto'

import { exludedCommTypes } from '../../../../config/Constants'
import {
  getAllCommunicationTypes,
  getAllLocations,
  getAllRole,
  getRelationshipTypes,
  inviaEmailRapporti
} from '../../../../actions'
import { BwmInput } from '../../../shared/form/BwmInput'
import { downloadFile, formatDateForFile, isEmpty } from '../../../shared/Utility'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupConfirm, titleColors } from '../../../shared/popups/PopupConfirm'
//devono essere presenti dati sufficienti a importare un soggetto
//deve essere possibile selezionare il tipo di comunicazione, e inserire almeno i delegati
//la form è simile a quella su cora, ma i dati non vengono salvati a DB, bensì vengono inviati all'assistenza per essere elaborati.
//idealmente, sarebbe utile prevedere una forma di persistenza tra refresh della pagina
export function FormCora() {
  const dispatch = useDispatch()

  const [relationshipStorageName, setStorageName] = useState('')
  const [relationships, setRelationships] = useState([])
  const [relationshipsIndex, setRelationshipsIndex] = useState(0)

  const [denominazione, setDenominazione] = useState('')
  const [partitaIva, setPartitaIva] = useState('')
  const [emailCliente, setMailCliente] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  //valori di dominio
  const locations = useSelector((state) => state.locations.customLocations)
  const roles = useSelector((state) => state.roles.data)
  const communicationTypes = useSelector((state) =>
    state.communicationTypes.data.filter((el) => exludedCommTypes.indexOf(el.code) === -1)
  )
  const currencies = useSelector((state) => state.currencies.data)

  const MAX_RELATIONSHIP_COUNT = 20

  //effetto per reperire valori di dominio
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    setMailCliente(queryParameters.get('email'))
    setDenominazione(queryParameters.get('denominazione'))
    setPartitaIva(queryParameters.get('partitaIva'))
    dispatch(getAllLocations())
    dispatch(getAllCommunicationTypes())
    dispatch(getAllRole())
    dispatch(getRelationshipTypes())
  }, [])

  useEffect(() => {
    if (relationshipStorageName) {
      setRelationships(JSON.parse(localStorage.getItem(relationshipStorageName)) || [])
    }
  }, [relationshipStorageName])

  useEffect(() => {
    if (partitaIva) {
      setStorageName('facciamo_noi_tutto_rapporti-' + partitaIva)
    }
  }, [partitaIva])

  const storeRelationships = (rel = relationships) => {
    if (relationshipStorageName) {
      localStorage.setItem(relationshipStorageName, JSON.stringify(rel))
    }
  }

  const saveRelationship = (relationship) => {
    let temp = relationships
    relationships[relationshipsIndex] = relationship
    setRelationships(temp)
    storeRelationships(temp)
  }

  const handlePrevRelationship = () => {
    if (relationshipsIndex > 0) {
      setRelationshipsIndex(relationshipsIndex - 1)
    }
  }

  const handleNextRelationship = () => {
    if (relationshipsIndex <= MAX_RELATIONSHIP_COUNT) {
      setRelationshipsIndex(relationshipsIndex + 1)
    }
  }

  const handleDeleteCurrentRapporto = () => {
    let updatedRapporti = [...relationships]
    updatedRapporti.splice(relationshipsIndex, 1)

    if (relationshipsIndex >= updatedRapporti.length) {
      handlePrevRelationship()
    }

    setRelationships(updatedRapporti)
    storeRelationships(updatedRapporti)
  }

  const getLatestRelationshipId = () => {
    const rel = relationships[relationships.length - 1]
    return rel?.id || 0
  }

  const sendRelationships = () => {
    PopupConfirm({
      titleColor: titleColors.SUCCESS,
      title: 'CONFERMA INVIO',
      text: `Si desidera inviare allo staff ${relationships.length} rapporti per la comunicazione?`,
      handleClickConfirm: () => {
        let dto = {
          nomeCliente: denominazione,
          codiceFiscaleCliente: partitaIva,
          emailCliente: emailCliente,
          coraRelationships: relationships
        }
        setIsLoading(true)
        inviaEmailRapporti(dto)
          .then(
            (res) => {
              PopupConfirm({
                titleColor: titleColors.SUCCESS,
                title: 'INVIO EFFETTUATO',
                text: 'Dati inviati correttamente, si desidera scaricare i rapporti inviati?',
                confirm: 'SCARICA CSV RAPPORTI',
                cancel: 'CHIUDI',
                handleClickConfirm: () => {
                  downloadFile(res, `raporti_${denominazione}_${formatDateForFile(new Date())}.csv`)
                }
              })
            },
            (err) => {
              PopupError({ text: 'Si è verificato un errore durante il salvataggio dei dati' })
            }
          )
          .then(() => {
            setIsLoading(false)
          })
      }
    })
  }

  return (
    <>
      {isLoading && <PageSpinner />}
      <Row>
        <Col>NOME CLIENTE (utente StarSoluzioni): {emailCliente}</Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <h5>Dati del SOGGETTO OBBLIGATO che deve fare la comunicazione</h5>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <BwmInput
            label={'Partita IVA'}
            className={'form-control'}
            value={partitaIva}
            onChange={(e) => setPartitaIva(e.target.value)}
          />
        </Col>
        <Col lg={4}>
          <BwmInput
            label={'Denominazione'}
            className={'form-control'}
            value={denominazione}
            onChange={(e) => setDenominazione(e.target.value)}
          />
        </Col>
        <Col lg={4}>
          <button
            type="button"
            className="btn btn-primary mt-4"
            disabled={isEmpty(emailCliente)}
            onClick={sendRelationships}>
            <i className="thx-floppy-disk thx-icon me-2" />
            Invia rapporti
          </button>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col lg={4}>
          <h5>Rapporti da comunicare</h5>
        </Col>
        <Col lg={6} className="d-flex justify-content-end">
          <div className={` fw-bold`}>
            Rapporto numero: {relationshipsIndex + 1} di {relationships.length}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormRapporto
            key={'form-rapporto-' + relationshipsIndex}
            relationshipLocations={locations}
            communicationTypes={communicationTypes}
            currencies={currencies}
            roles={roles}
            relationship={relationships[relationshipsIndex]}
            saveRelationship={saveRelationship}
            index={getLatestRelationshipId()}
          />
        </Col>
      </Row>
      <Row className="justify-content-between mt-2">
        <Col>
          <button
            className="btn btn-outline-primary mt-2"
            disabled={isEmpty(relationships[relationshipsIndex]?.id)}
            onClick={() => handleDeleteCurrentRapporto(relationshipsIndex)}>
            <i className="thx-cancel thx-icon me-2" />
            Cancella rapporto
          </button>
        </Col>
      </Row>
      <Row className="justify-content-between mt-2">
        <Col>
          <button
            type="button"
            className={`btn btn-primary  w-25 `}
            onClick={handlePrevRelationship}
            disabled={relationshipsIndex <= 0}>
            <i className="thx-arrow-left thx-icon" />
          </button>
        </Col>
        <Col className="d-flex justify-content-end">
          <button
            type="button"
            className={`btn btn-primary   w-25 `}
            onClick={handleNextRelationship}
            disabled={relationshipsIndex >= MAX_RELATIONSHIP_COUNT - 1}>
            {relationships[relationshipsIndex + 1] ? (
              <i className="thx-right thx-icon" />
            ) : (
              <i className="thx-plus thx-icon" />
            )}
          </button>
        </Col>
      </Row>
      <Row></Row>
    </>
  )
}
