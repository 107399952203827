import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import { isValid } from 'date-fns'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import { ArcoRegistryModal } from './NewArcoRegistryModal'
import { ArcoRegistrationContractModal } from './NewArcoRegistrationContractModal'
import { ArcoRegistrationProfessionalsModal } from './NewArcoRegistrationProfessionalsModal'
import { ArcoRegistryPrintModal } from './NewArcoRegistryPrintModal'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { Tabs } from '../../../shared/Tabs'
import { ArcoRegistriesList } from './NewArcoRegistriesList'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

const defaultSize = (size = null) => ({
  profilingDate: Utility.getSize(size, { default: '8', md: '12' }),
  description: Utility.getSize(size, { default: '49', md: '40' }),
  description_36: Utility.getSize(size, { default: '31', md: '20' }),
  number: Utility.getSize(size, { default: '7', md: '10' }),
  nature: Utility.getSize(size, { default: '18', md: '19' }),
  nature_36: Utility.getSize(size, { default: '13' }),
  purpose: Utility.getSize(size, { default: '18', md: '19' }),
  purpose_36: Utility.getSize(size, { default: '14' }),
  goldPrice: Utility.getSize(size, { default: '13', md: '15' }),
  silverPrice: Utility.getSize(size, { default: '14', md: '16' }),
  // TAB REGISTRY
  customerTable: {
    table: Utility.getSize(size, { default: '25', lg: '26' }),
    checkbox: Utility.getSize(size, { default: '10', md: '15' }),
    client: Utility.getSize(size, { default: '90', md: '85' })
  },
  counterpartOrProfessionistTable: {
    table: Utility.getSize(size, { default: '25', lg: '30' }),
    checkbox: Utility.getSize(size, { default: '10', md: '15' }),
    professionistOrCounterpart: Utility.getSize(size, { default: '90', md: '85' })
  },
  contractsTable: {
    table: Utility.getSize(size, { default: '50', lg: '44' }),
    checkbox: Utility.getSize(size, { default: '7', md: '10' }),
    contractDate: Utility.getSize(size, { default: '12', lg: '15', md: '22' }),
    contract: Utility.getSize(size, { default: '61', lg: '58', md: '42' }),
    amount: Utility.getSize(size, { default: '20', lg: '24', md: '26' })
  },
  // TAB RISK OPERATION
  b1: Utility.getSize(size, { default: '22' }),
  b2: Utility.getSize(size, { default: '31' }),
  b3: Utility.getSize(size, { default: '21' }),
  b4: Utility.getSize(size, { default: '26' }),
  b5: Utility.getSize(size, { default: '29' }),
  b6: Utility.getSize(size, { default: '26' }),
  b7: Utility.getSize(size, { default: '28' }),
  b8: Utility.getSize(size, { default: '17' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

const addButton = (dataTip, onClick, disabled = false) => {
  const className = window.innerWidth <= Constants.BREAKPOINT_LG ? 'p-1' : ''

  return (
    <button
      type="button"
      disabled={disabled}
      className={`btn btn-outline-primary text-center me-1 ${className}`}
      data-tip={dataTip}
      onClick={onClick}>
      <i className="thx-plus thx-icon" />
    </button>
  )
}

const removeButton = (dataTip, onClick, disabled) => {
  const className = window.innerWidth <= Constants.BREAKPOINT_LG ? 'p-1' : ''

  return (
    <button
      type="button"
      className={`btn btn-outline-primary text-center ${disabled && 'disabled'} ${className}`}
      disabled={disabled}
      data-tip={dataTip}
      onClick={onClick}>
      <i className="thx-minus thx-icon" />
    </button>
  )
}

const printButton = (dataTip, onClick, disabled) => {
  const labels = getText(localStorage.getItem('language') || 'it')
  const className = window.innerWidth <= Constants.BREAKPOINT_LG ? 'p-1' : ''

  return (
    <button
      type="button"
      className={`btn btn-outline-primary text-center ${disabled && 'disabled'} ${className}`}
      disabled={disabled}
      data-tip={dataTip}
      onClick={onClick}>
      <i className="thx-print thx-icon" />
      {window.innerWidth > Constants.BREAKPOINT_MD && (
        <span className="ms-2">{labels.DOCUMENTS}</span>
      )}
    </button>
  )
}

export const ArcoRegistrationForm = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)
  registerLocale(lang, lang === 'it' ? it : en)

  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('tab-registry')

  const customer = useSelector((state) => state.auth.customer)
  const domains = useSelector((state) => state.arcoDomains.domains)

  const [arcoCausalOperationsFromStore, setArcoCausalOperationsFromStore] = useState([])

  const getArcoRegistriesAsCustomers = (currentOperation) => {
    let clientList = []
    if (currentOperation?.arcoRegistriesAsCustomers) {
      clientList = currentOperation?.arcoRegistriesAsCustomers
    }
    return clientList
  }

  const getArcoRegistriesAsProfessionals = (currentOperation) => {
    let countropartsList = []
    if (currentOperation?.arcoRegistriesAsProfessionals) {
      countropartsList = currentOperation?.arcoRegistriesAsProfessionals
    }
    return countropartsList
  }

  const getArcoProfessionals = (currentOperation) => {
    let clientList = []
    if (currentOperation?.arcoProfessionals) {
      clientList = currentOperation?.arcoProfessionals
    }
    return clientList
  }

  const getArcoContracts = (currentOperation) => {
    let contractsList = []
    if (currentOperation?.arcoContracts) {
      let contracts = currentOperation?.arcoContracts
      contracts.forEach((contract) => {
        delete contract.arcoOperation
      })
      contractsList = contracts
    }
    return contractsList
  }

  const getB = (currentOperation, type, list) => {
    let value
    domains.forEach((el) => {
      if (el.type === type?.toUpperCase()) {
        if (currentOperation[type] === el.description) value = el
        if (!currentOperation[type] && el.def) value = el
      }
    })
    if ([undefined, null, ''].includes(value)) value = list[0]
    return value
  }

  const getBList = (type) => {
    return domains
      ? domains
          .filter((arcoDomain) => arcoDomain.type === type)
          .map((el) => {
            el.description = el.description.toUpperCase()
            return el
          })
      : []
  }

  const setNewInitialValues = (currentOperation = {}, retrieve, arcoSubject = null) => {
    if (retrieve) {
      setActiveTab('tab-registry')
      setTouched(false)
    }
    const profilingDateDefault = new Date()
    profilingDateDefault.setHours(12, 0, 0, 0)
    setInitialValues({
      id: currentOperation.id,
      arcoRegistriesAsCustomers: getArcoRegistriesAsCustomers(currentOperation),
      arcoRegistriesAsProfessionals: getArcoRegistriesAsProfessionals(currentOperation),
      arcoProfessionals: getArcoProfessionals(currentOperation),
      arcoContracts: getArcoContracts(currentOperation),
      b1: getB(currentOperation, 'b1', getBList('B1')),
      b2: getB(currentOperation, 'b2', getBList('B2')),
      b3: getB(currentOperation, 'b3', getBList('B3')),
      b4: getB(currentOperation, 'b4', getBList('B4')),
      b5: getB(currentOperation, 'b5', getBList('B5')),
      b6: getB(currentOperation, 'b6', getBList('B6')),
      b7: getB(currentOperation, 'b7', getBList('B7')),
      b8: getB(currentOperation, 'b8', getBList('B8')),
      profilingDate: currentOperation?.id
        ? new Date(currentOperation.profilingDate)
        : profilingDateDefault,
      description: currentOperation?.id
        ? currentOperation.description
        : props.arcoSubject?.operationDescription || '',
      nature: currentOperation?.id
        ? currentOperation.nature
        : props.arcoSubject?.operationNature || '',
      number: currentOperation.number,
      purpose: currentOperation?.id
        ? currentOperation.purpose
        : props.arcoSubject?.operationPurpose || '',
      goldPrice: currentOperation.goldPrice || '',
      silverPrice: currentOperation.silverPrice || '',
      arcoSubject: props.arcoSubject || arcoSubject,
      customer: customer || currentOperation?.custumer
    })
  }

  useEffect(() => {
    if (!(arcoCausalOperationsFromStore?.length > 0)) {
      getAllArcoCausalOperations()
    }
  }, [props.arcoSubject])

  useEffect(() => {
    retrieveOperation(true)
  }, [props.id])

  useEffect(() => {
    if (domains?.length > 0) {
      actions.getArcoSubjectDetail(props.arcoSubjectId).then((res) => {
        retrieveOperation(true, res)
      })
    }
  }, [props.arcoSubjectId, domains])

  const getAllArcoCausalOperations = () => {
    const axiosInstance = axiosService.getInstance()
    if (props.arcoSubject?.arcoSubjectType) {
      axiosInstance
        .get(`/api/arco/custom/arco-causal-operations/${props.arcoSubject?.arcoSubjectType.code}`)
        .then((res) => {
          if (res) setArcoCausalOperationsFromStore(res.data)
        })
    }
  }

  const retrieveOperation = (retrieve = false, arcoSubject) => {
    setLoading(true)
    setTouched(false)
    if (props.id) {
      actions.getArcoOperationDetail(props.id).then((res) => {
        setNewInitialValues({ ...res.data, customer: customer || res.data?.custumer }, retrieve)
        setLoading(false)
      })
    } else {
      setNewInitialValues({}, retrieve, arcoSubject)
      setLoading(false)
    }
  }

  const close = (retrieve = false) => {
    if (retrieve) retrieveOperation()
    if (props.close) props.close()
  }

  const formErrorsMapping = [
    {
      errorKey: 'profilingDate',
      errorLabel: labels.PROFILING_DATE
    },
    {
      errorKey: 'nature',
      errorLabel: labels.NATURE
    },
    {
      errorKey: 'description',
      errorLabel: labels.OPERATION_DESCRIPTION
    },
    {
      errorKey: 'purpose',
      errorLabel: labels.SCOPE
    }
  ]

  const validationSchema = Yup.object().shape({
    profilingDate: Yup.date(labels.INSERT_VALID_DATE).required(labels.REQUIRED_FIELD),
    nature: Yup.string().required(labels.REQUIRED_FIELD),
    description: Yup.string().required(labels.REQUIRED_FIELD),
    purpose: Yup.string().required(labels.REQUIRED_FIELD)
  })

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsMapping)
  }

  return (
    <div className="pt-1 border-top">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom mb-0' : ''}>
            {showErrorAlert && touched && (
              <div className={props.formOnBottom ? 'arco-operation-form-on-bottom-error' : ''}>
                <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
              </div>
            )}
            <FormBody
              {...props}
              activeTab={activeTab}
              initialValues={initialValues}
              arcoCausalOperationsFromStore={arcoCausalOperationsFromStore}
              loading={loading}
              touched={touched}
              setNewInitialValues={setNewInitialValues}
              setLoading={setLoading}
              setActiveTab={setActiveTab}
              setShowErrorAlert={setShowErrorAlert}
              setTouched={setTouched}
              retrieveOperation={retrieveOperation}
              getBList={getBList}
              close={close}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [currentType, setCurrentType] = useState()
  const [printPopupList, setPrintPopupList] = useState()
  const [selectedPrintItem, setSelectedPrintItem] = useState()
  const [showPrintModal, setShowPrintModal] = useState(false)

  const [checkedArcoClientsValues, setCheckedArcoClientsValues] = useState({})
  const [checkedArcoProfessionistsValues, setCheckedArcoProfessionistsValues] = useState({})
  const [checkedArcoCounterpartValues, setCheckedArcoCounterpartValues] = useState({})
  const [checkedArcoContractsValues, setCheckedArcoContractsValues] = useState({})

  const [registryModalElement, setRegistryModalElement] = useState(null)
  const [showRegistryModal, setShowRegistryModal] = useState(false)

  const [selectedContract, setSelectedContract] = useState()
  const [showModalContract, setShowModalContract] = useState(false)

  const professionistsData = useSelector((state) => state.arcoProfessionists.data)
  const customer = useSelector((state) => state.auth.customer)

  const [showModal, setShowModal] = useState(false)
  const [popupType, setPopupType] = useState()
  const [detailId, setDetailId] = useState(null)

  const [loading, setLoading] = useState(false)

  const ARCO_PURPOSE_OPTIONS = [
    {
      name: labels.PURPOSE_INVESTMENT,
      key: 0
    },
    {
      name: labels.PURPOSE_DISINVESTMENT,
      key: 1
    },
    {
      name: labels.PURPOSE_PERSONAL_NEEDS,
      key: 2
    },
    {
      name: labels.PURPOSE_FAMILY_NEEDS,
      key: 3
    },
    {
      name: labels.PURPOSE_BUSINESS_NEEDS,
      key: 4
    },
    {
      name: labels.PURPOSE_REAL_ESTATE_TRANSACTION,
      key: 5
    }
  ]

  const ARCO_NATURE_OPTIONS = [
    {
      name: labels.NATURE_REAL_ESTATE,
      key: 0
    },
    {
      name: labels.NATURE_PROFESSIONAL_PERFORMANCE,
      key: 1
    },
    {
      name: labels.NATURE_ASSIGNMENT,
      key: 2
    },
    {
      name: labels.NATURE_MANDATE,
      key: 3
    }
  ]

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const handlerChangeDataProfilatura = (date) => {
    const form = Object.assign({}, values)
    if (isValid(date)) date.setHours(12, 0, 0, 0)
    form.profilingDate = date
    setValues(form)
  }

  const handlerChangeB = (e, type) => {
    const form = Object.assign({}, values)
    const list = props.getBList(type.toUpperCase())
    const element = list.find(
      (arcoDomain) => arcoDomain.id?.toString() === e.target.value?.toString()
    )
    form[type] = element ? element : null
    setValues(form)
  }

  const handleValidation = async () => {
    const errors = await validateForm(values)
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true)
      return false
    } else {
      props.setShowErrorAlert(false)
      return true
    }
  }

  const showPopUp = async (type, id) => {
    const isValid = await handleValidation()
    if (!isValid) return
    setDetailId(id)
    setShowModal(!showModal)
    setPopupType(type)
  }

  const showPrintPopup = (type, id = 0) => {
    if (type) setCurrentType(type)
    if (!id) saveFromPrintPopUp(type)
    else {
      const printItem = onSelectedPrintItem(type)
      if (!printItem) {
        setCurrentType(undefined)
        setPrintPopupList(undefined)
      }
      setShowPrintModal(!!printItem)
    }
  }

  const printOperationPdf = (savedOperation) => {
    if (savedOperation?.id) {
      props.setLoading(true)
      const axiosInstance = axiosService.getInstance()
      axiosInstance({
        url: `/api/arco/custom/arco-operations-summary-document/${savedOperation.id}`,
        method: 'GET'
      })
        .then(
          (response) => {
            if (response) {
              Utility.downloadFile(
                Utility.base64ToArrayBuffer(response.data),
                savedOperation.description?.substring(0, 30) + '.pdf'
              )
            }
          },
          (err) => {
            if (err.errorKey === 'arcoOperationCheck') PopupError({ text: err.title })
            else PopupError({ text: labels.ERROR_DOWNLOAD_DOCUMENT })
          }
        )
        .then(() => props.setLoading(false))
    }
  }

  /**
   *
   * @param {*} show determina se continuare a mostrare la schermata dell'operazione
   * @returns
   */
  const saveAll = async (show) => {
    const isValid = await handleValidation()
    if (!isValid) return

    const form = getFormData(values)
    if (props.disabled) return props.getOperations(show ? form : undefined)

    actions.saveArcoOperation(form).then(
      (response) => {
        props.setNewInitialValues(response)
        props.getOperations(show ? response : undefined)
        props.setActiveTab('tab-registry')
        if (!show) {
          PopupSuccess({ text: labels.DATA_SAVED_SUCCESSFULLY })
          props.close()
        }
      },
      (error) => PopupError({ text: labels.ERROR_SAVE_OPERATION })
    )
  }

  const saveBeforePrintOperation = async () => {
    const isValid = await handleValidation()
    if (!isValid) return

    if (props.disabled) {
      return printOperationPdf(getFormData(values))
    }

    setLoading(true)
    return actions.saveArcoOperation(getFormData(values)).then(
      (response) => {
        setLoading(false)
        props.getOperations()
        if (response?.id) printOperationPdf(response)
        setValues(response)
      },
      (error) => {
        setLoading(false)
        PopupError({ text: labels.ERROR_SAVE_OPERATION })
      }
    )
  }

  const onSelectedPrintItem = (popupPrintType) => {
    if (popupPrintType === 'CLIENTS') {
      const _checkedArcoClientsValues = cloneDeep(printPopupList || checkedArcoClientsValues)
      const key = Object.keys(_checkedArcoClientsValues)[0]
      const printItem = Object.values(_checkedArcoClientsValues)[0]
      setSelectedPrintItem(printItem)
      delete _checkedArcoClientsValues[key]
      setPrintPopupList(_checkedArcoClientsValues)
      return printItem
    } else {
      const _checkedArcoCounterpartValues = cloneDeep(
        printPopupList || checkedArcoCounterpartValues
      )
      const key = Object.keys(_checkedArcoCounterpartValues)[0]
      const printItem = Object.values(_checkedArcoCounterpartValues)[0]
      setSelectedPrintItem(printItem)
      delete _checkedArcoCounterpartValues[key]
      setPrintPopupList(_checkedArcoCounterpartValues)
      return printItem
    }
  }

  const saveFromPrintPopUp = async (type) => {
    const isValid = await handleValidation()
    if (!isValid) return

    actions
      .saveArcoOperation(getFormData(values))
      .then((response) => {
        props.setNewInitialValues(response)
        const printItem = onSelectedPrintItem(type)
        setShowPrintModal(!!printItem)
      })
      .catch(() => {
        PopupError({ text: labels.ERROR_SAVE_OPERATION })
      })
  }

  const getB = (formdata, type) => {
    formdata[`${type}Value`] = formdata[type]?.value || ''
    formdata[type] = formdata[type]?.description || ''
    return formdata
  }

  const getFormData = (formData) => {
    formData = getB(formData, 'b1')
    formData = getB(formData, 'b2')
    formData = getB(formData, 'b3')
    formData = getB(formData, 'b4')
    formData = getB(formData, 'b5')
    formData = getB(formData, 'b6')
    formData = getB(formData, 'b7')
    formData = getB(formData, 'b8')
    formData.customer = customer || formData.customer
    return formData
  }

  const saveAllAndKeepOpen = async (formData) => {
    const isValid = await handleValidation()
    if (!isValid) return
    formData = getFormData(formData)
    actions.saveArcoOperation(formData).then(
      (response) => {
        const form = Object.assign({}, values)
        form.id = response.id
        form.arcoRegistriesAsCustomers = response.arcoRegistriesAsCustomers
        form.arcoRegistriesAsProfessionals = response.arcoRegistriesAsProfessionals
        form.arcoProfessionals = response.arcoProfessionals
        form.arcoContracts = response.arcoContracts
        setValues(form)
        setShowModal(false)
        setShowRegistryModal(false)
        setShowModalContract(false)
        setShowPrintModal(false)
        props.getOperations()
      },
      (error) => PopupError({ text: labels.ERROR_SAVE_OPERATION })
    )
  }

  const insertSelectedProfessionists = (selected = []) => {
    const form = Object.assign({}, values)
    let newProf = cloneDeep(form.arcoProfessionals || [])
    selected.forEach((element) => {
      if (!newProf.find((e) => e.id === element.id)) newProf.push(element)
    })
    form.arcoProfessionals = newProf
    saveAllAndKeepOpen(form)
  }

  const addArcoContract = (newContract) => {
    const form = Object.assign({}, values)
    let contractsArr = cloneDeep(form.arcoContracts || [])
    if (newContract?.index === null || newContract?.index === undefined) {
      contractsArr.push(newContract)
    } else {
      contractsArr = contractsArr.map((el, index) => {
        if (index === newContract.index) return newContract
        return el
      })
    }
    form.arcoContracts = contractsArr
    setValues(form)
    saveAllAndKeepOpen(form)
  }

  const setClientList = (item) => {
    const form = Object.assign({}, values)
    let newClients = cloneDeep(form.arcoRegistriesAsCustomers || [])
    let hasItem = newClients.filter((el) => el.id === item.id).length > 0
    if (!hasItem) newClients = newClients.concat(item)
    form.arcoRegistriesAsCustomers = newClients
    setValues(form)
    saveAllAndKeepOpen(form)
  }

  const setContropartList = (item) => {
    const form = Object.assign({}, values)
    let newCounterpart = cloneDeep(form.arcoRegistriesAsProfessionals || [])
    let hasItem = newCounterpart.filter((el) => el.id === item.id).length > 0
    if (!hasItem) newCounterpart = newCounterpart.concat(item)
    form.arcoRegistriesAsProfessionals = newCounterpart
    setValues(form)
    saveAllAndKeepOpen(form)
  }

  const closeRegistryModal = (savedElement) => {
    //Solo aggiornamento
    const form = Object.assign({}, values)
    if (savedElement) {
      let customerToSaveIndex = form.arcoRegistriesAsCustomers.findIndex(
        (e) => e.id === savedElement.id
      )
      let counterpartToSaveIndex = form.arcoRegistriesAsProfessionals.findIndex(
        (e) => e.id === savedElement.id
      )
      if (customerToSaveIndex >= 0) {
        form.arcoRegistriesAsCustomers[customerToSaveIndex] = savedElement
      }
      if (counterpartToSaveIndex) {
        form.arcoRegistriesAsProfessionals[counterpartToSaveIndex] = savedElement
      }
      props.getOperations()
    }
    setRegistryModalElement(null)
    setShowRegistryModal(!showRegistryModal)
  }

  const hideProfessionalsModal = async () => {
    if (!(await handleValidation())) return
    setShowModal(!showModal)
  }

  const onSelectedItem = (item) => {
    if (popupType === 'CLIENTS') {
      setClientList(item)
    } else if (popupType === 'COUNTERPARTS') {
      setContropartList(item)
    }

    setShowModal(!showModal)
  }

  const handleCheckChange = (event, el, checkedItems, setCheckedItems) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[el.id] = el
      else delete newCheckedItems[el.id]
      setCheckedItems(newCheckedItems)
    }
  }

  const deleteItems = (key, checkedItems, setCheckedItems, type, message) => {
    const form = Object.assign({}, values)
    let newArray = cloneDeep(form[key])
    const promises = []
    Object.values(checkedItems).forEach((row) => {
      if (!row?.isNew) {
        promises.push(
          actions
            .deleteArcoRelationsOfOperation(values.arcoSubject.id, values.id, type, row.id)
            .then(() => (newArray = newArray.filter((elem) => row.id !== elem.id)))
        )
      }
    })

    Promise.all(promises)
      .then(() => {
        setCheckedItems({})
        PopupSuccess({ text: message })
        props.retrieveOperation()
        props.getOperations()
      })
      .catch((error) => {
        setCheckedItems({})
        form[key] = newArray
        PopupError({ text: error.title })
      })
  }

  // ROW CLIENTS
  const getSurnameOrDenomination = (item) => {
    return Constants.PF === item.personType
      ? item.lastName + ' ' + item.firstName
      : item.denomination
  }

  const showArcoRegistryDetail = async (element) => {
    const isValid = await handleValidation()
    if (!isValid) return
    setRegistryModalElement(element)
    setShowRegistryModal(!showRegistryModal)
  }

  const addClientButton = () => {
    return addButton(labels.ADD_CLIENTS, () => showPopUp('CLIENTS'), props.disabled)
  }

  const removeClientButton = () => {
    return removeButton(
      labels.REMOVE_CLIENTS,
      () =>
        deleteItems(
          'arcoRegistriesAsCustomers',
          checkedArcoClientsValues,
          setCheckedArcoClientsValues,
          'CLIENTS',
          labels.REMOVE_CLIENTS_SUCCESS
        ),
      Object.keys(checkedArcoClientsValues).length === 0 || props.disabled
    )
  }

  const printClientButton = () => {
    return printButton(
      labels.PRINT_DOCUMENT,
      () => showPrintPopup('CLIENTS'),
      Object.keys(checkedArcoClientsValues).length === 0 || props.disabled,
      labels
    )
  }

  const renderRowClients = (sizes) => {
    const form = Object.assign({}, values)
    if (form.arcoRegistriesAsCustomers?.length > 0) {
      return form.arcoRegistriesAsCustomers.map((arcoClient) => {
        return (
          <div
            key={`client-key-${arcoClient.id}`}
            className={'text-start row-table'}
            id={`client-${arcoClient.id}`}>
            <div className={`${sizes.checkbox} text-center div-td justify-content-center`}>
              <input
                type="checkbox"
                disabled={props.disabled}
                className="arcoCheckbox"
                id={`arcoClient-${arcoClient.id}`}
                value={!!checkedArcoClientsValues[arcoClient.id]}
                onClick={(e) =>
                  handleCheckChange(
                    e,
                    arcoClient,
                    checkedArcoClientsValues,
                    setCheckedArcoClientsValues
                  )
                }
              />
            </div>
            <div
              className={`${sizes.client} div-td text-truncate`}
              title={getSurnameOrDenomination(arcoClient)?.toUpperCase()}
              onClick={() => showArcoRegistryDetail(arcoClient)}>
              <strong>{getSurnameOrDenomination(arcoClient)?.toUpperCase()}</strong>
            </div>
          </div>
        )
      })
    }
    return []
  }

  // ROW PROFESSIONISTS AND COUNTERPARTS
  const isProfessionals = () => values.arcoSubject?.arcoSubjectType?.code === 'PB'

  const deleteArcoCounterpartOrProfessionalSubject = (type) => {
    if (type == 'COUNTERPARTS') {
      deleteItems(
        'arcoRegistriesAsProfessionals',
        checkedArcoCounterpartValues,
        setCheckedArcoCounterpartValues,
        'COUNTERPARTS',
        labels.REMOVE_COUNTERPARTS
      )
    } else if (type == 'PROFESSIONALS') {
      deleteItems(
        'arcoProfessionals',
        checkedArcoProfessionistsValues,
        setCheckedArcoProfessionistsValues,
        'PROFESSIONALS',
        labels.REMOVE_PROFESSIONALS
      )
    }
  }

  const disablePrintProfessionalOrCounterpart = () => {
    const checkArcoProfessionists = Object.keys(checkedArcoProfessionistsValues).length > 0
    const checkArcoCounterpart = Object.keys(checkedArcoCounterpartValues).length > 0
    return !(checkArcoProfessionists || checkArcoCounterpart)
  }

  const addProfessionistOrCounterpartButton = () => {
    return addButton(
      `Aggiungi ${isProfessionals() ? labels.PROFESSIONISTS : labels.COUNTERPARTS}`,
      () => showPopUp(isProfessionals() ? 'PROFESSIONALS' : 'COUNTERPARTS'),
      props.disabled
    )
  }

  const removeProfessionistOrCounterpartButton = () => {
    return removeButton(
      `${labels.REMOVE} ${isProfessionals() ? labels.PROFESSIONISTS : labels.COUNTERPARTS}`,
      () =>
        deleteArcoCounterpartOrProfessionalSubject(
          isProfessionals() ? 'PROFESSIONALS' : 'COUNTERPARTS'
        ),
      disablePrintProfessionalOrCounterpart() || props.disabled
    )
  }

  const printProfessionistOrCounterpartButton = () => {
    return printButton(
      labels.PRINT_DOCUMENT,
      () => showPrintPopup(isProfessionals() ? 'PROFESSIONALS' : 'COUNTERPARTS'),
      disablePrintProfessionalOrCounterpart() || props.disabled,
      labels
    )
  }

  //ROW PROFESSIONISTS
  const renderRowProfessionists = (sizes) => {
    const form = Object.assign({}, values)
    if (form.arcoProfessionals?.length > 0) {
      return form.arcoProfessionals.map((arcoProfessionist) => {
        return (
          <div
            className={'text-start row-table'}
            id={`arcoProfessionist-${arcoProfessionist.id}`}
            key={`arcoProfessionist-${arcoProfessionist.id}`}>
            <div className={`${sizes.checkbox} text-center div-td justify-content-center`}>
              <input
                type="checkbox"
                disabled={props.disabled}
                className="arcoCheckbox"
                id={`arcoProfessionist-${arcoProfessionist.id}`}
                value={!!checkedArcoProfessionistsValues[arcoProfessionist.id]}
                onClick={(e) =>
                  handleCheckChange(
                    e,
                    arcoProfessionist,
                    checkedArcoProfessionistsValues,
                    setCheckedArcoProfessionistsValues
                  )
                }
              />
            </div>
            <div
              title={arcoProfessionist.name}
              className={`${sizes.professionistOrCounterpart} div-td text-truncate`}>
              <strong>{arcoProfessionist.name}</strong>
            </div>
          </div>
        )
      })
    }
    return []
  }

  // ROW COUNTERPARTS
  const renderRowCounterpart = (sizes) => {
    const form = Object.assign({}, values)
    if (form.arcoRegistriesAsProfessionals?.length > 0) {
      return form.arcoRegistriesAsProfessionals.map((arcoContropart) => {
        return (
          <div
            key={`counterpart-key-${arcoContropart.id}`}
            className={'text-start row-table'}
            id={`counterpart-${arcoContropart.id}`}>
            <div className={`${sizes.checkbox} text-center div-td justify-content-center`}>
              <input
                type="checkbox"
                disabled={props.disabled}
                className="arcoCheckbox"
                id={`arcoCounterpart-${arcoContropart.id}`}
                value={!!checkedArcoCounterpartValues[arcoContropart.id]}
                onClick={(e) =>
                  handleCheckChange(
                    e,
                    arcoContropart,
                    checkedArcoCounterpartValues,
                    setCheckedArcoCounterpartValues
                  )
                }
              />
            </div>
            <div
              title={getSurnameOrDenomination(arcoContropart)?.toUpperCase()}
              className={`${sizes.professionistOrCounterpart} div-td text-truncate`}
              onClick={() => showArcoRegistryDetail(arcoContropart)}>
              <strong>{getSurnameOrDenomination(arcoContropart)?.toUpperCase()}</strong>
            </div>
          </div>
        )
      })
    }
    return []
  }

  // ROW REGISTRY
  const showPopUpContract = async () => {
    const isValid = await handleValidation()
    if (!isValid) return
    setSelectedContract(null)
    setShowModalContract(!showModalContract)
  }

  const showRegistryDetail = (arcoContract) => {
    setSelectedContract(arcoContract)
    setShowModalContract(!showModalContract)
  }

  const addRegistryButton = () => {
    return addButton(labels.ADD_CONTRACTS, showPopUpContract, props.disabled)
  }

  const removeRegistryButton = () => {
    return removeButton(
      labels.REMOVE_CONTRACTS,
      () =>
        deleteItems(
          'arcoContracts',
          checkedArcoContractsValues,
          setCheckedArcoContractsValues,
          'CONTRACTS',
          labels.REMOVE_CONTRACTS_SUCCESS
        ),
      props.disabled || Object.keys(checkedArcoContractsValues)?.length === 0
    )
  }

  const formatCurrency = (amount, currencyCode) => {
    let body = {
      style: 'currency',
      currency: currencyCode || 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
    return amount.toLocaleString('de-DE', body)
  }

  const isArcoSubjectTypes36 = () => {
    return values.arcoSubject?.arcoSubjectType?.code === '36'
  }

  const renderRowContracts = (sizes) => {
    const form = Object.assign({}, values)
    if (form.arcoContracts?.length > 0) {
      return form.arcoContracts.map((arcoContract, index) => {
        if (arcoContract.status < 1) {
          arcoContract.index = index
          return (
            <div
              key={`contract-key-${arcoContract.id}`}
              className={'text-start row-table'}
              id={`contract-${arcoContract.id}`}>
              <div className={`${sizes.checkbox} text-center div-td justify-content-center`}>
                <input
                  type="checkbox"
                  className="arcoCheckbox"
                  disabled={props.disabled}
                  id={`arcoContract-${arcoContract.id}`}
                  value={!!checkedArcoContractsValues[arcoContract.id]}
                  onClick={(e) =>
                    handleCheckChange(
                      e,
                      arcoContract,
                      checkedArcoContractsValues,
                      setCheckedArcoContractsValues
                    )
                  }
                />
              </div>
              <div
                title={Utility.formatDateForDisplay(arcoContract.contractDate)}
                className={`${sizes.contractDate} div-td text-truncate`}
                onClick={() => showRegistryDetail(arcoContract)}>
                <span>{Utility.formatDateForDisplay(arcoContract.contractDate)}</span>
              </div>
              <div
                title={
                  arcoContract.arcoCausalOperationType.code +
                  '-' +
                  arcoContract.arcoCausalOperationType.description.toUpperCase()
                }
                className={`${sizes.contract} div-td text-truncate`}
                onClick={() => showRegistryDetail(arcoContract)}>
                <span>
                  {arcoContract.arcoCausalOperationType.code +
                    '-' +
                    arcoContract.arcoCausalOperationType.description.toUpperCase()}
                </span>
              </div>
              <div
                title={formatCurrency(arcoContract.amount || 0, arcoContract.currency?.isoCode)}
                className={`${sizes.amount} div-td justify-content-end`}
                onClick={() => showRegistryDetail(arcoContract)}>
                <span className="d-flex justify-content-end">
                  {formatCurrency(arcoContract.amount || 0, arcoContract.currency?.isoCode)}
                </span>
              </div>
            </div>
          )
        }
        return <></>
      })
    }
    return []
  }

  return (
    <div className="row-detail">
      {loading && <PageSpinner />}
      <div>
        <div>
          <div>
            <div className="row">
              <div className={`${sizesClass.profilingDate} pe-2`}>
                <DateField
                  disabled={props.disabled}
                  label={`${labels.PROFILING_DATE}*`}
                  name="profilingDate"
                  date={values.profilingDate}
                  error={errors.profilingDate}
                  touched={props.touched}
                  onChange={(date) => handlerChangeDataProfilatura(date)}
                />
              </div>
              <div
                className={`${isArcoSubjectTypes36() ? sizesClass.description_36 : sizesClass.description} pe-2`}>
                <BwmInput
                  disabled={props.disabled}
                  name={labels.DESCRIPTION}
                  label={`${labels.DESCRIPTION_OPERATION}*`}
                  className="form-control"
                  value={values.description}
                  error={errors.description}
                  touched={props.touched}
                  onChange={(e) =>
                    Utility.handleChangeValue(
                      e.target.value,
                      'description',
                      values,
                      setValues,
                      true
                    )
                  }
                />
              </div>
              <div className={`${sizesClass.number} pe-2`}>
                <BwmInput
                  disabled
                  name={labels.NUMBER}
                  label={labels.NUMBER}
                  className="form-control text-end"
                  maxLength="60"
                  value={values.number || ''}
                  error={errors.number}
                  touched={props.touched}
                />
              </div>
              <div
                className={`${isArcoSubjectTypes36() ? sizesClass.nature_36 : sizesClass.nature} pe-2`}>
                <AutoCompileCustom //Autocompile
                  disabled={props.disabled}
                  label={`${labels.NATURE}*`}
                  className="form-control"
                  filterValues={false}
                  suggestions={ARCO_NATURE_OPTIONS}
                  filter={ARCO_NATURE_OPTIONS}
                  value={values.nature ? values.nature.toUpperCase() : ''}
                  error={errors.nature}
                  touched={props.touched}
                  handleInputAutocompile={(value) =>
                    Utility.handleChangeValue(value, 'nature', values, setValues, true)
                  }
                />
              </div>

              <div
                className={`${isArcoSubjectTypes36() ? sizesClass.purpose_36 : sizesClass.purpose}`}>
                <AutoCompileCustom //Autocompile
                  disabled={props.disabled}
                  label={`${labels.SCOPE}*`}
                  className="form-control"
                  filterValues={false}
                  suggestions={ARCO_PURPOSE_OPTIONS}
                  filter={ARCO_PURPOSE_OPTIONS}
                  value={values.purpose ? values.purpose.toUpperCase() : ''}
                  error={errors.purpose}
                  touched={props.touched}
                  handleInputAutocompile={(value) =>
                    Utility.handleChangeValue(value, 'purpose', values, setValues, true)
                  }
                />
              </div>
              {isArcoSubjectTypes36() && (
                <>
                  <div className={`${sizesClass.goldPrice} px-2`}>
                    <BwmInput
                      disabled={props.disabled}
                      name="goldPrice"
                      label={labels.GOLD_PRICE}
                      className="form-control"
                      maxLength="100"
                      value={values.goldPrice}
                      error={errors.goldPrice}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'goldPrice',
                          values,
                          setValues,
                          false,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass.silverPrice}`}>
                    <BwmInput
                      disabled={props.disabled}
                      name="silverPrice"
                      label={labels.SILVER_PRICE}
                      className="form-control"
                      maxLength="100"
                      value={values.silverPrice}
                      error={errors.silverPrice}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'silverPrice',
                          values,
                          setValues,
                          false,
                          true
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>

            <div className="form-row mt-2" style={{ height: '195px' }}>
              <div className="tab-content relationship col-12">
                <Tabs
                  className="tabs-sm"
                  activeTab={props.activeTab}
                  setActiveTab={props.setActiveTab}>
                  <div label={labels.REGISTRIES_AND_CONTRACTS} id="tab-registry">
                    <div className="row pt-3 px-2 pb-2">
                      <div
                        className={`${sizesClass.customerTable.table} ${window.innerWidth <= Constants.BREAKPOINT_MD ? 'pe-2' : 'pe-3'}`}>
                        <div className="d-flex mb-2 justify-content-between">
                          <div className="align-items-center d-flex">
                            <b>{labels.CLIENTS?.toUpperCase()}</b>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className="pe-2">{addClientButton()}</div>
                            <div className="pe-2">{removeClientButton()}</div>
                            <div>{printClientButton()}</div>
                          </div>
                        </div>
                        <div className="border border-radius col-12 arco-new-operation">
                          {renderRowClients(sizesClass.customerTable)}
                        </div>
                      </div>
                      <div
                        className={`${sizesClass.counterpartOrProfessionistTable.table} ${window.innerWidth <= Constants.BREAKPOINT_MD ? 'pe-2' : 'pe-3'}`}>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="align-items-center d-flex">
                            <b>
                              {isProfessionals()
                                ? labels.PROFESSIONISTS?.toUpperCase()
                                : labels.COUNTERPARTS?.toUpperCase()}
                            </b>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className="pe-2">{addProfessionistOrCounterpartButton()}</div>
                            <div className={!isProfessionals() ? 'pe-2' : ''}>
                              {removeProfessionistOrCounterpartButton()}
                            </div>
                            {!isProfessionals() && (
                              <div>{printProfessionistOrCounterpartButton()}</div>
                            )}
                          </div>
                        </div>
                        <div className="border border-radius col-12 arco-new-operation">
                          {isProfessionals()
                            ? renderRowProfessionists(sizesClass.counterpartOrProfessionistTable)
                            : renderRowCounterpart(sizesClass.counterpartOrProfessionistTable)}
                        </div>
                      </div>
                      <div className={`${sizesClass.contractsTable.table}`}>
                        <div className="row mb-2">
                          <div className="col-8 align-items-center d-flex">
                            <b>{labels.CONTRACTS?.toUpperCase()}</b>
                          </div>
                          <div className="col d-flex justify-content-end">
                            <div className="pe-2">{addRegistryButton()}</div>
                            <div>{removeRegistryButton()}</div>
                          </div>
                        </div>
                        <div className="border border-radius col-12 arco-new-operation">
                          {renderRowContracts(sizesClass.contractsTable)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div label={labels.RISK_OPERATION} id="tab-operation">
                    <div className="w-100 p-2 pt-0">
                      <div className="row pt-3 mb-2">
                        <div className={`${sizesClass.b1} pe-2`}>
                          <BwmSelect
                            options={props.getBList('B1')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b1"
                            label={`${labels.B1}`}
                            className="form-control"
                            value={values.b1?.id}
                            error={errors.b1}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b1')}
                          />
                        </div>
                        <div className={`${sizesClass.b2} pe-2`}>
                          <BwmSelect
                            options={props.getBList('B2')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b2"
                            label={`${labels.B2}`}
                            className="form-control"
                            value={values.b2?.id}
                            error={errors.b2}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b2')}
                          />
                        </div>
                        <div className={`${sizesClass.b3} pe-2`}>
                          <BwmSelect
                            options={props.getBList('B3')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b3"
                            label={`${labels.B3}`}
                            className="form-control"
                            value={values.b3?.id}
                            error={errors.b3}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b3')}
                          />
                        </div>
                        <div className={`${sizesClass.b4}`}>
                          <BwmSelect
                            options={props.getBList('B4')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b4"
                            label={`${labels.B4}`}
                            className="form-control"
                            value={values.b4?.id}
                            error={errors.b4}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b4')}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className={`${sizesClass.b5} pe-2`}>
                          <BwmSelect
                            options={props.getBList('B5')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b5"
                            label={`${labels.B5}`}
                            className="form-control"
                            value={values.b5?.id}
                            error={errors.b5}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b5')}
                          />
                        </div>
                        <div className={`${sizesClass.b6} pe-2`}>
                          <BwmSelect
                            options={props.getBList('B6')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b6"
                            label={`${labels.B6}`}
                            className="form-control"
                            value={values.b6?.id}
                            error={errors.b6}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b6')}
                          />
                        </div>
                        <div className={`${sizesClass.b7} pe-2`}>
                          <BwmSelect
                            options={props.getBList('B7')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b7"
                            label={`${labels.B7}`}
                            className="form-control"
                            value={values.b7?.id}
                            error={errors.b7}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b7')}
                          />
                        </div>
                        <div className={`${sizesClass.b8}`}>
                          <BwmSelect
                            options={props.getBList('B8')}
                            disabled={props.disabled}
                            allowEmpty={false}
                            name="b8"
                            label={`${labels.B8}`}
                            className="form-control"
                            value={values.b8?.id}
                            error={errors.b8}
                            touched={props.touched}
                            onChange={(e) => handlerChangeB(e, 'b8')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row row align-item-center mt-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-outline-primary me-4"
                onClick={() => props.close(true)}>
                <i className="thx-cancel thx-icon me-2" />
                <span>{labels.CANCEL}</span>
              </button>
              {props.paginationComponent && (
                <div className="d-flex justify-content-end">{props.paginationComponent}</div>
              )}
              {window.innerWidth > Constants.BREAKPOINT_LG && (
                <div className="ms-4">
                  <b className="me-2">*</b>
                  <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-outline-primary  me-2"
                data-tip={
                  window.innerWidth <= Constants.BREAKPOINT_MD ? labels.ATTACHMENTS : undefined
                }
                onClick={() => saveAll(true)}>
                <i className="thx-clip thx-icon" />
                {window.innerWidth > Constants.BREAKPOINT_MD && (
                  <span className="ms-2">{labels.ATTACHMENTS}</span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary me-2"
                onClick={() => saveBeforePrintOperation()}>
                <i className="thx-export thx-icon me-2 mt-1" />
                {window.innerWidth > Constants.BREAKPOINT_MD
                  ? labels.PRINT_OPERATION
                  : labels.PRINT}
              </button>
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={() => saveAll(false)}
                disabled={props.disabled}>
                <i className="thx-floppy-disk thx-icon me-2" />
                {labels.SAVE}
              </button>
            </div>
          </div>
        </div>
        {showModal && (popupType === 'CLIENTS' || popupType === 'COUNTERPARTS') && (
          <ArcoRegistriesList
            disabled={props.disabled}
            arcoSubjectId={props.arcoSubjectId}
            arcoSubject={values.arcoSubject}
            arcoRegistries={props.arcoRegistries}
            showModalRegistriesList={showModal}
            detailId={detailId}
            depth={2}
            onHide={showPopUp}
            onSelectedItem={onSelectedItem}
          />
        )}
        {showRegistryModal && (
          <ArcoRegistryModal
            disabled={props.disabled}
            arcoSubjectId={props.arcoSubjectId}
            arcoSubject={values.arcoSubject}
            depth={-999}
            selectedItem={registryModalElement}
            showModalCheck={showRegistryModal}
            onHide={closeRegistryModal}
          />
        )}
        {showModal && popupType === 'PROFESSIONALS' && (
          <ArcoRegistrationProfessionalsModal
            disabled={props.disabled}
            show={showModal}
            subject={values.arcoSubject}
            professionistsData={professionistsData}
            onHide={hideProfessionalsModal}
            onConfirm={(selectedItems) => insertSelectedProfessionists(selectedItems)}
          />
        )}
        {showModalContract && (
          <div>
            <ArcoRegistrationContractModal
              disabled={props.disabled}
              arcoSubject={values.arcoSubject}
              arcoCausalOperations={props.arcoCausalOperationsFromStore}
              show={showModalContract}
              currencies={props.currencies}
              addArcoContract={addArcoContract}
              selectedContract={selectedContract}
              selectedOperation={values}
              onHide={showPopUpContract}
            />
          </div>
        )}

        {showPrintModal && (
          <div>
            <ArcoRegistryPrintModal
              key={'printModal-' + selectedPrintItem?.id}
              arcoSubject={values.arcoSubject}
              show={showPrintModal}
              onHide={() => showPrintPopup(currentType, selectedPrintItem?.id)}
              selectedPrintItem={selectedPrintItem}
              currentOperation={values}
            />
          </div>
        )}
      </div>
    </div>
  )
}
