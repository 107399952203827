import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Constants from '../../../config/Constants'
import { CheckRemainingSubjects, IsLicenseValid } from '../../shared/auth/license/LicenseChecker'
import * as actions from '../../../actions'
import getText from '../../shared/i18n/labels'
import { Route, useRouteMatch } from 'react-router-dom'

// DETAIL
import BlueSearchPage from './NewBlueSearchPage'

// TABLE
import BlueSubjectList from './NewBlueSubjectList'
import { baseSearchObject } from '../../shared/tables/TableUtils'

// MODAL
import Modal from 'react-bootstrap/Modal'
import BlueHistoryModal from './BlueHistoryModal'
// import BlueSubjectModal from './NewBlueSubjectModal'

// BUTTONS
import { BtnEmpty } from '../../shared/BtnEmpty'

// COMPONENTS
import { PopupDelete } from '../../shared/popups/PopupDelete'
import { PopupError } from '../../shared/popups/PopupError'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../shared/popups/PopupSuccess'
import { LicenseExpiredPopup } from '../../shared/auth/license/LicenseExpiredPopup'
import { SubjectTotalAndCallToAction } from '../../shared/SubjectTotalAndCallToAction'

export default function BlueSubject() {
  const [checkedItems, setCheckedItems] = useState([])
  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const { path } = useRouteMatch()

  const [searchId, setSearchId] = useState(null)

  const [subjects, setSubjects] = useState([])
  const [loading, setLoading] = useState(false)
  // FOR MODAL
  // const [showAddModal, setShowAddModal] = useState(false)
  // const [currSubject, setCurrSubject] = useState({})
  // const [subjectId, setSubjectId] = useState(undefined)
  const [expired, setExpired] = useState(false)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const [subjectHistoryId, setSubjectHistoryId] = useState(undefined)
  const [subjectSummary, setSubjectSummary] = useState(undefined)
  const [customerApplication, setCustomerApplication] = useState()
  const [resetForm, setResetForm] = useState(false)

  useEffect(() => {
    getSubjects()
    getLocations()
    CheckRemainingSubjects(
      Constants.APPLICATION_BLUE,
      false,
      (subjectSummary) => {
        setDisableNewSubjectButtons(subjectSummary?.available < 1)
        setSubjectSummary(subjectSummary)
      },
      labels,
      lang
    )
  }, [])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_BLUE)
    }
  }
  useEffect(() => {
    setExpired(isExpired())
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_BLUE
    )
    const customerApplication = auth.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application.id
    )
    setCustomerApplication(customerApplication)
  }, [auth])

  useEffect(() => {
    if (expired) {
      LicenseExpiredPopup({ language: lang })
    }
  }, [expired])

  const getSubjects = () => {
    actions.getAllBlueSubjects(baseSearchObject).then((res) => {
      setSubjects(res)
      CheckRemainingSubjects(
        Constants.APPLICATION_BLUE,
        false,
        (subjectSummary) => {
          setDisableNewSubjectButtons(subjectSummary?.available < 1)
          setSubjectSummary(subjectSummary)
        },
        labels,
        lang
      )
    })
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const openPopupDelete = () => {
    PopupDelete({
      title: labels.DELETE_SUBJECTS_POPUP_TITLE,
      cancelText: labels.CANCEL,
      confirmText: labels.CONFIRM,
      handleClickConfirm: deleteBlueSubject
    })
  }

  const deleteBlueSubject = () => {
    setLoading(true)
    let ids = []
    checkedItems.forEach((subject) => ids.push(subject))

    actions
      .deleteBlueSubjects(ids)
      .then(
        (res) => {
          PopupSuccess({
            title: labels.SUCCESSTITLE,
            text: labels.SUCCESSTITLE,
            ok: labels.SUCCESSOK
          })
          setCheckedItems([])
          getSubjects()
          setLoading(false)
        },
        (err) => {
          setLoading(false)
          PopupError({
            text: labels.GENERIC_ERROR,
            title: labels.WARNING,
            request: labels.CONTACT_SUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => {
        setLoading(false)
      })
  }

  const dowloadLocalDocument = (fileName) => {
    const link = document.createElement('a')
    link.href = process.env.PUBLIC_URL + '/docs/' + fileName
    link.setAttribute('download', fileName)
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // FOR MODAL
  // const handleAddClick = () => {
  //   setShowAddModal(true)
  // }

  // const handleAddClose = () => {
  //   setShowAddModal(false)
  // }

  const handleHistoryClose = () => {
    setSubjectHistoryId()
  }

  const handleHistoryClick = (subjectId) => {
    setSubjectHistoryId(subjectId)
  }

  // FORM MODAL
  // const handleDetailClick = (subjectId, subjectsList) => {
  //   setSubjectId(subjectId)
  //   setCurrSubject(subjectsList.find((obj) => obj.id === subjectId))
  // }

  // const handleDetailClose = () => {
  //   setSubjectId(undefined)
  //   setCurrSubject(undefined)
  // }

  const renderActionsComponent = () => (
    <div className="col-12 ps-0">
      <div className="btn-group" role="group" aria-label="action buttons">
        <button
          type="button"
          disabled={checkedItems.length === 0}
          className={`btn btn-outline-primary me-0`}
          onClick={() => openPopupDelete()}>
          <i className="thx-trash thx-icon me-2" />
          {labels.SUBJECT_DELETE}
        </button>
        <button
          type="button"
          className={`btn btn-primary ms-2`}
          onClick={() => dowloadLocalDocument('BLUE-Ricerca-Massiva.pdf')}>
          {labels.MASSIVE_INSTRUCTION}
        </button>
      </div>
    </div>
  )

  return (
    <>
      {loading && <PageSpinner text={labels.SPINNERTITLE} />}
      <Route key={path} path={`${path}/search/:id`}>
        <BlueSearchPage setId={setSearchId} />
      </Route>
      <div className={searchId ? 'd-none' : 'table-custom'}>
        <div className="tabs single">
          <div className="d-flex justify-content-between">
            <div>
              <SubjectTotalAndCallToAction
                total={subjectSummary?.total}
                remaining={subjectSummary?.available}
                buyLink={Constants.BLUE_BUY_LINK}
                used={subjectSummary?.used}
                licenseType={customerApplication?.licenseType}
                customerApplication={customerApplication}
                language={lang}
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="ps-2">
                {/* <BlueSubjectModal
                  subject={{}}
                  licenseExpired={expired || disableNewSubjectButtons}
                  disabled={props.licenseExpired}
                  subjectSummary={subjectSummary}
                  addBlueSubject={actions.addBlueSubject}
                  customLocations={props.locations}
                  showModal={showAddModal}
                  openModal={handleAddClick}
                  closeModal={handleAddClose}
                  getSubjects={getSubjects}
                /> */}
                {!(expired || disableNewSubjectButtons) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={expired || disableNewSubjectButtons}
                    onClick={() => setResetForm(!resetForm)}>
                    <i className="thx-plus thx-icon me-2" />
                    {labels.SUBJECT_NEW}
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* {subjectId && (
            <BlueSubjectModal
              id={subjectId}
              hideButton={true}
              showModal={!!subjectId}
              licenseExpired={expired}
              subject={currSubject}
              disabled={expired}
              subjectSummary={subjectSummary}
              addBlueSubject={actions.addBlueSubject}
              customLocations={props.locations}
              getSubjects={getSubjects}
              openModal={handleDetailClick}
              closeModal={handleDetailClose}
            />
          )} */}
          <Modal
            backdrop="static"
            show={!!subjectHistoryId}
            onHide={() => handleHistoryClose()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable>
            <div className="bar"></div>
            <Modal.Header closeButton className="ps-3">
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>{labels.HISTORYTITLE}</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BlueHistoryModal subjectId={subjectHistoryId} />
            </Modal.Body>
            <Modal.Footer>
              <BtnEmpty text={labels.CLOSE} handlerOnClick={() => handleHistoryClose()} />
            </Modal.Footer>
          </Modal>
          <div className="table-custom mt-1">
            <div className="tab-content tab-rel">
              <BlueSubjectList
                disabled={expired || loading}
                resetForm={resetForm}
                subjects={subjects}
                getSubjects={getSubjects}
                setSubjects={setSubjects}
                checkedItems={checkedItems}
                setSelectedSubjects={setCheckedItems}
                actionsComponent={renderActionsComponent()}
                showHistory={handleHistoryClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
