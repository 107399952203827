import { useState } from 'react'
import { useSelector } from 'react-redux'
import { APPLICATIONS, LICENSE_TYPES } from '../../config/Constants'
import getText from '../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import NumberFormat from 'react-number-format'
import { BwmSelect } from '../shared/form/BwmSelectB5'
import { BwmInput } from '../shared/form/BwmInputB5'
import { DateField } from '../shared/form/DateFieldB5'

// BUTTONS
import { BtnEmpty } from '../shared/BtnEmpty'
import { BtnFill } from '../shared/BtnFill'

export function AdminNewApplicationModal(props) {
  const defaultYear = new Date().getFullYear() + 1
  const customer = props.customer
  const [modalShow, setModalShow] = useState(false)
  const [application, setApplication] = useState(2)
  const [licenseType, setLicenseType] = useState('BOUGHT')
  const [maxSubjects, setMaxSubjects] = useState(1)
  const [expiration, setExpiration] = useState(new Date().setFullYear(defaultYear))

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const onSave = () => {
    if (expiration && application && maxSubjects && licenseType) {
      const customerApplication = {
        expiration: new Date(expiration),
        application: { id: application },
        maxSubjects: maxSubjects,
        licenseType: licenseType,
        customer: customer
      }
      props.onSave(customerApplication)
    }
  }

  const openModal = () => {
    setModalShow(true)
  }

  const closeModal = () => {
    setModalShow(false)
  }

  return (
    <>
      <button type="button" className="btn btn-primary btn-cell me-2" onClick={openModal}>
        <i className="thx-plus thx-icon" />
      </button>
      <Modal
        show={modalShow}
        onHide={closeModal}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-content-sm"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {labels.ADD_APPLICATION} {customer?.user?.email}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-auto">
            <div className="col-6 mb-3 pe-2">
              <BwmSelect
                label={labels.APPLICATION}
                className="form-control"
                value={application}
                options={APPLICATIONS}
                onChange={(e) => setApplication(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 number-format-label form-group">
              <NumberFormat
                id="filled-multiline-flexible"
                label={labels.NUMBER_OF_SUBJECTS}
                multiline={true}
                fixedDecimalScale={true}
                className="form-control"
                variant="filled"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                inputMode="numeric"
                customInput={BwmInput}
                value={maxSubjects || 0}
                onValueChange={(value) => setMaxSubjects(value.value)}
              />
            </div>
            <div className="col-6 pe-2">
              <BwmSelect
                label={labels.LICENSE_TYPE}
                className="form-control"
                value={licenseType}
                options={LICENSE_TYPES}
                onChange={(e) => setLicenseType(e.target.value)}
              />
            </div>
            <div className="col-6 form-group">
              <DateField
                label={labels.EXPIRATION}
                date={expiration}
                onChange={(date) => setExpiration(date)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
          <BtnFill
            text={labels.CREATE_AND_CONTINUE}
            classCustom="float-end text-end"
            handlerOnClick={() => onSave()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
