import { useEffect, useState } from 'react'
import { PageSelector } from '../searchComponents/PageSelector'
import { HeaderFilterDropdown } from './HeaderFilterDropdown'
import { TableUtils } from './TableUtils'
import { generateUniqueKey } from '../Utility'
import getText from '../i18n/labels'
import { NumberOfResultsSelector } from '../searchComponents/NumberOfResultsSelector'
import { BREAKPOINT_MD } from '../../../config/Constants'

export function StarTableTop({
  language = 'it',
  usePagination = true,
  actionsComponent = null,
  loading,
  columns,
  hiddenColumns,
  searchObject = {},
  isFiltered = false,
  totalPages = 0,
  showPagination = true,
  showDrag = true,
  tableConfiguration,
  tableConfigurationKey,
  withCheckBoxes,
  setColumns,
  setHiddenColumns,
  setHeaderSizes,
  perPageOptions,
  showResetFilter,
  setTableKey,
  onChangePage = () => {},
  onResetFilters = () => {}
}) {
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(
    parseInt(localStorage.getItem(`${tableConfigurationKey}-page-size`) || searchObject.pageSize)
  )
  const labels = getText(language)

  const nextPage = () => {
    const next = (searchObject.page + 1) * searchObject.pageSize
    return next < searchObject.totalElements ? next : searchObject.totalElements
  }

  useEffect(() => {
    onChangePage({ ...searchObject, page: 0, pageSize: selectedResultsPerPage })
  }, [selectedResultsPerPage])

  return (
    <div className="star-table-top d-flex justify-content-between w-100">
      <div>{actionsComponent}</div>
      <div
        className={
          'd-flex align-items-center justify-content-end' + (usePagination ? '' : ' me-3')
        }>
        {isFiltered && !loading && showResetFilter && (
          <button
            data-tip={labels.RESET_ALL_FILTERS}
            className="btn btn-transparent btn-xs me-2"
            onClick={onResetFilters}>
            <i className="thx-icon thx-filter" />
            {window.innerWidth > BREAKPOINT_MD && (
              <span className="ms-2">{labels.RESET_ALL_FILTERS}</span>
            )}
          </button>
        )}
        {showPagination && showDrag && (
          <HeaderFilterDropdown
            language={language}
            headers={columns}
            hiddenColumnsProps={hiddenColumns}
            resultsPerPage={searchObject.pageSize}
            onCleanCache={() => {
              tableConfiguration.cleanSearchObject()
              window.location.reload(false)
            }}
            onHide={(hiddenColumns, orderedColumns) => {
              if (tableConfiguration) {
                tableConfiguration.setHiddenColumns(hiddenColumns)
                tableConfiguration.setColumnOrder(orderedColumns)
              }
              setColumns(orderedColumns)
              setHiddenColumns(hiddenColumns)
              setHeaderSizes(
                TableUtils.prepareHeaderSizes(
                  orderedColumns,
                  hiddenColumns,
                  withCheckBoxes,
                  tableConfiguration
                )
              )
              setTableKey(generateUniqueKey(tableConfigurationKey))
            }}
          />
        )}
        {usePagination && showPagination && (
          <>
            <strong className="ms-4 me-4">
              {searchObject.totalElements === 0
                ? searchObject.totalElements
                : searchObject.page * searchObject.pageSize + 1}{' '}
              - {nextPage()} di {searchObject.totalElements}
            </strong>

            <div className="d-flex align-items-center">
              <span style={{ minWidth: '70px' }}>{labels.PERPAGE}</span>
              <NumberOfResultsSelector
                loading={loading}
                options={perPageOptions}
                selected={selectedResultsPerPage}
                onChange={(val) => setSelectedResultsPerPage(Number(val))}
              />
            </div>

            <div>
              <PageSelector
                loading={loading}
                pageNumber={searchObject.page}
                resultsPerPage={searchObject.pageSize}
                totalPages={totalPages}
                onUpdateNumberOfResults={(val) => onChangePage({ ...searchObject, pageSize: val })}
                onClickBack={() => onChangePage({ ...searchObject, page: searchObject.page - 1 })}
                onChangeValue={(page) => onChangePage({ ...searchObject, page })}
                onClickForward={() =>
                  onChangePage({ ...searchObject, page: searchObject.page + 1 })
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
