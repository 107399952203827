import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import { saveSid, validateSid } from '../../../shared/SidCertsHandler'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// FORM
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer'
import { PopupError } from '../../../shared/popups/PopupError'
import { sub } from 'date-fns'

const defaultSize = (size = null) => ({
  leftSide: Utility.getSize(size, { default: '58', lg: '54', md: '54' }),
  rightSide: Utility.getSize(size, { default: '42', lg: '46', md: '46' }),
  personType: Utility.getSize(size, { default: '100' }),
  fiscalCode: Utility.getSize(size, { default: '18', lg: '22', md: '31' }),
  companyName: Utility.getSize(size, { default: '80', lg: '77', md: '68' }),
  location: Utility.getSize(size, { default: '45', lg: '56', md: '44' }),
  province: Utility.getSize(size, { default: '8', lg: '10', md: '15' }),
  firstName: Utility.getSize(size, { default: '41', lg: '38', md: '34' }),
  lastName: Utility.getSize(size, { default: '41', lg: '38', md: '35' }),
  gender: Utility.getSize(size, { default: '10', lg: '13', md: '16' }),
  birthDate: Utility.getSize(size, { default: '14', lg: '18', md: '26' }),
  // SID FORM
  sidNumber: Utility.getSize(size, { default: '22' }),
  password: Utility.getSize(size, { default: '42' }),
  expirationDate: Utility.getSize(size, { default: '36' }),
  email: Utility.getSize(size, { default: '55' }),
  flagPec: Utility.getSize(size, { default: '45' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const CoraSubjectForm = (props) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [subject, setSubject] = useState({})

  const [coraSubjectSid, setCoraSubjectSid] = useState({
    id: null,
    certificate1: '',
    certificate2: '',
    certificate3: '',
    password: '',
    email: '',
    sidNumber: '',
    flagPec: false,
    expirationDate: null
  })

  const [initialCoraSubjectSid, setInitialCoraSubjectSid] = useState({
    id: null,
    certificate1: '',
    certificate2: '',
    certificate3: '',
    password: '',
    email: '',
    sidNumber: '',
    flagPec: false,
    expirationDate: null
  })

  const [errorsSid, setErrorsSid] = useState({})

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required(labels.REQUIRED_FIELD),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().required(labels.REQUIRED_FIELD),
    location: Yup.string().required(labels.REQUIRED_FIELD),
    province: Yup.string().required(labels.REQUIRED_FIELD),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date(labels.INVALID_DATE).when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required(labels.REQUIRED_FIELD)
          .test(
            'is-valid-date',
            labels.INVALID_DATE,
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => labels.INVALID_FIELD,
      (value) => !value
    )
  })

  const retrieveSubject = (id) => {
    setLoading(true)
    setErrorsSid({})
    if (id) {
      actions
        .getCoraSubjectDetail(id)
        .then((response) => {
          setSubject(response.data)
          setNewInitialValues(response.data)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    } else {
      setSubject({})
      setNewInitialValues(undefined)
      setLoading(false)
    }
  }

  const setNewInitialValues = (subjectItem) => {
    setTouched(false)
    if (subjectItem?.id) {
      setCoraSubjectSid(subjectItem.coraSubjectSid)
      setInitialCoraSubjectSid(subjectItem.coraSubjectSid)
    } else {
      setCoraSubjectSid({
        id: null,
        certificate1: '',
        certificate2: '',
        certificate3: '',
        password: '',
        email: '',
        sidNumber: '',
        flagPec: false,
        expirationDate: null
      })
      setInitialCoraSubjectSid({
        id: null,
        certificate1: '',
        certificate2: '',
        certificate3: '',
        password: '',
        email: '',
        sidNumber: '',
        flagPec: false,
        expirationDate: null
      })
    }
    setInitialValues({
      id: subjectItem?.id || null,
      personType: subjectItem?.personType || Constants.PNF,
      fiscalCode: subjectItem?.fiscalCode,
      companyName: subjectItem?.companyName,
      location: subjectItem?.location,
      province: subjectItem?.province,
      firstName: subjectItem?.firstName,
      lastName: subjectItem?.lastName,
      gender: subjectItem?.gender,
      birthDate: subjectItem?.birthDate,
      errorFiscalCode: false
    })
  }

  const close = (retrieve = false) => {
    setTouched(false)
    if (retrieve) retrieveSubject(props.subjectId)
    setErrorsSid({})
    if (props.close) props.close()
  }

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: labels.FISCAL_CODE
    },
    {
      errorKey: 'firstName',
      errorLabel: labels.FIRST_NAME
    },
    {
      errorKey: 'lastName',
      errorLabel: labels.LAST_NAME
    },
    {
      errorKey: 'companyName',
      errorLabel: labels.LEGAL_NAME
    },
    {
      errorKey: 'location',
      errorLabel: labels.LEGAL_ADDRESS
    },
    {
      errorKey: 'province',
      errorLabel: labels.PROVINCE
    },
    {
      errorKey: 'gender',
      errorLabel: labels.GENDER
    },
    {
      errorKey: 'birthDate',
      errorLabel: labels.BIRTH_DATE
    },
    {
      errorKey: 'errorSidNumber',
      errorLabel: labels.SID_NUMBER
    },
    {
      errorKey: 'errorPassword',
      errorLabel: labels.PROTECTION_PASSWORD
    },
    {
      errorKey: 'errorInSubject',
      errorLabel: labels.SUBJECT
    },
    {
      errorKey: 'errorInConfigSid',
      errorLabel: labels.SID_CONFIGURATION
    },
    {
      errorKey: 'errorEmail',
      errorLabel: labels.EMAIL_ADDRESS
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: labels.FISCAL_CODE
    }
  ]

  const getError = (errors, key, removeOldErrors) => {
    if (errorsSid[key]?.isValid === false) {
      errors[key] = errorsSid[key].msg
    } else if (removeOldErrors) {
      delete errors[key]
    }
    return errors
  }

  const getTotalErrors = (errors = {}, removeOldErrors = false) => {
    errors = getError(errors, 'errorCertificates', removeOldErrors)
    errors = getError(errors, 'errorPassword', removeOldErrors)
    errors = getError(errors, 'errorSidNumber', removeOldErrors)
    errors = getError(errors, 'errorEmail', removeOldErrors)
    errors = getError(errors, 'errorInConfigSid', removeOldErrors)
    return errors
  }

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = labels.BIRTH_PLACE
    else formErrorsTabMapping[4].errorLabel = labels.LEGAL_ADDRESS
    const totalErrors = getTotalErrors(errors, true)
    return Utility.extractErrors(totalErrors, formErrorsTabMapping)
  }

  return (
    <div className="border-top">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ values, errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom mb-0' : ''}>
            {showErrorAlert && touched && (
              <div className={props.formOnBottom ? 'cora-form-on-bottom-error' : ''}>
                <ErrorListAlert
                  errors={getErrors(errors, values.personType)}
                  hide={() => setShowErrorAlert(false)}
                />
              </div>
            )}
            <FormBody
              {...props}
              initialValues={initialValues}
              loading={loading}
              touched={touched}
              subject={subject}
              errorsSid={errorsSid}
              coraSubjectSid={coraSubjectSid}
              initialCoraSubjectSid={initialCoraSubjectSid}
              retrieveSubject={retrieveSubject}
              setTouched={setTouched}
              setSubject={setSubject}
              setInitialCoraSubjectSid={setInitialCoraSubjectSid}
              setLoading={setLoading}
              setCoraSubjectSid={setCoraSubjectSid}
              setErrorsSid={setErrorsSid}
              setShowErrorAlert={setShowErrorAlert}
              getTotalErrors={getTotalErrors}
              close={close}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const nameApplication = Constants.APPLICATION_CORA
  const id = props.subjectId

  const [certificateFiles, setCertificateFiles] = useState()
  const [isCertSidOpened, setIsCertSidOpened] = useState(false)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const handlePersonTypeClick = (val) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    form.personType = val
    setValues(form)
  }

  const checkSid = async (sidNumber) => {
    const _coraSubjectSid = cloneDeep(props.coraSubjectSid)
    try {
      if (sidNumber === props.initialCoraSubjectSid?.sidNumber || !sidNumber) return true
      const response = await actions.checkCoraSid(sidNumber)
      if (!response) {
        PopupError({
          text: `<b>${labels.SID_NUMBER}</b>: ${sidNumber}<br> ${labels.DETECTED_FOR_MULTIPLE_SUBJECTS_RECORDING_IMPOSSIBLE}`
        })
        props.setCoraSubjectSid({
          ..._coraSubjectSid,
          sidNumber: props.initialCoraSubjectSid?.sidNumber
        })
      }
      return response
    } catch {
      PopupError({
        text: `<b>${labels.SID_NUMBER}</b>: ${sidNumber}<br> ${labels.DETECTED_FOR_MULTIPLE_SUBJECTS_RECORDING_IMPOSSIBLE}`
      })
      props.setCoraSubjectSid({
        ..._coraSubjectSid,
        sidNumber: props.initialCoraSubjectSid?.sidNumber
      })
      return false
    }
  }

  const handleChangeSid = (e, errorKey, errorMessage) => {
    let value = e.target.value
    let name = e.target.name
    let newCoraSubjectSid = cloneDeep(props.coraSubjectSid)
    if (!newCoraSubjectSid) newCoraSubjectSid = {}
    const error = {}
    error[errorKey] = {}
    if (Utility.isEmpty(value)) {
      error[errorKey].isValid = false
      error[errorKey].msg = errorMessage
    } else {
      error[errorKey].isValid = true
      error[errorKey].msg = null
    }
    props.setErrorsSid({ ...props.errorsSid, errorInConfigSid: '', ...error })
    if (name === 'password') newCoraSubjectSid.password = value.toUpperCase().trim()
    if (name === 'sidNumber') newCoraSubjectSid.sidNumber = value.toUpperCase().trim()
    if (name === 'email') newCoraSubjectSid.email = value ? value.toUpperCase().trim() : value
    props.setCoraSubjectSid(newCoraSubjectSid)
  }

  const handleChangeSidValues = (newValues) => {
    props.setCoraSubjectSid({ ...props.coraSubjectSid, ...newValues })
  }

  const handleFiscalCode = async (fiscalCode) => {
    const form = Object.assign({}, values)
    if (fiscalCode) {
      try {
        const baseRegistry = await actions.getBaseRegistryByFiscalCode(fiscalCode)
        if (baseRegistry) {
          form.companyName = baseRegistry?.companyName || ''
          form.location = baseRegistry?.location?.location || ''
          form.province = baseRegistry?.location?.province || ''
          form.lastName = baseRegistry?.lastName || ''
          form.firstName = baseRegistry?.firstName || ''
          form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
          form.gender = baseRegistry?.gender
        }
        form.errorFiscalCode = false
        setValues(form)
      } catch {
        form.errorFiscalCode = true
        setValues(form)
      }
      try {
        if (!values.id) {
          const _coraSubjectSid = await actions.getCoraSubjectSidByFiscalCode(fiscalCode, 'cora')
          if (_coraSubjectSid?.id) props.setCoraSubjectSid(_coraSubjectSid)
        }
      } catch {
        setValues(form)
      }
      return form.errorFiscalCode
    }
  }

  const handleInputAutocompile = (newLocation) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        form.location = newLocation
        if (loc) form.province = loc.province
        setValues(form)
      },
      () => {
        const form = Object.assign({}, values)
        form.location = ''
        form.province = ''
        setValues(form)
      }
    )
  }

  const formValidation = () => {
    if (!isCertSidOpened) {
      return {
        errorSidNumber: { isValid: true, msg: '' },
        errorEmail: { isValid: true, msg: '' }
      }
    }
    return configurationSidValidation()
  }

  const updateAll = () => {
    saveSid(
      certificateFiles,
      props.coraSubjectSid || 'cora',
      values.fiscalCode,
      'cora',
      (res) => addOrUpdateCoraSubject(res, { ...values, errorFiscalCode: undefined }),
      () => {
        PopupError({ text: labels.GENERIC_ERROR })
        resetFields()
      }
    )
  }

  const addOrUpdateCoraSubject = (sid, values) => {
    let newSubject = { ...values }
    if (newSubject.personType === Constants.PF) {
      newSubject.companyName = null
    } else {
      newSubject.gender = null
      newSubject.firstName = null
      newSubject.lastName = null
      newSubject.birthDate = null
    }
    newSubject.createDate = new Date()
    newSubject.modifyDate = new Date()
    newSubject.coraSubjectSid = sid
    props.setInitialCoraSubjectSid(props.coraSubjectSid)
    props.setSubject(newSubject)
    props.addOrUpdateCoraSubject(newSubject, props.subject?.fiscalCode)
    props.close()
  }

  const resetFields = () => {
    props.setCoraSubjectSid({})
  }

  const configurationSidValidation = () => {
    return validateSid(
      props.coraSubjectSid?.sidNumber,
      props.coraSubjectSid?.email,
      props.errorsSid.errorSidNumber,
      props.errorsSid.errorEmail,
      labels
    )
  }

  const handleFormSubmit = async () => {
    const errorFiscalCode = await handleFiscalCode(values.fiscalCode)
    const form = Object.assign({}, values)
    form.errorFiscalCode = errorFiscalCode
    const errors = await validateForm(form) // Validazione dei valori
    setValues(form)
    props.setTouched(true)
    const { errorSidNumber, errorEmail } = formValidation()
    const totalErrors = props.getTotalErrors({
      ...errors,
      errorSidNumber: errorSidNumber?.msg,
      errorEmail: errorEmail?.msg
    })

    const thereAreErrors =
      Object.keys(totalErrors).length > 0 &&
      Object.values(totalErrors).filter((e) => !!e)?.length > 0
    props.setErrorsSid({ ...props.errorsSid, errorSidNumber, errorEmail })
    props.setLoading(true)
    const response = await checkSid(props.coraSubjectSid?.sidNumber)
    props.setLoading(false)
    if (!response) return
    if (thereAreErrors) {
      props.setShowErrorAlert(true)
    } else {
      updateAll(values)
      props.setShowErrorAlert(false)
    }
  }

  return (
    <div className="row row-detail d-flex">
      <div className={`${sizesClass.leftSide} pe-3`}>
        <div className="configurazioneSID pt-3">
          <div id={`anagrafica-${id || 0}`}>
            <div className="row">
              <div className={`${sizesClass.personType} mb-3 ps-0`}>
                <div className="input-group">
                  <div className="inputRadio me-5">
                    <label
                      disabled={props.disabled}
                      className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                      htmlFor={`pType1${values?.id}`}
                      onClick={() => handlePersonTypeClick(Constants.PNF)}>
                      <input
                        disabled={props.disabled}
                        className="form-check-input pe-1 me-2 mt-0"
                        style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                        type="radio"
                        checked={values.personType === Constants.PNF}
                        onChange={() => {}}
                      />
                      {labels.LEGAL_PERSON}
                    </label>
                  </div>
                  <div className="inputRadio">
                    <label
                      disabled={props.disabled}
                      className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                      htmlFor={`pType2${values?.id}`}
                      onClick={() => handlePersonTypeClick(Constants.PF)}>
                      <input
                        disabled={props.disabled}
                        className="form-check-input pe-1 me-2 mt-0"
                        style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                        type="radio"
                        checked={values.personType === Constants.PF}
                        onChange={() => {}}
                      />
                      {labels.PHYSICAL_PERSON}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {values.personType === Constants.PNF ? (
                <>
                  <div className={`${sizesClass.fiscalCode} pe-2 mb-2`}>
                    <BwmInput
                      disabled={props.disabled}
                      name="fiscalCode"
                      label={`${labels.FISCAL_CODE}*`}
                      className="form-control"
                      maxLength="16"
                      value={values.fiscalCode}
                      error={errors.fiscalCode || errors.errorFiscalCode}
                      touched={props.touched}
                      onBlur={(e) => handleFiscalCode(e.target.value)}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'fiscalCode',
                          values,
                          setValues,
                          true,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass.companyName} mb-2`}>
                    <BwmInput
                      disabled={props.disabled}
                      label={`${labels.LEGAL_NAME}*`}
                      className="form-control font-weight-bold font-size-big"
                      maxLength="60"
                      value={values.companyName}
                      error={errors.companyName}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'companyName',
                          values,
                          setValues,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass.location} pe-2 mb-2`}>
                    {/* LOCALITÀ */}
                    <AutoCompileCustom
                      disabled={props.disabled}
                      label={
                        values.personType === Constants.PNF
                          ? `${labels.LEGAL_ADDRESS}*`
                          : `${labels.BIRTH_PLACE}*`
                      }
                      id="location"
                      filter={props.locations}
                      value={values.location}
                      error={errors.location}
                      touched={props.touched}
                      handleInputAutocompile={(e) => handleInputAutocompile(e)}
                    />
                  </div>

                  <div className={`${sizesClass.province} pe-2 mb-2`}>
                    <ProvinceField
                      disabled={props.disabled}
                      placeholder={`${labels.EE_FOR_FOREIGN_COUNTRY}`}
                      label={`${labels.PROVINCE}*`}
                      province={values.province}
                      error={errors.province}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'province',
                          values,
                          setValues,
                          true,
                          true
                        )
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={`${sizesClass.fiscalCode} pe-2 mb-2`}>
                    <BwmInput
                      disabled={props.disabled}
                      name="fiscalCode"
                      label={`${labels.FISCAL_CODE}*`}
                      className="form-control"
                      value={values.fiscalCode}
                      error={errors.fiscalCode || errors.errorFiscalCode}
                      touched={props.touched}
                      maxLength="16"
                      onBlur={(e) => handleFiscalCode(e.target.value)}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'fiscalCode',
                          values,
                          setValues,
                          true,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass.firstName} pe-2`}>
                    <BwmInput
                      disabled={props.disabled}
                      name="firstName"
                      maxLength="25"
                      label={`${labels.FIRST_NAME}*`}
                      type="text"
                      className="form-control font-weight-bold font-size-big"
                      value={values.firstName}
                      error={errors.firstName}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'firstName',
                          values,
                          setValues,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass.lastName} mb-2 pe-2`}>
                    <BwmInput
                      disabled={props.disabled}
                      maxLength="25"
                      label={`${labels.LAST_NAME}*`}
                      type="text"
                      className="form-control font-weight-bold font-size-big"
                      value={values.lastName}
                      error={errors.lastName}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'lastName',
                          values,
                          setValues,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass.gender} pe-2 mb-2`}>
                    <BwmSelect
                      disabled={props.disabled}
                      options={Constants.genderList || []}
                      name="gender"
                      allowEmpty={false}
                      label={`${labels.GENDER}*`}
                      className="form-control"
                      value={values.gender}
                      error={errors.gender}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(e.target.value, 'gender', values, setValues)
                      }
                    />
                  </div>
                  <div className={`${sizesClass.birthDate} pe-2 mb-2`}>
                    <DateField
                      disabled={props.disabled}
                      label={`${labels.BIRTH_DATE}*`}
                      customOptions={{ maxDate: new Date() }}
                      date={values.birthDate}
                      error={errors.birthDate}
                      touched={props.touched}
                      onChange={(value) =>
                        Utility.handleChangeValue(value, 'birthDate', values, setValues)
                      }
                    />
                  </div>
                  <div className={`${sizesClass.location} pe-2 mb-2`}>
                    {/* LOCALITÀ */}
                    <AutoCompileCustom
                      disabled={props.disabled}
                      label={
                        values.personType === Constants.PNF
                          ? `${labels.LEGAL_ADDRESS}*`
                          : `${labels.BIRTH_PLACE}*`
                      }
                      id="location"
                      filter={props.locations}
                      value={values.location}
                      error={errors.location}
                      touched={props.touched}
                      handleInputAutocompile={(e) => handleInputAutocompile(e)}
                    />
                  </div>

                  <div className={`${sizesClass.province} mb-2`}>
                    <ProvinceField
                      disabled={props.disabled}
                      maxLength="2"
                      placeholder={labels.OUTSIDEITALY}
                      label={`${labels.PROVINCE}*`}
                      province={values.province}
                      error={errors.province}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'province',
                          values,
                          setValues,
                          true,
                          true
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={sizesClass.rightSide}>
        <div className="configurazioneSID pt-2 pb-1">
          <SidContactFormContainer
            disabled={props.disabled}
            id={props.subjectId || 0}
            sizes={sizesClass}
            errors={props.errorsSid}
            nameApplication={nameApplication}
            subject={props.subject}
            defaultEmail={props.defaultEmail}
            coraSubjectSid={props.coraSubjectSid}
            initialCoraSubjectSid={props.initialCoraSubjectSid}
            certificateFiles={certificateFiles}
            fiscalCode={values.fiscalCode}
            setCertificateFiles={setCertificateFiles}
            setIsCertSidOpened={setIsCertSidOpened}
            setCoraSubjectSid={props.setCoraSubjectSid}
            setErrorCertificates={(errorCertificates) =>
              props.setErrorsSid({ ...props.errorsSid, errorCertificates })
            }
            checkSid={checkSid}
            handleChangeSid={handleChangeSid}
            handleChangeSidValues={handleChangeSidValues}
            reloadList={() => {
              props.setInitialCoraSubjectSid(props.coraSubjectSid)
              props.reloadList()
              handleFormSubmit()
            }}
          />
        </div>
      </div>
      <div className="form-row mt-2 row align-item-center">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary me-4"
              onClick={() => props.close(true)}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
            {window.innerWidth > Constants.BREAKPOINT_LG && (
              <div className="ms-4">
                <b className="me-2">*</b>
                <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <button
              type="submit"
              disabled={props.disabled || props.loading}
              className="btn btn-primary ml-2"
              onClick={() => handleFormSubmit()}>
              <i className="thx-floppy-disk thx-icon me-2" />
              {labels.SAVE}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
