import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import getText from '../i18n/labels'

// COMPONENTS
import Alert from 'react-bootstrap/Alert'

function ErrorListAlert(props) {
  const [show, setShow] = useState(true)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  // Effetto per riaprire l'alert ogni volta che cambiano gli errori
  useEffect(() => {
    if (props.errors && Object.keys(props.errors).length > 0) {
      setShow(true) // Riapre l'alert quando arrivano nuovi errori
    }
  }, [props.errors])

  function hide() {
    setShow(false)
    if (props.hide) props.hide()
  }

  return (
    show &&
    Object.keys(props.errors)?.length > 0 && (
      <Alert variant="danger" className="p-2 mb-2 error-alert" onClose={() => hide()} dismissible>
        <Alert.Heading className="m-0">
          <div className="d-flex align-items-center">
            <i className="thx-delete-circle thx-icon me-2" />
            <span className="error-list-label">{props.label || labels.FORM_INCOMPLETE}</span>
          </div>
        </Alert.Heading>
        {props.errors && Object.keys(props.errors)?.length > 0 && (
          <div className="pt-3">
            {props.errors &&
              Object.entries(props.errors).map(([key, value], index) => (
                <span key={key}>
                  <b>{value.errorLabel}:</b> {value.errorMessage}
                  {index !== Object.entries(props.errors)?.length - 1 && <span>,</span>}
                  <span className="me-2"></span>
                </span>
              ))}
          </div>
        )}
      </Alert>
    )
  )
}

export default ErrorListAlert
