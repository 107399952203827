import { memo, useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Utility from '../../../shared/Utility'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { getBalanceRulesByRelationshipType, getDisabledFields } from '../BalanceRules'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import { YearlyBalanceExportModal } from './NewYearlyBalanceExportModal'
import { YearlyBalanceImportModal } from './NewYearlyBalanceImportModal'
import { CreateCommunicationModal } from '../communications/NewCreateCommunicationModal'

// FORM
import { BwmSelect } from '../../../shared/form/BwmSelect'
import { BwmInput } from '../../../shared/form/BwmInput'

import NumberFormat from 'react-number-format'

// COMPONENTS
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton } from 'react-bootstrap'

import RowSpinner from '../../../shared/spinner/Spinner'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'

const defaultSize = (size = null) => ({
  checkbox: Utility.getSize(size, { default: '3' }),
  registry: Utility.getSize(size, { default: '10' }),
  relationshipType: Utility.getSize(size, { default: '10' }),
  startDate: Utility.getSize(size, { default: '7' }),
  endDate: Utility.getSize(size, { default: '7' }),
  amount1: Utility.getSize(size, { default: '6' }),
  amount2: Utility.getSize(size, { default: '6' }),
  amount3: Utility.getSize(size, { default: '6' }),
  amount4: Utility.getSize(size, { default: '6' }),
  amount5: Utility.getSize(size, { default: '6' }),
  stock: Utility.getSize(size, { default: '6' }),
  currency: Utility.getSize(size, { default: '6' }),
  nature: Utility.getSize(size, { default: '15' }),
  result: Utility.getSize(size, { default: '6' })
})

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '250%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '170%'
  return '100%'
}

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

//memoizzo la cella per impedire rerender non necessari. Potrebbe valere la pena memoizzare l'intera riga
const NumberCell = memo(function NumberCell({
  value,
  valueName,
  relationship,
  className,
  disabled,
  rules,
  handler
}) {
  return (
    <StarTooltip text={rules?.[valueName]}>
      <div className={`${className} number-format-label`}>
        <NumberFormat
          key={`${valueName}_${relationship.id}`}
          name={`${valueName}_${relationship.id}`}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={0}
          fixedDecimalScale={true}
          className="form-control form-control-cell"
          inputMode="numeric"
          value={disabled ? '-' : value}
          readOnly={disabled}
          onValueChange={(e) => handler(relationship, valueName, e)}
        />
      </div>
    </StarTooltip>
  )
})

export function YearlyBalanceList(props) {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [allCheck, setAllCheck] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [dropdownYears, setDropdownYears] = useState([])
  const [sort, setSort] = useState({ order: null, activeKey: null })
  const [selectedYear, setSelectedYear] = useState(
    new Date().getMonth() > 4 ? new Date().getFullYear() : new Date().getFullYear() - 1
  )
  const [relationships, setRelationships] = useState([])
  const [excludedItems, setExcludedItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAll, setShowAll] = useState(false)

  const subject = useSelector((state) => state.subject.data)
  const [yearlyBalanceRelationships, setYearlyBalanceRelationships] = useState([])
  const currencies = useSelector((state) => state.currencies.data)
  const currencyTypes = useSelector((state) => state.currencyTypes.data)

  const prevYearlyBalanceRelationshipsRef = useRef()

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    setRelationships(props.yearlyBalanceRelationships)
    _setDropdownYears()
    getYearlyBalance(props.subjectId, selectedYear)
    getAllCurrency()
  }, [])

  useEffect(() => {
    if (prevYearlyBalanceRelationshipsRef.current !== yearlyBalanceRelationships) {
      setRelationships(yearlyBalanceRelationships)
    }
    prevYearlyBalanceRelationshipsRef.current = yearlyBalanceRelationships
  }, [yearlyBalanceRelationships])

  /**
   *
   * @param {*} subjectId
   * @param {*} year
   * @param {*} page
   * @param {*} size
   */
  const getYearlyBalance = (subjectId, year, page, size) => {
    setLoading(true)
    actions.getCommunicationsBalanceBySubjectIdAndYear(subjectId, year, page, size).then((res) => {
      setYearlyBalanceRelationships(res)
      setLoading(false)
    })
  }

  const _showAll = () => {
    if (showAll) {
      setShowAll(false)
      getYearlyBalance(props.subjectId, selectedYear)
    } else {
      setShowAll(true)
      getYearlyBalance(props.subjectId, selectedYear, 0, 0)
    }
  }

  const getAllCurrency = () => {
    if (!currencies?.length) {
      dispatch(actions.getAllCurrency())
    }
    if (!currencyTypes?.length) {
      dispatch(actions.getAllCurrencyTypes())
    }
  }

  const openGuideBalances = () => {
    window.open('https://www.starsoluzioni.it/media/TABELLA-RAPPORTI.pdf')
    PopupSuccess({ text: labels.FILE_DOWNLOADED })
  }

  const saveBalances = () => {
    const yearlyBalances = {
      subjectId: parseInt(props.subjectId),
      year: String(selectedYear),
      relationships: relationships
    }

    return actions
      .updateYearlyBalances(yearlyBalances)
      .then(() => {
        PopupSuccess({ text: labels.DATA_SAVED_SUCCESSFULLY })

        // Se showAll è true, richiama getYearlyBalance con parametri extra
        if (showAll) {
          getYearlyBalance(props.subjectId, selectedYear, 0, 0)
        } else {
          getYearlyBalance(props.subjectId, selectedYear)
        }
      })
      .catch((errors) => PopupError({ text: labels.GENERIC_ERROR }))
  }

  const _setDropdownYears = () => {
    const initialYear = 2011 // anno di inizio comunicazione saldi come da normativa
    let currentYear = new Date().getFullYear()
    //vengono mostrati tutti gli anni dal 2011 fino a quello corrente
    let years = []
    for (let i = currentYear; i >= initialYear; i--) {
      years.push({ id: i, description: `Anno: ${i}` })
    }
    setDropdownYears(years)
  }

  const renderTable = (relationships) => {
    if (loading) return <RowSpinner />

    const header = <div className="text-start header-table">{renderRowHeader(relationships)}</div>
    let rows = relationships.map((relationship, index) => renderBalanceRows(relationship, index))

    const table = [header, ...rows]

    if (table.length === 1) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '99%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">{labels.NO_BALANCES_FOUND}</h3>
        </div>
      )
    }
    return (
      <div className="star-table-recipient">
        <div className="star-table" style={{ width: getTableContainerWidth(), height: '74vh' }}>
          {table}
        </div>
      </div>
    )
  }

  const handlerYearChange = (value) => {
    setSelectedYear(parseInt(value))
    getYearlyBalance(props.subjectId, parseInt(value))
    setShowAll(false)
  }

  const getRelationshipToUpdate = (relationships = [], relationship) => {
    let index = relationships.findIndex((rel) => rel.id === relationship?.id)
    if (index === -1) {
      relationships.push(relationship)
      index = relationships.findIndex((rel) => rel.id === relationship?.id)
    }

    if (relationships[index].coraRelationBalances === undefined) {
      relationships[index].coraRelationBalances = []
    }

    if (relationships[index].coraRelationBalances[0] === undefined) {
      relationships[index].coraRelationBalances[0] = {}
    }
    return index
  }

  //prende un valore dal NumberFormat. contiene valore in formato float, valore formattato in stringa e valore raw
  const handlerAmount = (relationship, field, value) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(newRelationships, relationship)
    newRelationships[index].coraRelationBalances[0][field] = value.floatValue
    setRelationships(newRelationships)
  }

  const handlerCurrency = (relationship, e) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(newRelationships, relationship)
    newRelationships[index].coraRelationBalances[0].currency = {
      id: e.target.value.trim(),
      isoCode: e.target.options[e.target.value].getAttribute('data-cod'),
      description: e.target.options[e.target.value].text
    }
    setRelationships(newRelationships)
  }

  const handlerCurrencyType = (relationship, e) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(newRelationships, relationship)
    if (Utility.isNotEmpty(e.target.value)) {
      newRelationships[index].coraRelationBalances[0].currencyType = {
        id: e.target.value.trim()
      }
    } else {
      newRelationships[index].coraRelationBalances[0].currencyType = null
    }
    setRelationships(newRelationships)
  }

  const handlerIdResult = (relationship, e) => {
    let newRelationships = cloneDeep(relationships)
    const index = getRelationshipToUpdate(relationships, relationship)
    newRelationships[index].coraRelationBalances[0].idResult = e.target.value.toUpperCase().trim()
    setRelationships(newRelationships)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRelationships = cloneDeep(relationships)
      newRelationships.forEach((relatioship) => (items[relatioship.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[event.target.value] = true
      else delete newCheckedItems[event.target.value]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === relationships?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handlerDontSendCommunication = () => {
    let selectedToExclude = cloneDeep(excludedItems)
    let newRelationships = cloneDeep(relationships)
    for (const checkedItem of Object.keys(checkedItems)) {
      if (checkedItem) {
        let idToRemove = parseInt(checkedItem)
        selectedToExclude.push(idToRemove)
        let index = newRelationships.map((item) => item.id).indexOf(idToRemove)
        newRelationships.splice(index, 1)
      }
    }
    setExcludedItems(selectedToExclude)
    setRelationships(newRelationships)
    resetCheckBox()
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
    document.getElementsByName('relationshipCheck').forEach((el) => {
      if (el.checked) {
        el.checked = false
      }
    })
  }

  const getSort = (key) => {
    if (sort.activeKey === key && sort.order) {
      if (sort.order === 1) return { activeKey: key, order: -1 }
      else if (sort.order === -1) return { activeKey: key, order: null }
      else return { activeKey: key, order: 1 }
    } else {
      return { activeKey: key, order: 1 }
    }
  }

  const sortBy = (val) => {
    if (val) {
      let newRelationships = cloneDeep(relationships)
      let relationshipsOrdered = cloneDeep(yearlyBalanceRelationships)
      const newSort = getSort(val)
      if (newSort.order) {
        switch (val) {
          case 'completeName':
            // Sort by completeName
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              return (
                compare * ((a.completeName > b.completeName) - (b.completeName > a.completeName))
              )
            })
            break
          case 'relType':
            // Sort by relationship type
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              const condition = a.relationshipType?.code > b.relationshipType?.code

              if (condition) {
                return compare * 1
              } else if (b.relationshipType?.code > a.relationshipType?.code) {
                return compare * -1
              } else {
                return compare * 0
              }
            })
            break
          case 'startDate':
            // Sort by startDate
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              const condition = moment(a.startDate).isAfter(moment(b.startDate))

              if (condition) {
                return compare * 1
              } else if (moment(b.startDate).isAfter(moment(a.startDate))) {
                return compare * -1
              } else {
                return compare * 0
              }
            })
            break
          case 'endDate':
            // Sort by endDate
            relationshipsOrdered = newRelationships.sort((a, b) => {
              const compare = newSort.order === 1 ? 1 : -1
              const condition = moment(a.endDate || new Date()).isAfter(
                moment(b.endDate || new Date())
              )

              if (condition) {
                return compare * 1
              } else if (moment(b.endDate || new Date()).isAfter(moment(a.endDate || new Date()))) {
                return compare * -1
              } else {
                return compare * 0
              }
            })
            break
        }
      }
      setRelationships(relationshipsOrdered)
      setSort(newSort)
    }
  }

  const getSortArrow = (key) => {
    if (key === sort.activeKey && sort.order === -1) {
      return <i className="thx-sort-down thx-icon pointer float-end me-2" />
    } else if (key === sort.activeKey && sort.order === 1) {
      return <i className="thx-sort-up thx-icon pointer float-end me-2" />
    }
  }

  const renderRowHeader = () => {
    return (
      <>
        <div className={`${sizesClass.checkbox} d-flex div-td justify-content-center`}>
          <input type="checkbox" checked={!!allCheck} onChange={(e) => handleAllCheckChange(e)} />
        </div>
        <div
          title={labels.REGISTRY}
          className={`${sizesClass.registry} div-td d-flex pointer`}
          onClick={() => sortBy('completeName')}>
          {getSortArrow('completeName')}
          {labels.REGISTRY}
        </div>
        <div
          title={labels.RELATIONSHIP_TYPE}
          className={`${sizesClass.relationshipType} div-td d-flex pointer`}
          onClick={() => sortBy('relType')}>
          {getSortArrow('relType')}
          {labels.RELATIONSHIP_TYPE}
        </div>
        <div
          title={labels.START_DATE}
          className={`${sizesClass.startDate} div-td d-flex pointer`}
          onClick={() => sortBy('startDate')}>
          {getSortArrow('startDate')}
          {labels.START_DATE}
        </div>
        <div
          title={labels.END_DATE}
          className={`${sizesClass.endDate} div-td d-flex pointer`}
          onClick={() => sortBy('endDate')}>
          {getSortArrow('endDate')}
          {labels.END_DATE}
        </div>
        <div title={labels.AMOUNT_1} className={`${sizesClass.amount1} div-td`}>
          {labels.AMOUNT_1}
        </div>
        <div title={labels.AMOUNT_2} className={`${sizesClass.amount2} div-td`}>
          {labels.AMOUNT_2}
        </div>
        <div title={labels.AMOUNT_3} className={`${sizesClass.amount3} div-td`}>
          {labels.AMOUNT_3}
        </div>
        <div title={labels.AMOUNT_4} className={`${sizesClass.amount4} div-td`}>
          {labels.AMOUNT_4}
        </div>
        <div className={`${sizesClass.amount5} div-td`}>
          <div>
            <StarTooltip text={labels.OTHER_INFO_AMOUNT_5}>
              <span>{labels.AMOUNT_5}</span>
            </StarTooltip>
          </div>
        </div>
        <div title={labels.AVERAGE_STOCK} className={`${sizesClass.stock} div-td`}>
          {labels.AVERAGE_STOCK}
        </div>
        <div title={labels.CURRENCY} className={`${sizesClass.currency} div-td`}>
          {labels.CURRENCY}
        </div>
        <div title={labels.NATURE} className={`${sizesClass.nature} div-td`}>
          {labels.NATURE}
        </div>
        <div title={labels.RESULT} className={`${sizesClass.result} div-td`}>
          {labels.RESULT}
        </div>
      </>
    )
  }

  const getDisplayedAmount = (balance, amount) => {
    if (Utility.isEmpty(balance?.id) && Utility.isEmpty(amount)) {
      return ''
    } else if (Utility.isNotEmpty(balance?.id) && Utility.isEmpty(amount)) {
      return '0'
    } else {
      return amount
    }
  }

  const renderBalanceRows = (relationship, index) => {
    let balance = relationship?.coraRelationBalances[0]

    let amount1Balance = getDisplayedAmount(balance, balance?.amount1)
    let amount2Balance = getDisplayedAmount(balance, balance?.amount2)
    let amount3Balance = getDisplayedAmount(balance, balance?.amount3)
    let amount4Balance = getDisplayedAmount(balance, balance?.amount4)
    let amount5Balance = getDisplayedAmount(balance, balance?.amount5)
    let stockBalance = getDisplayedAmount(balance, balance?.stock)
    const rules = getBalanceRulesByRelationshipType(relationship?.relationshipType?.code)
    const disabledFields = getDisabledFields(rules)

    let smallcolclass = 'px-2 padding-top-2px text-truncate'
    let smallcolClassNumber = `${smallcolclass} text-end border-right`
    return (
      <div
        className={`${index % 2 !== 0 ? 'bg-color' : ''} text-start row-table row-table-without-click`}
        id={`row-${relationship.id}`}>
        <div className={`${sizesClass.checkbox} d-flex justify-content-center div-td`}>
          <input
            type="checkbox"
            aria-label="Checkbox for following text input"
            id={`row-${relationship.id}`}
            name="relationshipCheck"
            value={relationship.id || ''}
            checked={checkedItems[relationship.id] || false}
            onChange={(e) => handleCheckChange(e)}
          />
        </div>
        <div
          title={relationship.completeName}
          className={`${sizesClass.registry} div-td text-truncate`}>
          {relationship.completeName}
        </div>
        <div
          title={
            relationship.relationshipType?.code + ' - ' + relationship.relationshipType?.description
          }
          className={`${sizesClass.relationshipType} div-td text-truncate`}>
          {relationship.relationshipType?.code + ' - ' + relationship.relationshipType?.description}
        </div>
        <div
          title={Utility.formatDateForDisplay(relationship.startDate)}
          className={`${sizesClass.startDate} px-2 text-truncate div-td text-truncate`}>
          {Utility.formatDateForDisplay(relationship.startDate)}
        </div>
        <div
          title={relationship.endDate && Utility.formatDateForDisplay(relationship.endDate)}
          className={`${sizesClass.endDate} px-2 text-truncate div-td text-truncate`}>
          {relationship.endDate && Utility.formatDateForDisplay(relationship.endDate)}
        </div>
        {renderNumberCell(
          amount1Balance,
          'amount1',
          relationship,
          `${sizesClass.amount1} ${smallcolClassNumber}`,
          disabledFields.amount1Disabled,
          rules,
          handlerAmount
        )}
        {renderNumberCell(
          amount2Balance,
          'amount2',
          relationship,
          `${sizesClass.amount2} ${smallcolClassNumber}`,
          disabledFields.amount2Disabled,
          rules,
          handlerAmount
        )}
        {renderNumberCell(
          amount3Balance,
          'amount3',
          relationship,
          `${sizesClass.amount3} ${smallcolClassNumber}`,
          disabledFields.amount3Disabled,
          rules,
          handlerAmount
        )}
        {renderNumberCell(
          amount4Balance,
          'amount4',
          relationship,
          `${sizesClass.amount4} ${smallcolClassNumber}`,
          disabledFields.amount4Disabled,
          rules,
          handlerAmount
        )}
        {renderNumberCell(
          amount5Balance,
          'amount5',
          relationship,
          `${sizesClass.amount5} ${smallcolClassNumber}`,
          disabledFields.amount5Disabled,
          rules,
          handlerAmount
        )}
        {renderNumberCell(
          stockBalance,
          'stock',
          relationship,
          `${sizesClass.stock} ${smallcolClassNumber}`,
          disabledFields.stockDisabled,
          rules,
          handlerAmount
        )}
        <div className={`${sizesClass.currency} ${smallcolclass} border-right`}>
          <BwmSelect
            options={currencies}
            showCode={true}
            name={`currency_${relationship.id}`}
            className="form-control form-control-cell"
            nameKey={'currencies'}
            onChange={(e) => handlerCurrency(relationship, e)}
            value={balance?.currency?.id || 58}
          />
        </div>
        <div className={`${sizesClass.nature} ${smallcolclass} border-right`}>
          <BwmSelect
            options={currencyTypes}
            showCode={true}
            name={`currencyType_${relationship.id}`}
            className="form-control form-control-cell"
            onChange={(e) => handlerCurrencyType(relationship, e)}
            value={balance?.currencyType?.id || null}
          />
        </div>
        <div className={`${sizesClass.result} ${smallcolclass}`}>
          <BwmInput
            name={'idResult'}
            className={'form-control form-control-cell'}
            value={balance?.idResult || ''}
            onChange={(e) => handlerIdResult(relationship, e)}
          />
        </div>
      </div>
    )
  }

  /**
   *
   * @param {*} value
   * @param {*} valueName
   * @param {*} relationship
   * @param {*} className
   * @param {*} disabled
   * @param {*} rules
   * @param {*} handler
   * @returns
   */
  const renderNumberCell = (
    value,
    valueName,
    relationship,
    className,
    disabled,
    rules,
    handler
  ) => {
    return (
      <NumberCell
        value={value}
        valueName={valueName}
        relationship={relationship}
        className={className}
        disabled={disabled}
        rules={rules}
        handler={handler}
      />
    )
  }

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex" role="group" aria-label="action buttons">
              <div className="pe-2">
                <Dropdown onSelect={(eventKey) => handlerYearChange(eventKey)}>
                  <DropdownButton
                    title={`${labels.YEAR}: ${selectedYear}`}
                    bsPrefix={'dropdown-menu-green btn-fill m-0 me-1 pe-2'}>
                    {dropdownYears &&
                      dropdownYears.map((el, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            eventKey={el.id}
                            active={el.id === selectedYear}>
                            {el.description}
                          </Dropdown.Item>
                        )
                      })}
                  </DropdownButton>
                </Dropdown>
              </div>
              <div className="pe-2">
                <button
                  data-tip={labels.BALANCES_INSTRUCTIONS}
                  className="btn btn-outline-primary"
                  onClick={() => openGuideBalances()}>
                  <i className="thx-question thx-icon" />
                  {window.innerWidth > Constants.BREAKPOINT_LG && (
                    <span className="ms-2">{labels.BALANCES_INSTRUCTIONS}</span>
                  )}
                </button>
              </div>
              <div className="pe-2">
                <button className="btn btn-outline-primary" onClick={() => _showAll()}>
                  {showAll
                    ? `${labels.SHOW_FIRST} ${actions.BALANCE_DEFAULT_PAGE_SIZE}`
                    : labels.SHOW_ALL}
                </button>
              </div>
              <div className="pe-2">
                <YearlyBalanceExportModal
                  disabled={false}
                  selectedYear={selectedYear}
                  subjectId={props.subjectId}
                />
              </div>
              <div className="pe-2">
                <YearlyBalanceImportModal
                  subjectId={props.subjectId}
                  selectedYear={selectedYear}
                  getYearlyBalance={() => getYearlyBalance(props.subjectId, selectedYear, 0, 0)}
                />
              </div>
              <div>
                <button
                  type="button"
                  data-tip={labels.EXCLUDE_FROM_COMMUNICATION}
                  className={`btn btn-outline-primary ${Object.keys(checkedItems)?.length === 0 && 'disabled'}`}
                  disabled={Object.keys(checkedItems)?.length === 0}
                  onClick={handlerDontSendCommunication}>
                  <i className="thx-exclude thx-icon" />
                  {window.innerWidth > Constants.BREAKPOINT_LG && (
                    <span className="ms-2">{labels.EXCLUDE_FROM_COMMUNICATION}</span>
                  )}
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-end" role="group" aria-label="action buttons">
              <button className="btn btn-outline-primary" onClick={saveBalances}>
                <i className="thx-floppy-disk thx-icon me-2" />
                {labels.SAVE}
              </button>
              <span className="ms-2">
                <CreateCommunicationModal
                  type="YEARLY"
                  subjectId={props.subjectId}
                  subject={subject}
                  selectedYear={selectedYear}
                  excludedRelationships={excludedItems}
                  disabled={relationships?.length === 0}
                  onClickCreateCommunicationBefore={saveBalances}
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      {renderTable(relationships)}
    </>
  )
}
