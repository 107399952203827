import { useEffect, useState } from 'react'
import { generateUniqueKey } from '../Utility'

// COMPONENTS
import Autosuggest from 'react-autosuggest'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const getSuggestions = (value, filter = [], filterValues) => {
  if (filterValues === false) {
    return filter
  }

  const escapedValue = escapeRegexCharacters(value.trim())

  if (escapedValue === '') {
    return []
  }
  const regex = new RegExp('^.*' + escapedValue + '.*$', 'i')
  const suggestions = filter.filter((datas) => regex.test(datas.name))

  return suggestions
}

export const AutoCompileCustom = (props) => {
  const [value, setValue] = useState(props.value || '')
  const [suggestions, setSuggestions] = useState([])
  const [guid, setGuid] = useState()

  useEffect(() => {
    setGuid(generateUniqueKey('id'))
    if (document.getElementsByClassName('react-autosuggest__input').length === 1) {
      document.getElementsByClassName('react-autosuggest__input')[0].classList.add('form-control')
    }
  }, [])

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value || '')
    }
  }, [props.value])

  useEffect(() => {
    if (guid) {
      const formFloating = document.querySelector(`#${guid}`)
      if (formFloating) {
        if (value) {
          formFloating.classList.add('input-has-value')
        } else {
          formFloating.classList.remove('input-has-value')
        }
      }
    }
  }, [guid, value])

  const onChange = (event, { newValue, method }) => {
    setValue(newValue?.toUpperCase() || '')
    props.handleInputAutocompile(newValue, props.id)
  }

  const onSuggestionSelected = (event, { suggestionValue, method }) => {
    if (method === 'click') {
      setValue(suggestionValue)
      props.handleInputAutocompile(suggestionValue)
    } else {
      event.preventDefault()
    }
  }

  const getSuggestionValue = (suggestion) => {
    return suggestion.name
  }

  const renderSuggestion = (suggestion) => {
    return suggestion.name
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, props.filter, props.filterValues))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const inputProps = { value, onChange, disabled: props.disabled }
  const inputWrapperClass = `input-wrapper w-100 ${props.touched && props.error !== undefined ? 'has-error' : ''}`

  return (
    <div id={guid} className={inputWrapperClass}>
      <FloatingLabel title={props.label} id="floatingLabel" label={props.label}>
        <Autosuggest
          suggestions={suggestions}
          shouldRenderSuggestions={() => true}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </FloatingLabel>
    </div>
  )
}
