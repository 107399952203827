import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getContactByLogin, saveInlineContactInfo, searchContactInfos } from '../../../actions'
import * as Constants from '../../../config/Constants'
import { formatDateTimeForDisplay } from '../../shared/Utility'
import getText from '../../shared/i18n/labels'

// TABLE
import { TableUtils, baseSearchObject, fieldTypes } from '../../shared/tables/TableUtils'
import { StarTable } from '../../shared/tables/StarTable'

//MODAL
import { ContactInfoModal } from './NewContactInfoModal'

// FORM
import { BwmSelect } from '../../shared/form/BwmSelectB5'

// BUTTON
import { BtnFill } from '../../shared/BtnFill'

// COMPONENTS
import { PopupError } from '../../shared/popups/PopupError'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '200%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '140%'
  return '100%'
}

export function StarTel() {
  const queryParameters = new URLSearchParams(window.location.search)
  const selectedLogin = queryParameters.get('login')

  const [rows, setRows] = useState([])
  const [editedIds, setEditedIds] = useState([])
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    pageSize: 50,
    denomination: '',
    callPriority: [],
    login: '',
    callDate: '',
    caller: '',
    calledContact: '',
    callNotes: ''
  })

  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)

  const [selectedContactNumber, setSelectedContactNumber] = useState(null)

  const callerOptions = [
    { id: 'Silvana', description: 'Silvana' },
    { id: 'Valentina', description: 'Valentina' }
  ]

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [caller, setCaller] = useState(callerOptions[0])

  const baseContact = {
    callPriority: 'SOSPESO',
    contactNumbers: [],
    contactHistory: [],
    contactApplications: []
  }

  const PRIORITIES = [
    { id: 'NUOVO_CLIENTE', description: labels.NEW_CLIENT },
    { id: 'SOSPESO', description: labels.SUSPENDED }
  ]

  useEffect(() => {
    if (selectedLogin) {
      getContactByLogin(selectedLogin).then((res) => {
        if (res) {
          if (!res.contactNumbers) {
            res.contactNumbers = []
          }
          openContactModalAndAddNewHistory(res, res.contactNumbers[0])
        } else {
          openContactModal(baseContact)
        }
      })
    }
  }, [])

  const openContactModal = (contactInfo, contactNumber) => {
    setSelectedContact(contactInfo)
    setSelectedContactNumber(contactNumber || contactInfo.contactNumbers[0])
    setIsContactModalOpen(true)
  }

  const openContactModalAndAddNewHistory = (contactInfo, contactNumber) => {
    openContactModal(contactInfo, contactNumber)
  }

  const closeContactModal = () => {
    setIsContactModalOpen(false)
    setSelectedContact(null)
    setSelectedContactNumber(null)
    getCustomerContacts(editedIds)
  }

  const onEdit = (newList = [], id) => {
    let arr = editedIds
    arr.push(id)
    setEditedIds(arr)
    setRows(prepareRows(newList, editedIds))
  }

  const onUpdate = (contactInfo) => {
    saveInlineContactInfo(contactInfo).then(
      (res) => {
        const ids = editedIds.filter((id) => id !== contactInfo.id)
        setEditedIds(ids)
        getCustomerContacts(ids)
      },
      (err) => {
        PopupError({ text: err.title || labels.GENERIC_ERROR })
      }
    )
  }

  const getCustomerContacts = (ids) => {
    searchContactInfos(searchObject).then((res) => {
      setRows(prepareRows(res.content, ids))
    })
  }

  const header = [
    TableUtils.composeHeader({
      fieldName: 'denomination',
      displayedName: labels.DENOMINATION,
      maxWidth: '3.3fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'login',
      displayedName: labels.USER,
      maxWidth: '3.8fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'calledContact',
      displayedName: labels.CONTACT,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'phoneNumbers',
      displayedName: labels.PHONE,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'callDate',
      displayedName: labels.LAST_CALL,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'caller',
      displayedName: labels.CALLED_FROM,
      maxWidth: '1.7fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'callNotes',
      displayedName: labels.NOTES,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'callPriority',
      displayedName: labels.PRIORITY,
      type: fieldTypes.SELECTION,
      options: PRIORITIES,
      maxWidth: '1.4fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'save',
      displayedName: labels.EDIT,
      additionalClass: 'd-flex justify-contnet-center',
      sortable: false,
      searchable: false,
      maxWidth: '1fr'
    })
  ]

  const prepareRows = (customerContacts = [], ids = []) => {
    return customerContacts.map((c) => {
      return TableUtils.composeRow({
        id: c.id,
        cellData: {
          login: TableUtils.composeCell({
            fieldName: 'login',
            fieldValue: c.login,
            component: () => {
              return (
                <div className=" d-flex align-items-center justify-content-between">
                  <span
                    className="text-truncate"
                    style={{ maxWidth: '75%' }}
                    onClick={() => openContactModal(c)}>
                    {c.login}
                  </span>
                  <div className=" d-flex align-items-center">
                    {c.login && (
                      <button
                        title={labels.COPY_EMAIL}
                        type="button"
                        className="ms-1 btn btn-primary btn-cell"
                        onClick={() => navigator.clipboard.writeText(c.login)}>
                        E
                      </button>
                    )}
                    {c.pwd && (
                      <button
                        title={labels.COPY_PASSWORD}
                        type="button"
                        className="ms-1 ms-1 btn btn-primary btn-cell"
                        onClick={() => navigator.clipboard.writeText(c.pwd)}>
                        P
                      </button>
                    )}
                  </div>
                </div>
              )
            }
          }),
          denomination: TableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: c.denomination,
            onClick: () => openContactModal(c)
          }),
          phoneNumbers: TableUtils.composeCell({
            fieldName: 'phoneNumbers',
            fieldValue: c.contactNumbers.map((el) => `${el.phone} - ${el.denomination}`).join('; '),
            component: () => {
              return (
                <div className="d-flex">
                  {c.contactNumbers.map((el, index) => (
                    <button
                      className="px-0 me-1"
                      key={index}
                      onClick={() => openContactModalAndAddNewHistory(c, el)}>
                      {el.phone}
                      {el.phone && index !== c.contactNumbers?.length - 1 && ';'}
                    </button>
                  ))}
                </div>
              )
            }
          }),
          callDate: TableUtils.composeCell({
            fieldName: 'callDate',
            fieldValue: formatDateTimeForDisplay(c?.contactHistory?.date),
            onClick: () => openContactModal(c)
          }),
          caller: TableUtils.composeCell({
            fieldName: 'caller',
            fieldValue: c?.contactHistory?.caller,
            onClick: () => openContactModal(c)
          }),
          calledContact: TableUtils.composeCell({
            fieldName: 'calledContact',
            fieldValue: c?.contactHistory?.contact,
            onClick: () => openContactModal(c)
          }),

          callNotes: TableUtils.composeCell({
            fieldName: 'callNotes',
            fieldValue: c?.contactHistory?.notes,
            onClick: () => openContactModal(c)
          }),
          callPriority: TableUtils.composeCell({
            fieldName: 'callPriority',
            fieldValue: c.callPriority,
            editable: true,
            onEdit: (rowId, fieldName, value) =>
              onEdit(
                TableUtils.updateList({ list: customerContacts, rowId, fieldName, value }),
                c.id
              )
          }),
          save: TableUtils.composeCell({
            fieldName: 'save',
            component: () => {
              return (
                <div className="w-100 d-flex justify-content-center">
                  <button
                    disabled={!ids.includes(c.id)}
                    className="btn btn-primary btn-cell"
                    onClick={() => onUpdate(c)}>
                    {labels.SAVE}
                  </button>
                </div>
              )
            }
          })
        }
      })
    })
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-1 mb-2">
        <div>
          <BtnFill
            text={labels.ADD_CLIENT}
            handlerOnClick={() => openContactModal(baseContact)}></BtnFill>
        </div>
        <div className="text-center">
          <h2 className="m-0">StarTel</h2>
        </div>
        <div className="text-center w-15 w-md-20">
          <BwmSelect
            className={'form-control'}
            label={labels.CALLER_DEFAULT}
            options={callerOptions}
            value={caller?.id}
            onChange={(e) => setCaller(callerOptions.find((o) => o.id === e.target.value))}
          />
        </div>
      </div>

      <StarTable
        version="1"
        headerColumns={header}
        rows={rows}
        tableConfigurationKey={'adminContactsList'}
        tableContainerWidth={getTableContainerWidth()}
        searchObjectPrototype={searchObject}
        setSearchObject={(newSearchObject) => setSearchObject(newSearchObject)}
        searchCallBack={searchContactInfos}
        onExecutedSearch={(content) => {
          setRows(prepareRows(content, editedIds))
        }}
      />
      {isContactModalOpen && (
        <ContactInfoModal
          show={isContactModalOpen}
          contactInfo={selectedContact}
          onClose={closeContactModal}
          onSave={() => {
            closeContactModal()
            getCustomerContacts(editedIds)
          }}
          selectedContactNumber={selectedContactNumber}
          defaultCaller={caller?.description}
        />
      )}
    </>
  )
}
