import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import getText from '../../shared/i18n/labels'
import { base64ToArrayBuffer, downloadFile, formatDateForDisplay } from '../../shared/Utility'
import * as actions from '../../../actions'

// COMPONENTS
import { PopupError } from '../../shared/popups/PopupError'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../shared/popups/PopupSuccess'
import RowSpinner from '../../shared/spinner/Spinner'

export default function BlueHistoryModal(props) {
  const [searchEvents, setSearchEvents] = useState([])
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)
  const [loading, setLoading] = useState(false)
  const [rowLoading, setRowLoading] = useState(false)

  useEffect(() => {
    setRowLoading(true)
    actions.getBlueSearchEvents(props.subjectId).then((res) => {
      setSearchEvents(res)
      setRowLoading(false)
    })
  }, [props.subjectId])

  const downloadDocument = (searchEvent) => {
    setLoading(true)
    actions
      .blueDownloadAntiTerrorism(searchEvent.blueSubject.id, searchEvent.id, null, lang)
      .then(
        (response) => {
          if (response) {
            let ext = searchEvent.fileContentType.includes('pdf') ? '.pdf' : '.zip'
            downloadFile(
              base64ToArrayBuffer(response),
              `${searchEvent.blueSubject.denomination}-${moment(searchEvent.searchDate).format('YYYY-MM-DD')}${ext}`
            )
            PopupSuccess({
              title: labels.SUCCESSTITLE,
              text: labels.DOWLOAD_OK,
              ok: labels.SUCCESSOK
            })
          } else {
            PopupError({
              text: labels.FILEERROR,
              title: labels.WARNING,
              request: labels.CONTACT_SUPPORT,
              close: labels.CLOSE
            })
          }
        },
        (err) => {
          PopupError({
            text: labels.FILEERROR,
            title: labels.WARNING,
            request: labels.CONTACT_SUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => setLoading(false))
  }

  const renderSearchTerms = (terms) => {
    if (terms) {
      let object = JSON.parse(terms)

      if (!object.subject) {
        return `${labels.MASSIVE}`
      } else if (object.entities && object.entities.length > 0) {
        let termsStr = ``
        let entity = object.entities[0]
        if (entity.generic) {
          return `${labels.GENERICSEARCH}: "${entity.generic}"`
        } else {
          if (entity.wholeName) {
            termsStr += `${labels.WHOLENAME}: "${entity.wholeName}"; `
          }
          if (entity.firstName) {
            termsStr += `${labels.FIRSTNAME}: "${entity.firstName}"; `
          }
          if (entity.lastName) {
            termsStr += `${labels.LASTNAME}: "${entity.lastName}"; `
          }
          if (entity.birthDate) {
            termsStr += `${labels.BIRTH_DATE}: "${entity.birthDate}"; `
          }
          if (entity.birthPlace) {
            termsStr += `${labels.BIRTH_PLACE}: "${entity.birthPlace}"; `
          }
          if (entity.passportNumber) {
            termsStr += `${labels.PASSPORTNUMBER}: "${entity.passportNumber}"; `
          }
          if (entity.cityzenCountry) {
            termsStr += `${labels.CITIZEN_COUNTRY}: "${entity.passportNumber}"; `
          }
          if (object.precision) {
            termsStr += `${labels.PRECISION}: "${getPrecisionLabel(object.precision)}"; `
          }
        }
        return termsStr.slice(0, termsStr.length - 2)
      }
    }
  }

  const getPrecisionLabel = (precision) => {
    switch (precision) {
      case '0':
        return labels.LOWEST
      case '1':
        return labels.LOW
      case '2':
        return labels.MEDIUM
      case '3':
        return labels.HIGH
      default:
        return ''
    }
  }

  const renderRows = () => {
    return searchEvents?.map((el) => {
      let terms = renderSearchTerms(el.searchTerms)
      return (
        <div key={'blueHistory_' + el.id} className={'text-start row-table'}>
          <div className="w-15 div-td text-start text-truncate ">
            {formatDateForDisplay(el.searchDate)}
          </div>
          <div className="w-75 div-td text-start ">{terms} </div>
          <div className="w-10 div-td text-center" onClick={() => downloadDocument(el)}>
            {el.fileContentType?.includes('pdf') ? (
              <i className="thx-pdf thx-icon bg-primary" />
            ) : (
              <i className="thx-zip thx-icon bg-primary" />
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      {loading && <PageSpinner text={labels.SPINNER_TITLE} />}
      <div className="star-table">
        <div className="text-start header-table">
          <div className="w-15 div-td">{labels.SEARCH_DATE}</div>
          <div className="w-75 div-td">{labels.SEARCH_TERM}</div>
          <div className="w-10 div-td">Download</div>
        </div>

        {rowLoading && <RowSpinner />}

        {!rowLoading && !searchEvents?.length > 0 && (
          <div className="col-12 ps-0 pe-0 empty-state-history-modal">
            <td>
              <i
                className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
                style={{ width: '56px', height: '56px' }}
              />
              <h4>{labels.NORESULTS}</h4>
            </td>
          </div>
        )}

        {!rowLoading && searchEvents?.length > 0 && renderRows()}
      </div>
    </>
  )
}
