import { useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import { formatDateForDisplay } from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { ArcoRegistryPrintForm } from './NewArcoRegistryPrintForm'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export function ArcoRegistryPrintModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const getSubjectName = () => {
    return props.arcoSubject?.companyName
  }

  const getSurnameOrDenomination = (item) => {
    if (item?.personType) {
      return (
        Constants.PF === item.personType ? item.lastName + ' ' + item.firstName : item.denomination
      ).toUpperCase()
    }
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>
              <strong>{'< ' + getSubjectName()}</strong>
            </h3>
            <h3>
              <span>{labels.OPERATION + ': '}</span>
              <strong>{props?.currentOperation?.description}</strong>
              {' DEL ' + formatDateForDisplay(props?.currentOperation?.createDate)}
            </h3>
            <h3>
              <span>{labels.SUBJECT + ': '}</span>
              <strong>{getSurnameOrDenomination(props.selectedPrintItem)}</strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ArcoRegistryPrintForm
              onHide={props.onHide}
              key={'printModal-' + props.selectedPrintItem?.id}
              arcoSubject={props.arcoSubject}
              arcoRegistriesOperations={props?.currentOperation}
              selectedPrintItem={props.selectedPrintItem}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
