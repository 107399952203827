import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep, size } from 'lodash'
import { formatDateForDisplay } from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// TABLE
import { ArcoRegistriesList } from './NewArcoRegistriesList'

// POPUPS
import { PopupError } from '../../../shared/popups/PopupError'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export function ArcoRegistrySignatories(props) {
  const [showModal, setShowModal] = useState(false)
  const [allCheck, setAllCheck] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const [signatories, setSignatories] = useState(props.signatories || [])

  useEffect(() => {
    setSignatories(props.signatories || [])
  }, [props.signatories])

  const handleShowModal = () => {
    setShowModal(!showModal)
  }

  const addRegistry = (registry) => {
    if (registry) {
      if (Constants.PF === registry.personType) {
        props.addSignatory(registry)
        handleShowModal()
      } else {
        PopupError({ text: labels.NOT_ALLOWED_TO_INSERT_LEGAL_PERSONS })
      }
    }
  }

  const deleteArcoArcoRegistrySignatoriesSubject = () => {
    const idsToDelete = Object.keys(checkedItems)
    if (props?.selectedItem?.id) {
      actions.deleteArcoRegistriesSignatories(props.selectedItem.id, idsToDelete).then(
        () => {
          //chiudo form se contiene l'elemento cancellato
          props.removeSignatory(idsToDelete)
          resetCheckBox()
        },
        (errors) => PopupError({ text: labels.GENERIC_ERROR })
      )
    } else {
      props.removeSignatory(idsToDelete)
      resetCheckBox()
    }
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newControllingPersonRows = cloneDeep(signatories)
      newControllingPersonRows.forEach((el) => (items[el.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === signatories?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const renderRows = () => {
    if (!signatories?.length > 0) {
      return (
        <div className="col-12 ps-0 pe-0 empty-state-history-modal py-2">
          <div>
            <i
              className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
              style={{ width: '30px', height: '30px' }}
            />
            <h6>{labels.NORESULTS}</h6>
          </div>
        </div>
      )
    }
    return signatories.map((item, index) => {
      return (
        <div key={index} className=" text-start row-table" id={`signatories-${item.id}`}>
          <div className={`${props.sizes.checkbox} text-center div-td`}>
            <input
              type="checkbox"
              name="rowCheckBox"
              id={`checkboxDetailsSignatories${item.id || '-1'}`}
              checked={checkedItems[item.id] || false}
              onChange={(e) => handleCheckChange(e, item?.id)}
            />
          </div>

          <div
            className={`${props.sizes.firstName} div-td`}
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.firstName?.toUpperCase()}
          </div>
          <div
            className={`${props.sizes.lastName} div-td`}
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.lastName?.toUpperCase()}
          </div>
          <div
            className={`${props.sizes.fiscalCode} div-td`}
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.fiscalCode?.toUpperCase()}
          </div>
          <div
            className={`${props.sizes.birthDate} div-td`}
            onClick={() => props.openChildRegistryModal(item)}>
            {formatDateForDisplay(item?.birthDate)}
          </div>
          <div
            className={`${props.sizes.location} div-td`}
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.location?.toUpperCase()}
          </div>
          <div
            className={`${props.sizes.province} div-td`}
            onClick={() => props.openChildRegistryModal(item)}>
            {item?.province?.toUpperCase()}
          </div>
        </div>
      )
    })
  }

  return (
    <div className="col-12">
      <div className="row pt-2">
        <div className="col-9 mb-2">
          <div className="btn-group" role="group" aria-label="action buttons">
            <button
              type="button"
              disabled={Object.keys(checkedItems)?.length === 0 || props.disabled}
              className="btn btn-outline-primary"
              onClick={() => deleteArcoArcoRegistrySignatoriesSubject()}>
              <i className="thx-trash thx-icon me-2" />
              {labels.DELETE}
            </button>
            <button disabled={props.disabled} className="btn btn-primary" onClick={handleShowModal}>
              <i className="thx-plus thx-icon me-2" />
              {labels.CONNECT_REGISTRY}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 w-all star-table">
        <div className="text-start header-table p-0">
          <div className={`${props.sizes.checkbox} text-center div-td`}>
            <input
              disabled={!(signatories?.length > 0)}
              name="tableCheckBox"
              type="checkbox"
              checked={allCheck}
              onChange={(e) => handleAllCheckChange(e)}
            />
          </div>
          <div className={`${props.sizes.firstName} div-td`}>{labels.FIRST_NAME}</div>
          <div className={`${props.sizes.lastName} div-td`}>{labels.LAST_NAME}</div>
          <div className={`${props.sizes.fiscalCode} div-td`}>{labels.FISCAL_CODE}</div>
          <div className={`${props.sizes.birthDate} div-td`}>{labels.BIRTH_DATE}</div>
          <div className={`${props.sizes.location} div-td`}>{labels.LOCATION}</div>
          <div className={`${props.sizes.province} div-td`}>{labels.PROVINCE}</div>
        </div>

        {renderRows()}
      </div>
      {showModal && (
        <div>
          <ArcoRegistriesList
            modalShow={false}
            arcoSubjectId={props.arcoSubjectId}
            arcoSubject={props.arcoSubject}
            showModalRegistriesList={showModal}
            onHide={handleShowModal}
            fromSignatories={props.selectedItem}
            fromRegistry={props.selectedItem}
            onSelectedItem={addRegistry}
          />
        </div>
      )}
    </div>
  )
}
