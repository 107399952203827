import { isValid } from 'date-fns'
import { useSelector } from 'react-redux'
import { isValidDate } from '../Utility'
import Flatpickr from 'react-flatpickr'
import { Italian } from 'flatpickr/dist/l10n/it'
import { english } from 'flatpickr/dist/l10n/default'
import 'flatpickr/dist/flatpickr.css'

export const DateField = (props) => {
  const renderErrorMessage = (date) => {
    if (date && !isValidDate(new Date(date))) {
      return <div className="text-danger">Inserire data valida</div>
    } else if (props.error && !props.error.isValid) {
      return <div className="text-danger">{props.error?.msg}</div>
    }
    return <></>
  }

  const lang = useSelector((state) => state.language.language)

  return (
    <>
      <div className={`form-group ${props.formClassName || ''}`}>
        {props.label && <label>{props.label}</label>}
        <div className="input-group">
          <Flatpickr
            className={`form-control form-control-date ${props.className || ''}`}
            placeholder={lang === 'it' ? 'gg/mm/aaaa' : 'dd/mm/yyyy'}
            options={{
              maxDate: '2500-01-01',
              allowInput: true,
              dateFormat: 'd/m/Y',
              language: lang === 'it' ? Italian : english,
              locale: lang
            }}
            value={props.date !== null && isValid(new Date(props.date)) ? props.date : ''}
            onChange={([date]) =>
              props.onChange(isValidDate(new Date(date)) ? new Date(date) : undefined)
            }
          />
        </div>
        {renderErrorMessage(props.date)}
      </div>
    </>
  )
}
