import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import elenco from '../../../../styles/images/elenco.png'
import spedire from '../../../../styles/images/spedire.png'
import arrow_l from '../../../../styles/images/arrow_l.png'

import { Tabs } from '../../../shared/Tabs'
import { CrsRelationshipList } from './NewCrsRelationshipList'
import { CommunicationsHistoryList } from '../communications/NewCommunicationsHistoryList'
import { CheckLicenseAndRedirect } from '../../../shared/auth/license/LicenseChecker'
import { APPLICATION_CRS } from '../../../../config/Constants'

export const CrsRelationships = ({ setId }) => {
  const [subject, setSubject] = useState(undefined)

  const params = useParams()

  if (params.id) setId(params.id)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const history = useHistory()

  const auth = useSelector((state) => state.auth)

  const [activeTab, setActiveTab] = useState('list-of-relationship')

  useEffect(() => {
    getSubject(params.id)
  }, [params.id])

  useEffect(() => {
    if (auth?.user?.customer) {
      CheckLicenseAndRedirect(
        history,
        '/app/crs/dashboard',
        auth?.user?.customer?.customerApplications,
        APPLICATION_CRS,
        setId
      )
    }
  }, [auth, history])

  const getSubject = () => {
    actions.getCrsSubject(params.id).then((res) => {
      setSubject(res)
    })
  }

  const truncateTitle = () => {
    let newTitle = subject?.companyName || ''
    const truncate = 60
    if (newTitle.length > truncate) {
      return newTitle.slice(0, truncate) + '...'
    }
    return newTitle
  }

  const goToDashboard = () => {
    setId()
    history.push('/app/crs/dashboard')
  }

  const title = () => {
    return (
      <div className="d-flex title-page justify-content-between m-0 p-0 align-items-center me-4">
        <div className="cursor-pointer" onClick={goToDashboard}>
          <img
            src={arrow_l}
            className="ms-2 me-3 mb-2 mt-1"
            width="10"
            height="19"
            alt={labels.RETURN_TO_SUBJECT_LIST}
          />
          {truncateTitle()}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="table-custom">
        <Tabs className="mt-1" title={title()} activeTab={activeTab} setActiveTab={setActiveTab}>
          <div id="list-of-relationship" label={labels.LIST_OF_RELATIONSHIPS} icon={elenco}>
            {params.id && (
              <CrsRelationshipList auth={auth} subjectId={params.id} subject={subject} />
            )}
          </div>
          <div id="communication-history" label={labels.COMMUNICATION_HISTORY} icon={spedire}>
            <CommunicationsHistoryList subjectId={params?.id} auth={auth} />
          </div>
        </Tabs>
      </div>
    </>
  )
}
