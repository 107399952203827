import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { getBalanceRulesByRelationshipType } from '../BalanceRules'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import { ModalCreateBalance } from './NewModalCreateBalance'

// BUTTONS
import { BtnDelete } from '../../../shared/BtnDelete'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import RowSpinner from '../../../shared/spinner/Spinner'

import 'react-datepicker/dist/react-datepicker.css'
import * as styles from '../../../../config/styles'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'

export const BalanceForm = (props) => {
  const { relationship, currencies, subjectId } = props

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const noBalancesAllowedMessage = labels.NO_BALANCES_ALLOWED_MESSAGE
  const relationshipNotSavedMessage = labels.RELATIONSHIP_NOT_SAVED_MESSAGE
  const [balances, setBalances] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [checkedItems, setCheckedItems] = useState(new Map())
  const [allCheck, setAllCheck] = useState(false)
  const [balance, setBalance] = useState(null)
  const [newBalance, setNewBalance] = useState(false)
  const [disableAddBalanceButton, setDisableAddBalanceButton] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const handlerRefreshBalances = () => {
    if (relationship?.id) {
      setIsLoading(true)
      actions
        .getBalancesByRelationshipId(subjectId, relationship?.id)
        .then(
          (res) => {
            setBalances(res)
            resetCheckBox()
          },
          (errors) => {
            console.log(errors)
          }
        )
        .then(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    handlerRefreshBalances()
  }, [])

  useEffect(() => {
    setDisableAddBalanceButton(
      isEmpty(getBalanceRulesByRelationshipType(relationship?.relationshipType?.code))
    )
  }, [relationship])

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(balances)
      newRows.forEach((row) => (items[row.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === balances?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handlerSubmitBalance = (formDataBalance) => {
    setIsLoading(true)
    if (formDataBalance?.id) {
      actions
        .updateCoraBalance(formDataBalance)
        .then(
          (coraBalance) => {
            handlerRefreshBalances()
            props.onSave()
            closeModal()
          },
          (errors) => {
            closeModal()
            PopupError({ text: labels.ERROR_UPDATING_BALANCES })
          }
        )
        .then(() => setIsLoading(false))
    } else {
      actions
        .createCoraBalance(formDataBalance)
        .then(
          (coraBalance) => {
            handlerRefreshBalances()
            props.onSave()
            closeModal()
          },
          (errors) => {
            closeModal()
            PopupError({ text: labels.ERROR_INSERTING_BALANCES })
          }
        )
        .then(() => setIsLoading(false))
    }
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: labels.ANNUAL_BALANCES,
      handleClickConfirm: handlerDeleteBalance
    })
  }

  const handlerDeleteBalance = () => {
    Object.keys(checkedItems).forEach((key) => {
      if (key) {
        actions.deleteCoraBalance(key).then(
          () => {
            PopupSuccess({ text: labels.REMOVED_SUCCESSFULLY })
            handlerRefreshBalances()
          },
          (errors) => PopupError({ text: labels.GENERIC_ERROR })
        )
      }
    })
    props.onSave()
  }

  const formatCurrency = (amount, currencyCode) => {
    let body = {
      style: 'currency',
      currency: currencyCode || 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
    return amount.toLocaleString('it-IT', body)
  }

  const handlerShowBalanceForm = (balance) => {
    setNewBalance(false)
    if (isNotEmpty(balance)) {
      setBalance(balance)
    }
    setModalShow(true)
  }

  const addNewBalanceForm = () => {
    setBalance(null)
    setNewBalance(true)
    setModalShow(true)
  }

  const closeModal = () => {
    setModalShow(false)
  }

  const openGuideBalances = () => {
    window.open('https://www.starsoluzioni.it/media/TABELLA-RAPPORTI.pdf')
  }

  const displayDisabledTip = () => {
    if (!relationship.id) {
      return relationshipNotSavedMessage
    } else if (disableAddBalanceButton) {
      return noBalancesAllowedMessage
    }
    return null
  }

  const renderBalanceRows = () => {
    if (isLoading) {
      return <RowSpinner />
    } else if (!balances || balances?.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center border pt-2">
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '30px', height: '30px' }}
          />
          <h6>{labels.NO_RESULTS_TO_SHOW}</h6>
        </div>
      )
    }
    return (
      balances.length > 0 &&
      balances.map((balance, index) => (
        <div
          className="text-start row-table row-table-sm"
          key={'row-balance-' + index}
          id={'row-balance-' + index}>
          <div
            className={`${props.sizes.checkbox} mb-1 text-center div-td d-flex align-items-center justify-content-center`}>
            <input
              type="checkbox"
              id={`checkbox_${balance.id || ''}`}
              checked={checkedItems[balance.id] || false}
              onChange={(e) => handleCheckChange(e, balance.id)}
            />
          </div>
          <div
            className={`${props.sizes.year} div-td text-truncate`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {balance.year}
          </div>
          <div
            className={`${props.sizes.amount1} div-td text-truncate d-flex justify-content-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount1 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${props.sizes.amount2} div-td text-truncate d-flex justify-content-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount2 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${props.sizes.amount3} div-td text-truncate d-flex justify-content-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount3 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${props.sizes.amount4} div-td text-truncate d-flex justify-content-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount4 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${props.sizes.amount5} div-td text-truncate d-flex justify-content-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount5 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${props.sizes.stock} div-td text-truncate d-flex justify-content-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.stock || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${props.sizes.currency} div-td text-truncate`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {balance.currency !== undefined ? balance.currency?.isoCode : ''}
          </div>
          <div
            className={`${props.sizes.nature} div-td text-truncate`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {isNotEmpty(balance.currencyType?.id) ? balance.currencyType.description : ''}
          </div>
          <div
            className={`${props.sizes.result} div-td text-truncate`}
            data-tip={balance.idResult}
            onClick={() => handlerShowBalanceForm(balance)}>
            {balance.idResult}
          </div>
        </div>
      ))
    )
  }

  return (
    <div>
      {disableAddBalanceButton ? (
        <div className="py-3">
          <h5 className={'text-center'}>
            <b>{labels.REPORT_EXCLUDED_FROM_INTEGRATIVE_ANNUAL_BALANCES_COMMUNICATION}</b>
          </h5>
        </div>
      ) : (
        <div className="row p-2 pt-2">
          <div className="col-12 d-flex mb-1 align-items-center">
            <h6 className="m-0 pe-2">{labels.BALANCES_LIST_OF_THE_REPORT}</h6>
            <BtnDelete
              className="btn-cell"
              handlerOnClick={() => openPopupDelete()}
              disabled={Object.keys(checkedItems)?.length === 0}
            />
            <button
              className="btn btn-outline-primary btn-cell margin-left-5px "
              onClick={() => openGuideBalances()}>
              {labels.INSTRUCTIONS_FOR_COMPLETING_ANNUAL_BALANCES}
            </button>
            <div
              className="ms-auto d-flex justify-content-end balancePopOver"
              data-tip={displayDisabledTip()}>
              <button
                className={`${styles.btnFillPillSm} btn-cell`}
                onClick={() => addNewBalanceForm()}
                disabled={!relationship.id || disableAddBalanceButton || props.disabled}>
                <i className="thx-plus thx-icon me-2" />
                {labels.ADD}
              </button>
            </div>
          </div>
          <div className="col-12 table-balance">
            <div className="col-12 header-table header-table-sm p-0">
              <div
                className={`${props.sizes.checkbox} text-center div-td d-flex align-items-center justify-content-center`}>
                <input
                  disabled={!(balances?.length > 0)}
                  name="tableCheckBox"
                  type="checkbox"
                  checked={allCheck}
                  onClick={(e) => handleAllCheckChange(e)}
                />
              </div>
              <div className={`${props.sizes.year} div-td`}>{labels.YEAR}</div>
              <div className={`${props.sizes.amount1} div-td`}>{labels.AMOUNT_1}</div>
              <div className={`${props.sizes.amount2} div-td`}>{labels.AMOUNT_2}</div>
              <div className={`${props.sizes.amount3} div-td`}>{labels.AMOUNT_3}</div>
              <div className={`${props.sizes.amount4} div-td`}>{labels.AMOUNT_4}</div>
              <div className={`${props.sizes.amount5} div-td`}>{labels.OTHER_INFO_AMOUNT_5}</div>
              <div className={`${props.sizes.stock} div-td`}>{labels.AVERAGE_GAZ_MEDIUM}</div>
              <div className={`${props.sizes.currency} div-td`}>{labels.CURRENCY}</div>
              <div className={`${props.sizes.nature} div-td`}>{labels.NATURE}</div>
              <div className={`${props.sizes.result} div-td`}>{labels.RESULT}</div>
            </div>

            {renderBalanceRows()}
          </div>
          {(balance || newBalance) && (
            <ModalCreateBalance
              disabled={props.disabled}
              key={balance?.id || new Date().toString()}
              show={modalShow}
              onHide={() => closeModal()}
              handlerSubmitBalance={handlerSubmitBalance}
              relationship={relationship}
              balance={balance}
              currencies={currencies}
              balances={balances}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default BalanceForm
