import checkbox from '../../../styles/images/checkbox-inactive.png'
import checkboxSel from '../../../styles/images/checkbox-selected.png'

export const BwmCheckbox = ({ input, label, onChange, name, checked, error, disabled }) => (
  <div>
    <div className="input-group">
      {checked && (
        <input
          hidden={true}
          {...input}
          type="checkbox"
          className="form-check-input"
          name={name}
          id={name}
          onChange={onChange}
          checked={true}
          disabled={disabled}
        />
      )}
      {!checked && (
        <input
          hidden={true}
          {...input}
          type="checkbox"
          className="form-check-input"
          name={name}
          id={name}
          onChange={onChange}
          disabled={disabled}
        />
      )}
      <label
        className={`form-check-label cursor-pointer ${checked ? 'font-weight-bold' : ''}`}
        htmlFor={name}>
        <img width="20" className="me-1" src={checked ? checkboxSel : checkbox} alt={label} />
        {label}
      </label>
    </div>
    {error !== undefined && error !== null && !error.isValid ? (
      <div className="text-danger">{error.msg}</div>
    ) : (
      ''
    )}
  </div>
)
