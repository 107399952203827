import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DELAYED_COMMUNICATION_DATE } from '../../../../actions/types'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import { formatDateForDisplay } from '../../../shared/Utility'
import { CheckRemainingSubjects, IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import getText from '../../../shared/i18n/labels'
import { Route, useRouteMatch } from 'react-router-dom'

// DETAIL
import { Relationships } from '../relationships/NewCoraRelationships'

// MODAL
import { Modal } from 'react-bootstrap'
import { DelayedCommunicationModal } from '../communications/DelayedCommunicationModal'
import { ImportDataModal } from '../import/NewImportDataModal'
import { UploadReceiptModal } from '../receipts/NewUploadReceiptModal'
import { CoraSubjectsExportModal } from './NewCoraSubjectsExportModal'
// import { CoraSubjectModal } from './NewCoraSubjectModal'

// COMPONENTS
import { CoraSubjectList } from './NewCoraSubjectList'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import { PopupSubjectLicense } from '../../../shared/popups/PopupSubjectLicense'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'

import '../../../../styles/subject.css'

export function CoraSubject() {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const { path } = useRouteMatch()

  const [id, setId] = useState(null)
  const [relationshipId, setRelationshipId] = useState(null)
  const [subjects, setSubjects] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [checkedSubjects, setCheckedSubjects] = useState([])
  const [customerApplication, setCustomerApplication] = useState('')
  const [subjectSummary, setSubjectSummary] = useState(null)
  const [expired, setExpired] = useState(false)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const [showDelayedCommunicationModal, setShowDelayedCommunicationModal] = useState(false)
  const [showDelayedCommunicationHint, setShowDelayedCommunicationHint] = useState(false)
  const [loading, setLoading] = useState(false)
  const [licenseExpiration, setLicenseExpiration] = useState()
  const [showSubjectImportModal, setShowSubjectImportModal] = useState(false)
  const [resetForm, setResetForm] = useState(false)

  const auth = useSelector((state) => state.auth)
  const _subjects = useSelector((state) => state.subjects.data)

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_CORA)
    }
  }

  const setNewCheckedItems = (newCheckedItems) => {
    setCheckedItems(newCheckedItems)
    const newCheckedSubjects = []
    if (newCheckedItems?.length > 0) {
      newCheckedItems.forEach((id) => {
        let subject = checkedSubjects.find((s) => s.id?.toString() === id?.toString())
        if (!subject) subject = subjects.find((s) => s.id?.toString() === id?.toString())
        if (subject) newCheckedSubjects.push(subject)
      })
    }
    setCheckedSubjects(newCheckedSubjects)
  }

  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  useEffect(() => {
    getAllLocations()
    getSubjectSummary()
  }, [])

  useEffect(() => {
    setSubjects(_subjects)
  }, _subjects)

  useEffect(() => {
    if (expired) LicenseExpiredPopup({})
  }, [expired])

  useEffect(() => {
    setExpired(isExpired())
    checkLicense()
  }, [auth])

  const getSubjects = () => {
    actions.searchCoraSubjects().then((res) => {
      if (subjectSummary?.available < 0) {
        PopupSubjectLicense(
          {
            total: subjectSummary?.total,
            used: subjectSummary.used,
            name: Constants.APPLICATION_CORA
          },
          lang
        )
      }
      setSubjects(res?.content || res?.data)
    })
  }

  const checkLicense = () => {
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_CORA
    )
    const newCustomerApplication = auth?.user?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application.id
    )
    setCustomerApplication(newCustomerApplication)
    setLicenseExpiration(newCustomerApplication?.expiration)
  }

  const getSubjectSummary = (shouldDisplayError) => {
    CheckRemainingSubjects(
      Constants.APPLICATION_CORA,
      shouldDisplayError,
      (subjectSummary) => {
        setDisableNewSubjectButtons(subjectSummary?.available < 1)
        setSubjectSummary(subjectSummary)
      },
      labels,
      lang
    )
  }

  const getAllLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const addOrUpdateCoraSubject = (newSubject, previousFiscalCode) => {
    newSubject = trimFields(newSubject)
    const promise = newSubject.id
      ? actions.updateCoraSubject(newSubject)
      : actions.addCoraSubject(newSubject)
    promise.then(
      (response) => {
        if (previousFiscalCode !== newSubject.fiscalCode) {
          verifyCoraSubjectOnComurei(newSubject.fiscalCode)
        } else {
          PopupSuccess({ text: labels.CORRECTLY_SAVED })
        }

        setId(response.id)

        getSubjects()
        getSubjectSummary(true)
      },
      (errors) => {
        if (errors.status === 511) {
          PopupSubjectLicense(
            {
              total: subjectSummary.total,
              used: subjectSummary.used,
              available: subjectSummary?.available,
              name: Constants.APPLICATION_CORA
            },
            lang
          )
        } else {
          PopupError({
            text: errors?.data?.title || labels.GENERIC_ERROR,
            labels
          })
        }
      }
    )
  }

  const verifyCoraSubjectOnComurei = (fiscalCode, completeName) => {
    actions.verifyCoraSubjectOnComurei(fiscalCode).then(
      (res) => {
        if (res === false) {
          ComureiSubjectNotPresentAlert(
            { fiscalCode: fiscalCode, denomination: completeName },
            lang
          )
        } else {
          PopupSuccess({ text: labels.CORRECTLY_SAVED })
        }
      },
      (errors) => {
        console.log(errors)
        PopupSuccess({ text: labels.CORRECTLY_SAVED })
      }
    )
  }

  const updateCoraSubject = (subject, previousFiscalCode = '') => {
    subject = trimFields(subject)
    actions
      .updateCoraSubject(subject)
      .then(
        () => {
          if (previousFiscalCode !== subject.fiscalCode) {
            verifyCoraSubjectOnComurei(subject.fiscalCode)
          } else {
            PopupSuccess({ text: labels.CORRECTLY_SAVED })
          }
        },
        (errors) => {
          PopupError({
            text: errors?.title || labels.GENERIC_ERROR,
            labels
          })
        }
      )
      .then(() => {
        getSubjects()
      })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: labels.SELECTED_SUBJECTS,
      handleClickConfirm: deleteCoraSubjects,
      text: labels.WARNING_IF_IN_THE_FUTURE_IT_IS_NEEDED_TO_RESTORE_THIS_SUBJECT_AUTHORIZATION_MUST_BE_REQUESTED_TO_THE_ASSISTANCE_OFFICE
    })
  }

  const deleteCoraSubjects = () => {
    setLoading(true)
    let promises = []
    checkedItems.map((subject) => {
      promises.push(actions.deleteCoraSubject(subject))
    })
    Promise.all(promises)
      .then(
        () => {
          PopupSuccess({ text: labels.REMOVED_SUCCESSFULLY })
        },
        (errors) => {
          PopupError({ text: labels.GENERIC_ERROR })
        }
      )
      .then(() => {
        setLoading(false)
        getSubjects()
        getSubjectSummary(false)
        setCheckedItems([])
      })
  }

  const trimFields = (subject) => {
    if (subject.companyName) {
      //TODO: trimmare lato BE
      subject.companyName = subject.companyName.trim()
    } else if (subject.firstName && subject.lastName) {
      subject.firstName = subject.firstName.trim()
      subject.lastName = subject.lastName.trim()
    }
    return subject
  }

  const onChangeScheduledDate = (date) => {
    dispatch({ type: DELAYED_COMMUNICATION_DATE, delayedCommunicationDate: date })
    setShowDelayedCommunicationModal(false)
  }

  const actionsComponent = () => {
    return (
      <div className="col-12 ps-0">
        <div className="btn-group" role="group" aria-label="action buttons">
          <button
            type="button"
            disabled={checkedItems.length === 0}
            className={`btn btn-outline-primary  ${checkedItems.length === 0 ? 'disabled' : ''}`}
            onClick={() => openPopupDelete()}>
            <i className="thx-trash thx-icon me-2" />
            {labels.DELETE}
          </button>
          <CoraSubjectsExportModal
            disabled={checkedItems.length === 0}
            checkedItems={checkedSubjects}
          />
          <button
            type="button"
            data-tip={labels.IMPORT_SUBJECTS_TO_YOUR_LIST}
            className={`btn btn-outline-primary`}
            disabled={disableNewSubjectButtons || expired}
            onClick={() => setShowSubjectImportModal(true)}>
            <i className="thx-file-import thx-icon me-2" />
            {labels.IMPORT}
          </button>
          {showSubjectImportModal && (
            <ImportDataModal
              show={showSubjectImportModal}
              onSuccess={() => {
                checkLicense()
                getSubjects()
                setShowSubjectImportModal(false)
              }}
              onClose={() => setShowSubjectImportModal(false)}
            />
          )}
          <UploadReceiptModal disabled={expired} refreshSubjects={getSubjects} />
          {delayedCommunicationDate ? (
            <>
              <button
                type="button"
                className={`btn btn-danger `}
                onClick={() => onChangeScheduledDate(null)}>
                <i className="thx-trash thx-icon me-2" />
                {labels.SCHEDULED_DELAYED_SENDING_SET_TO}{' '}
                {formatDateForDisplay(delayedCommunicationDate)}
              </button>
              <button
                type="button"
                className={`btn btn-outline-primary`}
                onClick={() => setShowDelayedCommunicationHint(true)}>
                <i className="thx-question thx-icon" />
              </button>
            </>
          ) : (
            <button
              type="button"
              className={`btn btn-outline-primary`}
              onClick={() => setShowDelayedCommunicationModal(true)}>
              <i className="thx-calendar thx-icon me-2" />
              {labels.SCHEDULED_DELAYED_SENDING}
            </button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {loading && <PageSpinner />}
      <Route key={path} path={`${path}/relationship/:id`}>
        <Relationships setId={setRelationshipId} />
      </Route>
      <div className={relationshipId ? 'd-none' : ''}>
        <div className="table-custom">
          <div className="tabs single">
            <div className="d-flex align-items-center justify-content-between">
              <div className="ps-0 d-flex align-items-center">
                <SubjectTotalAndCallToAction
                  total={subjectSummary?.total}
                  remaining={subjectSummary?.available}
                  used={subjectSummary?.used}
                  licenseType={customerApplication?.licenseType}
                  customerApplication={customerApplication}
                  language={lang}
                />
              </div>

              <div className="d-flex text pe-0 align-items-center justify-content-end">
                <div className="col d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary mb-2 "
                    disabled={expired}
                    onClick={() => setResetForm(!resetForm)}>
                    <i className="thx-plus thx-icon me-2" />
                    {labels.NEW_SUBJECT}
                  </button>
                </div>
              </div>
            </div>

            <CoraSubjectList
              disabled={expired || loading}
              id={id}
              resetForm={resetForm}
              subjects={subjects}
              checkedItems={checkedItems}
              setCheckedItems={setNewCheckedItems}
              updateCoraSubject={updateCoraSubject}
              reloadSubjects={getSubjects}
              setSubjects={setSubjects}
              addOrUpdateCoraSubject={addOrUpdateCoraSubject}
              actionsComponent={actionsComponent()}
            />
          </div>
        </div>
        {/* {id && (
        <CoraSubjectModal
          id={id}
          showModal={!!id}
          hideButton={true}
          subjects={subjects}
          licenseExpired={!isLicenseValid}
          subjectSummary={subjectSummary}
          addOrUpdateCoraSubject={addOrUpdateCoraSubject}
          locations={locations}
          closeDialog={() => setId(null)}
        />
      )} */}
        {showDelayedCommunicationModal && (
          <DelayedCommunicationModal
            show={showDelayedCommunicationModal}
            date={delayedCommunicationDate}
            licenseExpiration={licenseExpiration}
            onConfirm={(date) => onChangeScheduledDate(date)}
            onHide={() => setShowDelayedCommunicationModal(false)}
          />
        )}
        {showDelayedCommunicationHint && (
          <Modal
            centered
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            show={showDelayedCommunicationHint}
            onHide={() => setShowDelayedCommunicationHint(false)}>
            <div className="bar"></div>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="container">
                <p>
                  {labels.SCHEDULED_DELAYED_SENDING_SET_TO}:
                  <strong>{formatDateForDisplay(delayedCommunicationDate)}</strong>
                </p>
                <p>{labels.ALL_AT_THE_END_OF_THE_SESSION_OR_PRESSING_F5_THE_SETTING_IS_DELETED}</p>
                <p>
                  {labels.THE_ACTIVE_SETTING_REMAINS_VALID_FOR_ANY_COMMUNICATIONS_CREATED_WITH_IT}
                </p>
                <p>
                  {
                    labels.TO_CANCEL_THESE_SENDINGS_THE_ROW_MUST_BE_DELETED_FROM_THE_COMMUNICATION_HISTORY
                  }
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className={'rowjustify-content-center'}>
                <button
                  type="button"
                  className={`btn btn-outline-primary`}
                  onClick={() => setShowDelayedCommunicationHint(false)}>
                  {labels.OK_CLOSE}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </>
  )
}
