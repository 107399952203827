import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { searchArcoSubjects } from '../../../../actions'
import getText from '../../../shared/i18n/labels'
import * as Constants from '../../../../config/Constants'

// TABLES
import { StarTable } from '../../../shared/tables/StarTable'

// FORM
import { ArcoSubjectForm } from './NewArcoSubjectForm'

const getOffset = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return 445
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return 400
  return 400
}

export function ArcoSubjectList(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)
  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState()
  const history = useHistory()

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    fiscalCode: null,
    companyName: null,
    subject: null,
    year: null
  })

  const tableUtils = TableUtils
  const tableName = 'subject-list-all'

  const header = [
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: labels.FISCAL_CODE,
      maxWidth: '1.5fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'companyName',
      displayedName: labels.SUBJECT,
      maxWidth: '2.5fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'arcoSubjectType',
      displayedName: labels.TYPOLOGY,
      type: fieldTypes.SELECTION,
      options: props.subjectTypes,
      maxWidth: '2fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'location',
      displayedName: labels.LOCATION,
      maxWidth: '2fr'
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          companyName: tableUtils.composeCell({
            fieldName: 'companyName',
            fieldValue: c.companyName
          }),
          arcoSubjectType: tableUtils.composeCell({
            fieldName: 'arcoSubjectType',
            fieldValue: c.arcoSubjectType
          }),
          location: tableUtils.composeCell({
            fieldName: 'location',
            fieldValue: c.location
          })
        }
      })
    })
  }

  const onDoubleClickRow = (row) => {
    const path = `/app/arco/dashboard/registrations/${row.id}`
    history.push(path)
  }

  useEffect(() => {
    setRows(prepareRows(props.subjects))
    setId(id ? id : props.subjects?.[0]?.id)
  }, [props.subjects])

  useEffect(() => {
    setId()
  }, [props.resetForm])

  const renderTable = () => {
    return (
      <StarTable
        id={id}
        version="1"
        checkedItems={props.checkedItems}
        headerColumns={header}
        rows={rows}
        loading={show}
        getOffset={getOffset}
        resetFilterOnBottom={false}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => searchArcoSubjects(searchObject)}
        onExecutedSearch={(content) => {
          setId()
          return props.setSubjects(content)
        }}
        usePagination={true}
        setId={setId}
        withCheckBoxes={true}
        onDoubleClickRow={(row) => onDoubleClickRow(row)}
        onClickCheck={props.checkSubject}
        actionsComponent={props.actionsComponent}
        formComponent={ArcoSubjectForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          subjectId: id,
          formOnBottom: true,
          locations: props.locations,
          subjectTypes: props.subjectTypes,
          setLoading: setLoading,
          showPopupManageTable: props.showPopupManageTable,
          getSubjects: () => props.getSubjects(searchObject),
          close: (newId) => setId(newId || id ? id : undefined)
        }}
      />
    )
  }

  return <>{renderTable()}</>
}
