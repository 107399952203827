import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../actions'
import * as Utility from '../shared/Utility'
import getText from '../shared/i18n/labels'

// MODAL
import { Modal } from 'react-bootstrap'

// FORM
import { BwmInput } from '../shared/form/BwmInputB5'
import ErrorListAlert from '../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { PopupError } from '../shared/popups/PopupError'
import { InfoBox } from '../shared/InfoBox'

const defaultSize = (size = null) => ({
  leftSide: Utility.getSize(size, { default: '70' }),
  email: Utility.getSize(size, { default: '50' }),
  password: Utility.getSize(size, { default: '50' }),
  firstName: Utility.getSize(size, { default: '50' }),
  lastName: Utility.getSize(size, { default: '50' }),
  rightSide: Utility.getSize(size, { default: '30' })
})

const getSize = () => {
  return defaultSize()
}

export function AdminNewCustomerModal({ onClose = () => {}, onConfirm = (form) => {} }) {
  const [initialValues, setInitialValues] = useState({ companyName: 'DEMO' })

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [touched, setTouched] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(labels.REQUIRED_FIELD),
    password: Yup.string()
      .required(labels.REQUIRED_FIELD)
      .min(4, labels.PASSWORD_MORE_4_CHARACTERS)
      .max(100, labels.PASSWORD_LESS_100_CHARACTERS),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable()
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'email',
      errorLabel: labels.EMAIL
    },
    {
      errorKey: 'password',
      errorLabel: labels.PASSWORD
    },
    {
      errorKey: 'first_name',
      errorLabel: labels.FIRST_NAME
    },
    {
      errorKey: 'last_name',
      errorLabel: labels.LAST_NAME
    }
  ]

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <Modal
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      onHide={onClose}
      bsPrefix="modal-create modal"
      backdrop="static">
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{labels.ADD_NEW_USER}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}>
          {({ values, errors }) => (
            <Form>
              {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
              {showErrorAlert && errors && touched && (
                <div>
                  <ErrorListAlert
                    errors={getErrors(errors, values.personType)}
                    hide={() => setShowErrorAlert(false)}
                  />
                </div>
              )}
              <FormBody
                initialValues={initialValues}
                touched={touched}
                setTouched={setTouched}
                setShowErrorAlert={setShowErrorAlert}
                onConfirm={onConfirm}
                onClose={onClose}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [type, setType] = useState('password')

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const handleFormSubmit = async () => {
    const form = Object.assign({}, values)
    if (form.password) form.password = form.password.trim()
    const errors = await validateForm(form) // Validazione dei valori
    setValues(form)
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true)
    } else {
      actions.adminCreateUser(form).then(
        (res) => props.onConfirm(res),
        (err) => PopupError({ text: labels.GENERIC_ERROR })
      )
      props.setShowErrorAlert(false)
    }
  }

  const onClickIconPsw = (e, id) => {
    e.preventDefault()
    let x = document.getElementById(id)
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text'
        setType('text')
      } else {
        x.type = 'password'
        setType('password')
      }
    }
  }

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    setTimeout(() => {
      setValues(props.initialValues)
    }, 200)
  }, [])

  return (
    <div className="mt-3 d-flex flex-column justify-content-between">
      <div className="row">
        <div className={`${sizesClass.leftSide} pe-4`}>
          <div className="row">
            <div className={`${sizesClass.email} pe-2 mb-3`}>
              <BwmInput
                label={`${labels.EMAIL}*`}
                className="form-control"
                value={values?.email}
                error={errors?.email}
                touched={props.touched}
                onChange={(e) =>
                  Utility.handleChangeValue(e.target.value, 'email', values, setValues, false, true)
                }
              />
            </div>
            <div className={`${sizesClass.email}`}>
              <BwmInput
                id="loginPsw"
                label={`${labels.PASSWORD}*`}
                className="form-control"
                name="password"
                type="password"
                value={values?.password}
                error={errors?.password}
                touched={props.touched}
                maxLength={100}
                Icon={() => (
                  <div
                    className="d-flex align-self-center"
                    onClick={(e) => onClickIconPsw(e, 'loginPsw')}>
                    <i
                      className={`thx-icon bg-primary ${type === 'text' ? 'thx-eye' : 'thx-eye-off'}`}
                    />
                  </div>
                )}
                onChange={(e) =>
                  Utility.handleChangeValue(e.target.value, 'password', values, setValues)
                }
              />
            </div>
            <div className={`${sizesClass.firstName} pe-2 mb-3`}>
              <BwmInput
                label={labels.FIRST_NAME}
                className="form-control"
                value={values?.firstName}
                error={errors?.firstName}
                touched={props.touched}
                onChange={(e) =>
                  Utility.handleChangeValue(e.target.value, 'firstName', values, setValues, true)
                }
              />
            </div>
            <div className={`${sizesClass.lastName}`}>
              <BwmInput
                label={labels.LAST_NAME}
                className="form-control"
                value={values?.lastName}
                error={errors?.lastName}
                touched={props.touched}
                onChange={(e) =>
                  Utility.handleChangeValue(e.target.value, 'lastName', values, setValues, true)
                }
              />
            </div>
          </div>
        </div>
        <div className={sizesClass.rightSide}>
          <InfoBox
            simplePointsArr={[
              {
                title: labels.HOW_IT_WORKS,
                text: labels.HOW_IT_WORKS_TEXT
              }
            ]}
          />
        </div>
      </div>
      <div className="d-flex mt-4 justify-content-between">
        <button className="btn btn-outline-primary" onClick={props.onClose}>
          <i className="thx-cancel thx-icon me-2" />
          {labels.CANCEL}
        </button>
        <div className="d-flex align-items-center">
          <b className="me-2">*</b>
          <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
        </div>
        <button
          disabled={props.disabled}
          onClick={() => handleFormSubmit()}
          className="btn btn-primary">
          <i className="thx-floppy-disk thx-icon me-2" />
          {labels.CREATE_AND_CONTINUE}
        </button>
      </div>
    </div>
  )
}
