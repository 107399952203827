import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import CodiceFiscale from 'codice-fiscale-js'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../../components/shared/i18n/labels'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// DATEPICKER
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'
import 'react-datepicker/dist/react-datepicker.css'

// MODAL
import Modal from 'react-bootstrap/Modal'

// COMPONENTS
import { Tabs } from '../../../shared/Tabs'
import { ArcoRegistrySignatories } from './NewArcoRegistrySignatories'
import { ArcoRegistryHolders } from './NewArcoRegistryHolders'
import { PopupError } from '../../../shared/popups/PopupError'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

import '../../../../styles/autocomplete.css'

const defaultSize = (size = null) => ({
  'tab-sub': {
    personType: Utility.getSize(size, { default: '100' }),
    fiscalCode: Utility.getSize(size, { default: '14', lg: '15', md: '20' }),
    denomination: Utility.getSize(size, { default: '40', md: '80' }),
    lastName: Utility.getSize(size, { default: '21', md: '35' }),
    firstName: Utility.getSize(size, { default: '20', md: '35' }),
    gender: Utility.getSize(size, { default: '7', md: '10' }),
    birthDate: Utility.getSize(size, { default: '11', md: '14' }),
    birthLocation: Utility.getSize(size, { default: '21', md: '29' }),
    location: Utility.getSize(size, { default: '23', md: '29' }),
    postalCode: Utility.getSize(size, { default: '7', md: '10' }),
    province: Utility.getSize(size, { default: '6', lg: '7', md: '9' }),
    address: Utility.getSize(size, { default: '29', md: '40' }),
    nation: Utility.getSize(size, { default: '35', lg: '27', md: '40' })
  },
  'tab-sub-doc': {
    arcoDocumentType: Utility.getSize(size, { default: '22', lg: '26', md: '27' }),
    number: Utility.getSize(size, { default: '20', lg: '18' }),
    beginDate: Utility.getSize(size, { default: '14' }),
    endDate: Utility.getSize(size, { default: '14' }),
    authority: Utility.getSize(size, { default: '30', lg: '28', md: '27' }),
    politicallyExposed: Utility.getSize(size, { default: '20', lg: '24', md: '27' }),
    sourceOfFunds: Utility.getSize(size, { default: '30', lg: '36', md: '50' }),
    sourceOfFundsDescription: Utility.getSize(size, { default: '50', lg: '40', md: '50' })
  },
  'tab-sub-sign': {
    checkbox: Utility.getSize(size, { default: '5' }),
    firstName: Utility.getSize(size, { default: '20', md: '14' }),
    lastName: Utility.getSize(size, { default: '20', md: '14' }),
    fiscalCode: Utility.getSize(size, { default: '12', lg: '15', md: '18' }),
    birthDate: Utility.getSize(size, { default: '10', lg: '13', md: '15' }),
    location: Utility.getSize(size, { default: '29' }),
    province: Utility.getSize(size, { default: '5', md: '7' })
  },
  'tab-sub-hold': {
    checkbox: Utility.getSize(size, { default: '5' }),
    firstName: Utility.getSize(size, { default: '20', md: '14' }),
    lastName: Utility.getSize(size, { default: '20', md: '14' }),
    fiscalCode: Utility.getSize(size, { default: '12', lg: '15', md: '18' }),
    birthDate: Utility.getSize(size, { default: '10', lg: '13', md: '15' }),
    location: Utility.getSize(size, { default: '29' }),
    province: Utility.getSize(size, { default: '5', md: '7' })
  },
  'tab-sub-risk': {
    a1: Utility.getSize(size, { default: '22', lg: '26', md: '30' }),
    a2: Utility.getSize(size, { default: '22', lg: '26', md: '30' }),
    a3: Utility.getSize(size, { default: '18', lg: '23' }),
    a4: Utility.getSize(size, { default: '18', lg: '24' }),
    a5: Utility.getSize(size, { default: '18', lg: '24' })
  }
})

const getSize = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export function ArcoRegistryModal(props) {
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [header, setHeader] = useState('')
  const [activeTab, setActiveTab] = useState('tab-sub')

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const domains = useSelector((state) => state.arcoDomains.domains)
  const customer = useSelector((state) => state.auth.customer)
  const arcoDocumentTypes = useSelector((state) => state.arcoRelationshipDocuments.data)

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    setLoading(true)
    if (props.selectedItem) {
      actions.getArcoRegistryDetail(props.selectedItem.id).then((res) => {
        setNewInitialValues(res)
        setLoading(false)
      })
    } else {
      setNewInitialValues({})
      setLoading(false)
    }
  }, [props.selectedItem])

  const getA = (type) => {
    let value
    const filteredList = []
    domains.forEach((el) => {
      if (el.type === type && el.def) value = el
      el.description = el.description.toUpperCase()
      filteredList.push(el)
    })
    if ([undefined, null, ''].includes(value)) value = filteredList[0]
    return value
  }

  const setNewInitialValues = (data = {}) => {
    setTabWithError({})
    setTouched(false)
    const initialDocument = {
      id: null,
      number: '',
      arcoDocumentType: arcoDocumentTypes[0],
      beginDate: null,
      endDate: null,
      authority: labels.DEFAULT_AUTHORITY,
      politicallyExposed: false,
      setSourceOfFunds: '',
      sourceOfFundsDescription: ''
    }
    setInitialValues({
      id: data.id,
      errorFiscalCode: false,
      personType: data.personType || Constants.PF,
      fiscalCode: data.fiscalCode || '',
      denomination: data.denomination || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: data.birthDate || null,
      locationLegal: data.locationLegal || '',
      location: data.location || '',
      birthLocation: data.birthLocation || '',
      provinceLegal: data.provinceLegal || '',
      province: data.province || '',
      birthProvince: data.birthProvince || '',
      postalCode: data.postalCode || '',
      address: data.address || '',
      nation: data.nation || 'ITALIA (REPUBBLICA ITALIANA)',
      gender: data.gender || Constants.genderList[0].id,
      a1: data.a1 && data.a1Value ? { id: data.a1, value: data.a1Value } : getA('A1'),
      a2: data.a2 && data.a2Value ? { id: data.a2, value: data.a2Value } : getA('A2'),
      a3: data.a3 && data.a3Value ? { id: data.a3, value: data.a3Value } : getA('A3'),
      a4: data.a4 && data.a4Value ? { id: data.a4, value: data.a4Value } : getA('A4'),
      a5: data.a5 && data.a5Value ? { id: data.a5, value: data.a5Value } : getA('A4'),
      arcoDocument: data.arcoDocument || initialDocument,
      arcoRegistryHolders: data.arcoRegistryHolders || [],
      arcoRegistrySignatories: data.arcoRegistrySignatories || [],
      arcoOperation: data.arcoOperation || null,
      arcoSubject: data.arcoSubject || props.arcoSubject,
      customer: data.customer || customer
    })
  }

  const formErrorsMapping = {
    'tab-sub': [
      {
        errorKey: 'personType',
        errorLabel: labels.PERSON_TYPE
      },
      {
        errorKey: 'fiscalCode',
        errorLabel: labels.FISCAL_CODE
      },
      {
        errorKey: 'errorFiscalCode',
        errorLabel: labels.FISCAL_CODE
      },
      {
        errorKey: 'denomination',
        errorLabel: labels.DENOMINATION
      },
      {
        errorKey: 'lastName',
        errorLabel: labels.LAST_NAME
      },
      {
        errorKey: 'firstName',
        errorLabel: labels.FIRST_NAME
      },
      {
        errorKey: 'gender',
        errorLabel: labels.GENDER
      },
      {
        errorKey: 'birthDate',
        errorLabel: labels.BIRTH_DATE
      },
      {
        errorKey: 'birthLocation',
        errorLabel: labels.BIRTH_LOCATION
      },
      {
        errorKey: 'birthProvince',
        errorLabel: labels.BIRTH_PROVINCE
      },
      {
        errorKey: 'address',
        errorLabel: labels.ADDRESS
      },
      {
        errorKey: 'postalCode',
        errorLabel: labels.POSTAL_CODE
      },
      {
        errorKey: 'province',
        errorLabel: labels.PROVINCE
      },
      {
        errorKey: 'nation',
        errorLabel: labels.NATION
      }
    ],
    'tab-sub-doc': [
      {
        errorKey: 'arcoDocument.arcoDocumentType.id',
        errorLabel: labels.DOCUMENT_TYPE
      },
      {
        errorKey: 'arcoDocument.number',
        errorLabel: labels.DOCUMENT_NUMBER
      },
      {
        errorKey: 'arcoDocument.beginDate',
        errorLabel: labels.DOCUMENT_ISSUE_DATE
      },
      {
        errorKey: 'arcoDocument.endDate',
        errorLabel: labels.DOCUMENT_EXPIRATION_DATE
      },
      {
        errorKey: 'arcoDocument.authority',
        errorLabel: labels.DOCUMENT_AUTHORITY
      },
      {
        errorKey: 'arcoDocument.politicallyExposed',
        errorLabel: labels.POLITICALLY_EXPOSED
      },
      {
        errorKey: 'arcoDocument.sourceOfFunds',
        errorLabel: labels.SOURCE_OF_FUNDS
      },
      {
        errorKey: 'arcoDocument.sourceOfFundsDescription',
        errorLabel: labels.SOURCE_OF_FUNDS_DETAIL
      }
    ]
  }

  const checkIfFiscalCodeIsRequired = (birthProvince) => {
    return birthProvince !== 'EE'
  }

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required(labels.PERSON_TYPE_ERROR),
    fiscalCode: Yup.string()
      .test('fiscal-code-required', labels.REQUIRED_FIELD, function (value) {
        const { nation, birthProvince } = this.parent
        return !(nation === 'ITALIA (REPUBBLICA ITALIANA)' && !value && birthProvince !== 'EE')
      })
      .nullable(),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => labels.NOTVALID,
      (value) => !value
    ),
    location: Yup.string().required(labels.REQUIRED_FIELD),
    province: Yup.string().required(labels.REQUIRED_FIELD),
    nation: Yup.string().required(labels.REQUIRED_FIELD),
    denomination: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date(labels.INVALID_DATE).when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required(labels.REQUIRED_FIELD)
          .test(
            'is-valid-date',
            labels.INVALID_DATE,
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    birthLocation: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    birthProvince: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    postalCode: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    address: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    arcoDocument: Yup.object({
      arcoDocumentType: Yup.object().shape({
        id: Yup.string()
          .nullable()
          .test('id-required-for-pf', labels.REQUIRED_FIELD, function (value) {
            const { personType } = this.options.context || {}
            return personType === Constants.PF ? !!value : true
          })
      }),
      number: Yup.string()
        .nullable()
        .test('number-required-for-pf', labels.REQUIRED_FIELD, function (value) {
          const { personType } = this.options.context || {}
          return personType === Constants.PF ? !!value : true
        }),
      authority: Yup.string()
        .nullable()
        .test('authority-required-for-pf', labels.REQUIRED_FIELD, function (value) {
          const { personType } = this.options.context || {}
          return personType === Constants.PF ? !!value : true
        }),
      beginDate: Yup.date(labels.INVALID_DATE)
        .nullable()
        .test('beginDate-required-for-pf', labels.REQUIRED_FIELD, function (value) {
          const { personType } = this.options.context || {}
          return personType === Constants.PF ? !!value : true
        })
        .test('is-valid-beginDate', labels.INVALID_DATE, function (value) {
          const { personType } = this.options.context || {}
          return personType === Constants.PF ? !value || Utility.isValidDate(new Date(value)) : true
        }),
      endDate: Yup.date(labels.INVALID_DATE)
        .nullable()
        .test('endDate-required-for-pf', labels.REQUIRED_FIELD, function (value) {
          const { personType } = this.options.context || {}
          return personType === Constants.PF ? !!value : true
        })
        .test('endDate-valid-for-pf', labels.INVALID_DATE, function (value) {
          const { personType } = this.options.context || {}
          if (personType === Constants.PF && value) {
            const beginDate = this.parent.beginDate
            return new Date(value) >= new Date(beginDate)
          }
          return true
        })
        .test('is-valid-endDate', labels.INVALID_DATE, function (value) {
          const { personType } = this.options.context || {}
          return personType === Constants.PF ? !value || Utility.isValidDate(new Date(value)) : true
        })
    })
  })

  const setTabWithError = (tabDict) => {
    const tabList = Object.entries(tabDict)
      .filter(([key, value]) => value === true) // Filtra solo dove il valore è true
      .map(([key]) => key) // Mappa solo le chiavi

    document.querySelectorAll('.tab-list-item').forEach((item) => {
      item.firstChild.classList.remove('text-danger')
    })
    tabList.forEach((tabId) => {
      // Add 'border-danger' to the element with id 'test'
      document.getElementById(tabId).firstChild.classList.add('text-danger')
    })
  }

  const getErrors = (errors, personType) => {
    if (activeTab === 'tab-sub') {
      formErrorsMapping[activeTab][9].errorLabel =
        personType === Constants.PF ? labels.LOCATION : labels.LOCATION_LEGAL
    }
    return Utility.extractErrors(errors, formErrorsMapping[activeTab] || [])
  }

  const close = () => {
    setTouched(false)
    setShowErrorAlert(false)
    setTabWithError({})
    props.onHide()
  }

  return (
    <>
      <Modal
        className={'arco-modal-registries'}
        backdrop="static"
        show={props.showModalCheck}
        onHide={close}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{header}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-visible">
          <>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}>
              {({ values, errors }) => (
                <Form>
                  {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
                  {showErrorAlert &&
                    touched &&
                    errors &&
                    typeof errors === 'object' &&
                    Object.keys(errors).length > 0 && (
                      <div>
                        <ErrorListAlert
                          errors={getErrors(errors, values.personType)}
                          hide={() => setShowErrorAlert(false)}
                        />
                      </div>
                    )}
                  <FormBody
                    {...props}
                    activeTab={activeTab}
                    loading={loading}
                    touched={touched}
                    checkIfFiscalCodeIsRequired={checkIfFiscalCodeIsRequired}
                    initialValues={initialValues}
                    errors={errors}
                    windowWidth={window.innerWidth}
                    setActiveTab={setActiveTab}
                    setLoading={setLoading}
                    setTouched={setTouched}
                    setTabWithError={setTabWithError}
                    setShowErrorAlert={setShowErrorAlert}
                    setHeader={setHeader}
                    close={close}
                  />
                </Form>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const DEFAULT_AUTHORITY = labels.DEFAULT_AUTHORITY
  const FIRST_TAB = labels.PERSONAL_DATA
  const SECOND_TAB = labels.DOCUMENTS
  const THIRD_TAB_PF = labels.PROCURATORS
  const THIRD_TAB_PNF = labels.SIGNATORIES
  const FOURTH_TAB = labels.ACTUAL_HOLDERS
  const FIFTH_TAB = labels.REGISTRY_RISK

  const locations = useSelector((state) => state.locations.customLocations)
  const locationsBirth = useSelector((state) => state.locations.customLocations)
  const domains = useSelector((state) => state.arcoDomains.domains)
  const arcoDocumentTypes = useSelector((state) => state.arcoRelationshipDocuments.data)
  const countries = useSelector((state) => state.countries.data)

  const [showRegistryModal, setshowRegistryModal] = useState(false)
  const [detailItem, setDetailItem] = useState(null)
  const [depth, setDepth] = useState(props.depth)
  const [showDetailType, setShowDetailType] = useState(null)

  /**
   * effetto per salvare firmatari e procuratori sull'anagrafica padre
   */
  useEffect(() => {
    checkAndSaveArcoRegistry()
  }, [])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    props.setHeader(() => {
      if ((values.firstName && values.lastName) || values.denomination) {
        return (
          <>
            <span>{labels.REGISTRY_OF}</span> <strong>{getSurnameOrDenomination()}</strong>
          </>
        )
      } else {
        return props.arcoSubject.companyName + ' - ' + labels.NEW_REGISTRY
      }
    })
  }, [values.firstName, values.lastName, values.denomination])

  const checkAndSaveArcoRegistry = async () => {
    if (values.id && (await handleTabsValidation()) && !props.disabled) {
      actions.saveArcoRegistry(getFormData()).then(
        (res) => {
          setValues(res)
        },
        (err) => handleRegistrySaveErrors(err)
      )
    }
  }

  const getA = (formdata, type) => {
    formdata[`${type}Value`] = formdata[type]?.value || ''
    formdata[type] = formdata[type]?.id || ''
    return formdata
  }

  const getFormData = () => {
    const arcoDocument = values.personType === Constants.PF ? values.arcoDocument : null
    let formData = { ...values, arcoDocument }
    formData = getA(formData, 'a1')
    formData = getA(formData, 'a2')
    formData = getA(formData, 'a3')
    formData = getA(formData, 'a4')
    formData = getA(formData, 'a5')
    return formData
  }

  const getAList = (type) => {
    return domains
      ? domains
          .filter((arcoDomain) => arcoDomain.type === type)
          .map((el) => {
            el.description = el.description.toUpperCase()
            return el
          })
      : []
  }

  const getCountryList = () => {
    let countriesWithDescription = []
    countries.map((country) => {
      countriesWithDescription.push({
        name: country.code,
        description: country.description
      })
    })
    return countriesWithDescription
  }

  const convertToNewDocumentList = () => {
    let documentsWithDescription = []
    arcoDocumentTypes.forEach((document) => {
      documentsWithDescription.push({
        id: document.id,
        description: document.documentType?.toUpperCase()
      })
    })
    return documentsWithDescription
  }

  const getSurnameOrDenomination = () => {
    return Constants.PF === values.personType
      ? values.lastName + ' ' + values.firstName
      : values.denomination
  }

  const getInitialDocument = () => {
    return {
      id: null,
      number: '',
      arcoDocumentType: arcoDocumentTypes[0],
      beginDate: null,
      endDate: null,
      authority: DEFAULT_AUTHORITY,
      politicallyExposed: false,
      setSourceOfFunds: '',
      sourceOfFundsDescription: ''
    }
  }

  const newFiscalCode = (val) => {
    const form = Object.assign({}, values)
    form.errorFiscalCode = false
    if (values.personType === Constants.PF) {
      try {
        const cf = new CodiceFiscale(val).toJSON()
        form.errorFiscalCode = true
        if (cf) {
          const formatLoc = cf?.birthplace?.toUpperCase() || ''
          form.birthDate = values.birthDate || new Date(cf?.birthday) || null
          form.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
          form.birthLocation = values.birthLocation || formatLoc.toUpperCase() || ''
          form.birthProvince = values.birthProvince || cf?.birthplaceProvincia || ''
          form.errorFiscalCode = false
          setValues(form)
          return false
        }
        return true
      } catch (e) {
        form.errorFiscalCode = true
        setValues(form)
        return true
      }
    }
  }

  const handleKeyPress = async (val) => {
    try {
      const registries = await actions.getArcoRegistryByFiscalCode(props.arcoSubjectId, val)
      if (registries && registries.length > 0) {
        let reg = registries[0]
        setValues({ ...values, ...reg, errorFiscalCode: false })
        return false
      } else {
        return newFiscalCode(val)
      }
    } catch {
      return newFiscalCode(val)
    }
  }

  const handleInputAutocompileBirth = (newLocation) => {
    const form = Object.assign({}, values)
    form.birthLocation = newLocation?.toUpperCase() || ''
    actions.getLocation(form.birthLocation).then(
      (loc) => {
        if (loc) {
          form.birthLocation = loc.location
          form.birthProvince = loc.province || ''
        }
        setValues(form)
      },
      (errors) => {
        setValues(form)
      }
    )
  }

  const handleInputAutocompileResidence = (newLocation) => {
    const form = Object.assign({}, values)
    form.location = newLocation?.toUpperCase() || ''
    actions.getLocation(newLocation.toUpperCase()).then(
      (loc) => {
        if (loc) {
          form.location = loc.location || ''
          form.province = loc.province || ''
          form.postalCode = loc.postalCode || ''
          setValues(form)
        }
      },
      (errors) => setValues(form)
    )
  }

  // ARCO DOCUMENT
  const calculateEndDate = (form, date) => {
    let calculatedEndDate = null
    let plusYears = 10
    date = new Date(date)
    let birthDateFormData = new Date(form.birthDate)
    if (form.birthDate && date) {
      calculatedEndDate = new Date(form.birthDate)
      if (
        date.getMonth() > birthDateFormData.getMonth() ||
        (date.getMonth() == birthDateFormData.getMonth() &&
          date.getDate() > birthDateFormData.getDate())
      ) {
        plusYears = 11
      }
      calculatedEndDate.setFullYear(date.getFullYear() + plusYears)
    } else {
      calculatedEndDate = form.arcoDocument.endDate
    }

    return calculatedEndDate
  }

  const handlerArcoDocumentType = (e) => {
    const form = Object.assign({}, values)
    const list = convertToNewDocumentList()
    const documentTypeObj = list.find((el) => el?.id?.toString() === e.target.value?.toString())
    form.arcoDocument.arcoDocumentType = documentTypeObj
    if (documentTypeObj?.id === 1) {
      form.arcoDocument.authority = form.arcoDocument.authority || labels.DEFAULT_AUTHORITY
    }
    setValues(form)
  }

  const handlerChangeArcoDocumentReleaseDate = (date) => {
    const form = Object.assign({}, values)
    if (!form.arcoDocument) form.arcoDocument = {}
    form.arcoDocument.beginDate = date
    form.arcoDocument.endDate = calculateEndDate(form, date)
    setValues(form)
  }

  const handlerChangeArcoDocumentExpirationDate = (date) => {
    const form = Object.assign({}, values)
    if (!form.arcoDocument) form.arcoDocument = {}
    if (!date) date = calculateEndDate(form, form?.arcoDocument?.beginDate)
    form.arcoDocument.endDate = date
    setValues(form)
  }

  const handlerChangeArcoDocumentValue = (val, name, upperCase = false) => {
    const form = Object.assign({}, values)
    if (!form.arcoDocument) form.arcoDocument = {}
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
    }
    form.arcoDocument[name] = val
    setValues(form)
  }

  const handlerChangeA = (e, type) => {
    const form = Object.assign({}, values)
    const list = getAList(type?.toUpperCase())
    let value = list.find((el) => el.id?.toString() === e.target.value?.toString())
    form[type] = value
    setValues(form)
  }

  const handlePersonType = (val) => {
    const form = Object.assign({}, values)
    form.personType = val
    if (val === Constants.PF && !form.arcoDocument) form.arcoDocument = getInitialDocument()
    setValues(form)
  }

  // SUBMIT
  const addSignatoryOrHolder = (type, signatoryOrHolder) => {
    const form = Object.assign({}, values)
    let newarray = []
    if (type === Constants.HOLDER) {
      newarray = cloneDeep(form.arcoRegistryHolders || [])
      newarray.push(signatoryOrHolder)
      form.arcoRegistryHolders = newarray
    } else {
      newarray = cloneDeep(form.arcoRegistrySignatories || [])
      newarray.push(signatoryOrHolder)
      form.arcoRegistrySignatories = newarray
    }
    setValues(form)
  }

  const removeSignatoryOrHolder = (type, ids) => {
    const form = Object.assign({}, values)
    if (type === Constants.HOLDER) {
      form.arcoRegistryHolders = form.arcoRegistryHolders.filter(
        (item) => !ids.some((id) => item.id?.toString() === id?.toString())
      )
    } else {
      form.arcoRegistrySignatories = form.arcoRegistrySignatories.filter(
        (item) => !ids.some((id) => item.id?.toString() === id?.toString())
      )
    }
    setValues(form)
  }

  const openChildRegistryModal = (registry, type) => {
    setShowDetailType(type)
    setDetailItem(registry)
    setDepth(depth + 1)
    setshowRegistryModal(true)
  }

  const closeChildRegistryModal = (registry) => {
    const form = Object.assign({}, values)
    if (registry) {
      if (showDetailType === Constants.SIGNATORY) {
        let newarray = cloneDeep(form.arcoRegistrySignatories || [])
        let index = newarray.findIndex((e) => e.id === registry.id)
        if (index !== -1) newarray[index] = registry
        form.arcoRegistrySignatories = newarray
      } else if (showDetailType === Constants.HOLDER) {
        let newarray = cloneDeep(form.arcoRegistryHolders || [])
        let index = newarray.findIndex((e) => e.id === registry.id)
        if (index !== -1) newarray[index] = registry
        form.arcoRegistryHolders = newarray
      }
      setValues(form)
    }
    setShowDetailType(null)
    setDetailItem(null)
    setDepth(depth - 1)
    setshowRegistryModal(false)
  }

  const handleRegistrySaveErrors = (error) => {
    if (error?.message === 'error.validation') {
      let errorList = ''
      error.fieldErrors.forEach((e) => {
        if (errorList) errorList = errorList + '</br>'
        errorList = errorList + e.defaultMessage
      })
      PopupError({
        text: labels.ERROR_SAVING_REGISTRY,
        second_title: errorList,
        labels
      })
    } else {
      PopupError({ text: labels.ERROR_SAVING_REGISTRY })
    }
  }

  const callDownloadService = () => {
    props.setLoading(true)
    actions
      .downloadAntiTerrorism(getFormData())
      .then(
        (response) => {
          if (response) {
            const url = window.URL.createObjectURL(
              new Blob([actions.getBlobFromBase64([response])], { type: 'application/zip' })
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'ANTITERRORISMO_' + getSurnameOrDenomination() + '.zip')
            document.body.appendChild(link)
            link.click()
          } else {
            PopupError({ text: labels.FILE_NOT_FOUND })
          }
        },
        (error) => PopupError({ text: labels.ERROR_CREATING_REPORT })
      )
      .then(() => props.setLoading(false))
  }

  const controlloAntiterrorismo = async () => {
    if (!values.id || !(await handleTabsValidation())) return
    callDownloadService()
  }

  const saveAll = async () => {
    if (!(await handleTabsValidation())) return
    const formData = getFormData()
    props.setLoading(true)
    actions.saveArcoRegistry(formData).then(
      (response) => {
        props.setLoading(false)
        props.onHide(response)
      },
      (error) => {
        handleRegistrySaveErrors(error)
      }
    )
  }

  const handleTabsValidation = async () => {
    const errorFiscalCode = await handleKeyPress(values.fiscalCode)
    const form = Object.assign({}, values)
    form.errorFiscalCode = errorFiscalCode
    const errors = await validateForm(form) // Validazione dei valori
    setValues(form)
    props.setTouched(true)
    const _tabErrors = {}
    let validation = true

    if (Object.keys(errors)?.length > 0) {
      const checkFirstTab =
        (Object.keys(errors)?.length === 1 && !errors.arcoDocument) ||
        Object.keys(errors)?.length > 1

      if (checkFirstTab) {
        _tabErrors['tab-sub'] = true
        validation = false
      }

      if (errors.arcoDocument) {
        _tabErrors['tab-sub-doc'] = true
        validation = false
      }
    }

    if (validation) {
      props.setShowErrorAlert(false)
      props.setTabWithError({})
      props.setTouched(false)
    } else {
      props.setShowErrorAlert(true)
      props.setTabWithError(_tabErrors)
    }
    return validation
  }

  return (
    <div className="row-detail">
      <div className="tab-content col-12">
        {props.loading && <PageSpinner />}
        <Tabs
          forceRender={true}
          activeTab={props.activeTab}
          setActiveTab={(tab) => {
            props.setActiveTab(tab)
            if (props.activeTab !== tab) handleTabsValidation()
          }}>
          <div id="tab-sub" label={FIRST_TAB}>
            <div className="row">
              {/*TIPO PERSONA*/}
              <div className={`${sizesClass['tab-sub'].personType} mb-3 ps-1 pt-2`}>
                <div className="input-group">
                  <div className="inputRadio me-5">
                    <label
                      disabled={props.disabled}
                      className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                      htmlFor={`pType1${values?.id}`}
                      onClick={() => handlePersonType(Constants.PNF)}>
                      <input
                        disabled={props.disabled}
                        className="form-check-input pe-1 me-2 mt-0"
                        style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                        type="radio"
                        checked={values.personType === Constants.PNF}
                        onChange={() => {}}
                      />
                      {labels.LEGAL_PERSON}
                    </label>
                  </div>
                  <div className="inputRadio">
                    <label
                      disabled={props.disabled}
                      className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                      htmlFor={`pType2${values?.id}`}
                      onClick={() => handlePersonType(Constants.PF)}>
                      <input
                        disabled={props.disabled}
                        className="form-check-input pe-1 me-2 mt-0"
                        style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                        type="radio"
                        checked={values.personType === Constants.PF}
                        onChange={() => {}}
                      />
                      {labels.PHYSICAL_PERSON}
                    </label>
                  </div>
                </div>
              </div>
              {/*DATI ANAGRAFICI*/}
              <div className={`${sizesClass['tab-sub'].fiscalCode} pe-2 mb-2`}>
                <BwmInput
                  name="fiscalCode"
                  label={
                    props.checkIfFiscalCodeIsRequired(values.birthProvince)
                      ? `${labels.FISCAL_CODE}*`
                      : `${labels.FISCAL_CODE}`
                  }
                  maxLength="16"
                  disabled={props.disabled}
                  className="form-control"
                  value={values.fiscalCode}
                  error={errors.fiscalCode || errors.errorFiscalCode}
                  touched={props.touched}
                  onChange={(e) => {
                    Utility.handleChangeValue(
                      e.target.value,
                      'fiscalCode',
                      values,
                      setValues,
                      true,
                      true
                    )
                  }}
                  onBlur={(e) => handleKeyPress(e.target.value)}
                />
              </div>
              {values.personType === Constants.PNF && (
                <div className={`${sizesClass['tab-sub'].denomination} pe-2`}>
                  <BwmInput
                    label={`${labels.DENOMINATION}*`}
                    maxLength="60"
                    disabled={props.disabled}
                    className="form-control font-weight-bold font-size-big"
                    value={values.denomination}
                    error={errors.denomination}
                    touched={props.touched}
                    onChange={(e) =>
                      Utility.handleChangeValue(
                        e.target.value,
                        'denomination',
                        values,
                        setValues,
                        true
                      )
                    }
                  />
                </div>
              )}

              {values.personType === Constants.PF && (
                <>
                  <div className={`${sizesClass['tab-sub'].lastName} pe-2`}>
                    <BwmInput
                      name="lastName"
                      label={`${labels.LAST_NAME}*`}
                      maxLength="25"
                      disabled={props.disabled}
                      className="form-control font-weight-bold font-size-big"
                      value={values.lastName}
                      error={errors.lastName}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'lastName',
                          values,
                          setValues,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass['tab-sub'].firstName} pe-2`}>
                    <BwmInput
                      label={`${labels.FIRST_NAME}*`}
                      maxLength="25"
                      className="form-control font-weight-bold font-size-big"
                      name="name"
                      disabled={props.disabled}
                      value={values.firstName}
                      error={errors.firstName}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'firstName',
                          values,
                          setValues,
                          true
                        )
                      }
                    />
                  </div>
                  <div className={`${sizesClass['tab-sub'].gender} text-truncate pe-2 mb-2`}>
                    <BwmSelect
                      options={Constants.genderList}
                      name="gender"
                      label={`${labels.GENDER}*`}
                      disabled={props.disabled}
                      className="form-control"
                      value={values.gender}
                      error={errors.gender}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(e.target.value, 'gender', values, setValues, true)
                      }
                    />
                  </div>
                  <div className={`${sizesClass['tab-sub'].birthDate} pe-2 mb-2`}>
                    <DateField
                      label={`${labels.BIRTH_DATE}*`}
                      date={values.birthDate}
                      disabled={props.disabled}
                      error={errors.birthDate}
                      customOptions={{ maxDate: new Date() }}
                      touched={props.touched}
                      onChange={(date) =>
                        Utility.handleChangeValue(date, 'birthDate', values, setValues)
                      }
                    />
                  </div>
                  <div className={`${sizesClass['tab-sub'].birthLocation} pe-2 mb-2`}>
                    <AutoCompileCustom
                      label={`${labels.BIRTH_LOCATION}*`}
                      id="Location"
                      className="form-control"
                      disabled={props.disabled}
                      filter={locationsBirth}
                      value={values.birthLocation}
                      error={errors.birthLocation}
                      touched={props.touched}
                      handleInputAutocompile={handleInputAutocompileBirth}
                    />
                  </div>
                  <div className={`${sizesClass['tab-sub'].province} pe-2 mb-2`}>
                    <ProvinceField
                      disabled={props.disabled}
                      maxLength="2"
                      label={`${labels.PROVINCE}*`}
                      placeholder={`${labels.EE_FOR_FOREIGN_COUNTRY}`}
                      province={values.birthProvince || ''}
                      error={errors.birthProvince}
                      touched={props.touched}
                      onChange={(e) =>
                        Utility.handleChangeValue(
                          e.target.value,
                          'birthProvince',
                          values,
                          setValues,
                          true
                        )
                      }
                    />
                  </div>
                </>
              )}
              <div className={`${sizesClass['tab-sub'].location} pe-2`}>
                <AutoCompileCustom
                  label={
                    values.personType === Constants.PF
                      ? `${labels.RESIDENCE_LOCATION}*`
                      : `${labels.LEGAL_SEAT_LOCATION}*`
                  }
                  id="Location"
                  className="form-control"
                  filter={locations}
                  disabled={props.disabled}
                  value={values.location}
                  error={errors.location}
                  touched={props.touched}
                  handleInputAutocompile={handleInputAutocompileResidence}
                />
              </div>
              <div className={`${sizesClass['tab-sub'].postalCode} pe-2`}>
                <BwmInput
                  label={`${labels.POSTAL_CODE}*`}
                  className="form-control"
                  maxLength="5"
                  disabled={props.disabled}
                  value={values.postalCode}
                  error={errors.postalCode}
                  touched={props.touched}
                  onChange={(e) =>
                    Utility.handleChangeValue(
                      e.target.value,
                      'postalCode',
                      values,
                      setValues,
                      true,
                      true
                    )
                  }
                />
              </div>
              <div className={`${sizesClass['tab-sub'].province} pe-2`}>
                <ProvinceField
                  disabled={props.disabled}
                  maxLength="2"
                  label={`${labels.PROVINCE}*`}
                  placeholder={`${labels.EE_FOR_FOREIGN_COUNTRY}`}
                  province={values.province || ''}
                  error={errors.province}
                  touched={props.touched}
                  onChange={(e) =>
                    Utility.handleChangeValue(
                      e.target.value,
                      'province',
                      values,
                      setValues,
                      true,
                      true
                    )
                  }
                />
              </div>
              <div className={`${sizesClass['tab-sub'].address} pe-2 mb-2`}>
                <BwmInput
                  label={
                    values.personType === Constants.PF
                      ? `${labels.RESIDENCE_ADDRESS}*`
                      : `${labels.LEGAL_SEAT_ADDRESS}*`
                  }
                  disabled={props.disabled}
                  className="form-control"
                  value={values.address}
                  error={errors.address}
                  touched={props.touched}
                  onChange={(e) =>
                    Utility.handleChangeValue(e.target.value, 'address', values, setValues, true)
                  }
                />
              </div>
              <div className={`${sizesClass['tab-sub'].nation}`}>
                <BwmSelect
                  options={getCountryList()}
                  label={`${labels.NATION}*`}
                  className="form-control"
                  maxLength="3"
                  disabled={props.disabled}
                  value={values.nation}
                  error={errors.nation}
                  touched={props.touched}
                  onChange={(e) =>
                    Utility.handleChangeValue(e.target.value, 'nation', values, setValues)
                  }
                />
              </div>
            </div>
          </div>
          {values.personType === Constants.PF ? (
            <div id="tab-sub-doc" label={SECOND_TAB}>
              {/*SEZIONE DOCUMENTI  (ARCO DOCUMENTS)*/}

              <div className="row mb-2">
                <div className={`${sizesClass['tab-sub-doc'].arcoDocumentType} pe-2`}>
                  <BwmSelect
                    options={convertToNewDocumentList()}
                    name="document_type"
                    label={`${labels.DOCUMENT_TYPE}*`}
                    className="form-control"
                    disabled={props.disabled}
                    value={values.arcoDocument?.arcoDocumentType?.id}
                    error={errors.arcoDocument?.arcoDocumentType}
                    touched={props.touched}
                    onChange={(e) => handlerArcoDocumentType(e)}
                  />
                </div>
                <div className={`${sizesClass['tab-sub-doc'].number} pe-2`}>
                  <BwmInput
                    label={`${labels.DOCUMENT_NUMBER}*`}
                    className="form-control"
                    name="number"
                    disabled={props.disabled}
                    value={values.arcoDocument?.number}
                    error={errors.arcoDocument?.number}
                    touched={props.touched}
                    onChange={(e) => handlerChangeArcoDocumentValue(e.target.value, 'number', true)}
                  />
                </div>
                <div className={`${sizesClass['tab-sub-doc'].beginDate} pe-2`}>
                  <DateField
                    label={`${labels.ISSUANCE_DATE}*`}
                    name={'beginDate'}
                    disabled={props.disabled}
                    date={values.arcoDocument?.beginDate}
                    error={errors.arcoDocument?.beginDate}
                    touched={props.touched}
                    onChange={(date) => handlerChangeArcoDocumentReleaseDate(date)}
                  />
                </div>
                <div className={`${sizesClass['tab-sub-doc'].endDate} pe-2`}>
                  <DateField
                    label={`${labels.EXPIRATION_DATE}*`}
                    name={'endDate'}
                    disabled={props.disabled}
                    date={values.arcoDocument?.endDate}
                    error={errors.arcoDocument?.endDate}
                    touched={props.touched}
                    onChange={(date) => handlerChangeArcoDocumentExpirationDate(date)}
                  />
                </div>
                <div className={`${sizesClass['tab-sub-doc'].authority}`}>
                  <BwmInput
                    label={`${labels.AUTHORITY_OF_ISSUANCE}*`}
                    className="form-control"
                    name="authority"
                    disabled={props.disabled}
                    value={values.arcoDocument?.authority?.toUpperCase()}
                    error={errors.arcoDocument?.authority}
                    touched={props.touched}
                    onChange={(e) =>
                      handlerChangeArcoDocumentValue(e.target.value, 'authority', true)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className={`${sizesClass['tab-sub-doc'].politicallyExposed}`}>
                  <div className="input-group mt-3">
                    <span className="text-primary font-weight-bold pe-3">
                      {labels.POLITICALLY_EXPOSED}
                    </span>
                    <BwmCheckbox
                      name="politicallyExposed"
                      className="form-control"
                      disabled={props.disabled}
                      checked={values.arcoDocument?.politicallyExposed}
                      error={errors.arcoDocument?.politicallyExposed}
                      touched={props.touched}
                      onChange={(e) =>
                        handlerChangeArcoDocumentValue(e.target.checked, 'politicallyExposed')
                      }
                    />
                  </div>
                </div>

                <div className={`${sizesClass['tab-sub-doc'].sourceOfFunds} pe-2 mb-2`}>
                  <BwmInput
                    label={`${labels.SOURCE_OF_FUNDS}`}
                    className="form-control"
                    name="sourceOfFunds"
                    disabled={props.disabled}
                    value={values.arcoDocument?.sourceOfFunds?.toUpperCase()}
                    error={errors.arcoDocument?.sourceOfFunds}
                    touched={props.touched}
                    onChange={(e) =>
                      handlerChangeArcoDocumentValue(e.target.value, 'sourceOfFunds', true)
                    }
                  />
                </div>

                <div className={`${sizesClass['tab-sub-doc'].sourceOfFundsDescription}`}>
                  <BwmInput
                    label={`${labels.SOURCE_OF_FUNDS_DETAIL}`}
                    className="form-control"
                    name="name"
                    disabled={props.disabled}
                    value={values.arcoDocument?.sourceOfFundsDescription}
                    error={errors.arcoDocument?.sourceOfFundsDescription}
                    touched={props.touched}
                    onChange={(e) =>
                      handlerChangeArcoDocumentValue(
                        e.target.value,
                        'sourceOfFundsDescription',
                        true
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div id="tab-sub" label={SECOND_TAB} disabled="disabled"></div>
          )}

          <div
            id="tab-sub-sign"
            label={values?.personType === Constants.PF ? THIRD_TAB_PF : THIRD_TAB_PNF}>
            <ArcoRegistrySignatories
              disabled={props.disabled}
              sizes={sizesClass['tab-sub-sign']}
              arcoSubjectId={props.arcoSubjectId}
              arcoSubject={props.arcoSubject}
              signatories={values.arcoRegistrySignatories}
              selectedItem={props?.selectedItem}
              addSignatory={(signatory) => addSignatoryOrHolder(Constants.SIGNATORY, signatory)}
              removeSignatory={(id) => removeSignatoryOrHolder(Constants.SIGNATORY, id)}
              getArcoRegistries={props.getArcoRegistries}
              handleClose={props.onHide}
              openChildRegistryModal={(registry) =>
                openChildRegistryModal(registry, Constants.SIGNATORY)
              }
            />
          </div>
          {values.personType === Constants.PNF ? (
            <div id="tab-sub-hold" label={FOURTH_TAB}>
              <ArcoRegistryHolders
                disabled={props.disabled}
                sizes={sizesClass['tab-sub-hold']}
                arcoSubjectId={props.arcoSubjectId}
                arcoSubject={props.arcoSubject}
                holders={values.arcoRegistryHolders}
                selectedItem={props?.selectedItem}
                addHolder={(holder) => addSignatoryOrHolder(Constants.HOLDER, holder)}
                removeHolder={(id) => removeSignatoryOrHolder(Constants.HOLDER, id)}
                getArcoRegistries={props.getArcoRegistries}
                handleClose={props.onHide}
                openChildRegistryModal={(registry) =>
                  openChildRegistryModal(registry, Constants.HOLDER)
                }
              />
            </div>
          ) : (
            <div id="tab-sub" label={FOURTH_TAB} disabled="disabled"></div>
          )}
          <div id="tab-sub-risk" label={FIFTH_TAB}>
            {/*SEZIONE RISCHI*/}
            <div className="row mb-2">
              <div className={`${sizesClass['tab-sub-risk'].a1} pe-2 mb-2`}>
                <BwmSelect
                  options={getAList('A1')}
                  allowEmpty={false}
                  name="a1"
                  label={`${labels.A1}*`}
                  disabled={props.disabled}
                  className="form-control"
                  value={values.a1?.id}
                  errors={values.a1}
                  touched={props.touched}
                  onChange={(e) => handlerChangeA(e, 'a1')}
                />
              </div>
              <div className={`${sizesClass['tab-sub-risk'].a2} pe-2 mb-2`}>
                <BwmSelect
                  options={getAList('A2')}
                  allowEmpty={false}
                  name="a2"
                  label={`${labels.A2}*`}
                  className="form-control"
                  disabled={props.disabled}
                  value={values.a2?.id}
                  errors={values.a2}
                  touched={props.touched}
                  onChange={(e) => handlerChangeA(e, 'a2')}
                />
              </div>
              <div className={`${sizesClass['tab-sub-risk'].a3} pe-2 mb-2`}>
                <BwmSelect
                  options={getAList('A3')}
                  allowEmpty={false}
                  name="a3"
                  label={`${labels.A3}*`}
                  disabled={props.disabled}
                  className="form-control"
                  value={values.a3?.id}
                  errors={values.a3}
                  touched={props.touched}
                  onChange={(e) => handlerChangeA(e, 'a3')}
                />
              </div>
              <div className={`${sizesClass['tab-sub-risk'].a4} pe-2 mb-2`}>
                <BwmSelect
                  options={getAList('A4')}
                  allowEmpty={false}
                  name="a4"
                  label={`${labels.A4}*`}
                  className="form-control"
                  disabled={props.disabled}
                  value={values.a4?.id}
                  errors={values.a4}
                  touched={props.touched}
                  onChange={(e) => handlerChangeA(e, 'a4')}
                />
              </div>
              <div className={`${sizesClass['tab-sub-risk'].a5}`}>
                <BwmSelect
                  options={getAList('A5')}
                  allowEmpty={false}
                  name="a5"
                  label={`${labels.A5}*`}
                  className="form-control"
                  disabled={props.disabled}
                  value={values.a5?.id}
                  errors={values.a5}
                  touched={props.touched}
                  onChange={(e) => handlerChangeA(e, 'a5')}
                />
              </div>
            </div>
          </div>
        </Tabs>
      </div>
      <div className="d-flex mt-3 justify-content-between align-item-center">
        <div className="d-flex">
          <button className="btn btn-outline-primary" onClick={() => props.close()}>
            <i className="thx-icon thx-cancel q-icon me-1" />
            {labels.CANCEL}
          </button>
          <div className="mt-2 ms-5">
            <b className="me-2">*</b>
            <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
          </div>
        </div>
        <div className="d-flex">
          <button
            disabled={!values.id || props.disabled}
            className="btn btn-outline-primary me-2"
            onClick={controlloAntiterrorismo}>
            <i className="thx-text-file thx-icon me-2" />
            {labels.ANTITERRORISM_CHECK}
          </button>
          <button disabled={props.disabled} className="btn btn-primary" onClick={saveAll}>
            <i className="thx-icon thx-floppy-disk q-icon me-2" />
            {labels.SAVE}
          </button>
        </div>
      </div>
      {showRegistryModal && (
        <div>
          <ArcoRegistryModal
            arcoSubjectId={props.arcoSubjectId}
            arcoSubject={props.arcoSubject}
            selectedItem={detailItem}
            showModalCheck={showRegistryModal}
            onHide={closeChildRegistryModal}
            depth={depth}
            fromRegistry={props.fromRegistry}
            fromHolders={props.fromHolders}
            fromSignatories={props.fromSignatories}
          />
        </div>
      )}
    </div>
  )
}
