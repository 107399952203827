import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CheckLicenseAndRedirect } from '../../shared/auth/license/LicenseChecker'
import * as Constants from '../../../config/Constants'
import * as Utility from '../../shared/Utility'
import * as actions from '../../../actions'
import getText from '../../shared/i18n/labels'
import { useParams, useHistory } from 'react-router-dom'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import BlueSearchForm from './NewBlueSearchForm'
import BlueResultForm from './NewBlueResultForm'

// COMPONENTS
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupError } from '../../shared/popups/PopupError'
import BlueSearchResultPopup from './BlueSearchResultsPopup'

import arrow_l from '../../../styles/images/arrow_l.png'

const defaultSize = (size = null) => ({
  leftSide: Utility.getSize(size, { default: '30', md: '40' }),
  rightSide: Utility.getSize(size, { default: '70', md: '60' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export default function BlueSearchPage({ setId }) {
  const params = useParams()

  if (params.id) setId(params.id)

  const dispatch = useDispatch()
  const history = useHistory()

  const subject = useSelector((state) => state.blueSubject.data)
  const auth = useSelector((state) => state.auth)
  const [isLoading, setLoading] = useState(false)
  const [searchResult, setSearchResults] = useState('')
  const [showResultsModal, setShowResultsModal] = useState(false)
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  if (params.id) setId(params.id)

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    dispatch(actions.getBlueSubject(params.id))
  }, [])

  useEffect(() => {
    if (auth?.user?.customer) {
      CheckLicenseAndRedirect(
        history,
        '/app/blue/dashboard',
        auth?.user?.customer?.customerApplications,
        Constants.APPLICATION_BLUE,
        setId
      )
    }
  }, [auth])

  const executeSearch = (data) => {
    setLoading(true)
    actions
      .checkBlueAntiTerrorism(params.id, data)
      .then(
        (res) => {
          setSearchResults(res)
          if (res?.result) {
            setShowResultsModal(true)
          }
        },
        (err) => {
          PopupError({
            text: labels.SEARCH_ERROR,
            title: labels.WARNING,
            request: labels.CONTACT_SUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => setLoading(false))
  }

  const closeResultsModal = () => {
    setShowResultsModal(false)
  }

  const isLoadingFunction = (value) => {
    setLoading(value)
  }

  const goToDashboard = () => {
    setId()
    history.push('/app/blue/dashboard')
  }

  return (
    <>
      {isLoading && <PageSpinner text={labels.SPINNERTITLE} />}
      <div className="table-custom mt-1" style={{ marginBottom: '60px' }}>
        <div className="row title-page align-items-center justify-content-between">
          <div className="cursor-pointer d-flex align-items-center mb-3" onClick={goToDashboard}>
            <img
              src={arrow_l}
              className="ms-2 me-3"
              width="10"
              height="19"
              alt={labels.RETURN_TO_SUBJECTS_LIST}
            />
            {subject?.denomination}
          </div>
        </div>
        <div className="tabs single">
          <div className="row">
            <div id="blue-search-form" className={`${sizesClass.leftSide} text-center pe-3`}>
              <div className="pe-4">
                <BlueSearchForm subject={subject} executeSearch={executeSearch} />
              </div>
            </div>
            <div className={`${sizesClass.rightSide} text-center`}>
              <BlueResultForm
                isLoading={isLoadingFunction}
                subject={subject}
                results={searchResult}
                className="d-flex flax-direction-column"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        backdrop="static"
        show={showResultsModal}
        onHide={closeResultsModal}
        dialogClassName="blue-results-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.SEARCH_COMPLETED}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueSearchResultPopup
            key="resultsModal"
            data={searchResult}
            subject={subject}
            onClose={closeResultsModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
