import { ComureiSubject } from '../components/application/application-comurei/subject-comurei/NewComureiSubject'
import { CoraSubject } from '../components/application/application-cora/subject-cora/NewCoraSubject'
import { CrsSubject } from '../components/application/application-crs/subject-crs/NewCrsSubject'

import { FormAccreditamento } from '../components/application/facciamo-noi/FormAccreditamento'
import { Dashboard } from '../components/dashboard/Dashboard'
import Login from '../components/login/Login'
import { ErrorPage } from '../components/shared/ErrorPage'
// import CorproSubject from '../components/application/application-corpro/subject-corpro/CorproSubject'
import { ArcoSubject } from '../components/application/application-arco/subject-arco/NewArcoSubject'
import BlueSubject from '../components/application/application-blue/NewBlueSubject'
import { AdminCustomers } from '../components/admin/NewAdminCustomers'
import { FormGestioneCertificati } from '../components/application/facciamo-noi/FormGestioneCertificati'
import { TestPage } from '../components/TestPage'
import { AssistanceLogin } from '../components/login/AssistanceLogin'
import { StarTel } from '../components/admin/StarTel/StarTelRoot'
import { FormBenvenuto } from '../components/application/facciamo-noi/facciamo-noi-tutto/FormBenvenuto'
import { FormCora } from '../components/application/facciamo-noi/facciamo-noi-tutto/FormCora'
import { FacciamoNoiTuttoCoraThankYou } from '../components/application/facciamo-noi/facciamo-noi-tutto/ThankYou'
import { FacciamoNoiAdmin } from '../components/application/facciamo-noi/FacciamoNoiAdmin'
import { CoraAdmin } from '../components/application/application-cora/CoraAdmin'
import { Dac7Refresh } from '../components/application/application-dac7/Dac7Refresh'

export const ApplicationPaths = {
  protected: [
    { path: '/dashboard', component: Dashboard },
    { path: '/app/comurei/dashboard', component: ComureiSubject },
    /*CORA*/
    { path: '/app/cora/dashboard', component: CoraSubject, withChildren: true },
    /**CRS */
    { path: '/app/crs/dashboard', component: CrsSubject, withChildren: true },
    /**CORPRO */
    // { path: '/app/corpro/dashboard', component: CorproSubject },
    /**ARCO */
    { path: '/app/arco/dashboard', component: ArcoSubject, withChildren: true },
    /**BLUE */
    { path: '/app/blue/dashboard', component: BlueSubject, withChildren: true },
    /*ADMIN*/
    { path: '/admin', component: AdminCustomers },
    { path: '/admin/star-tel', component: StarTel },
    { path: '/app/facciamo-noi/admin', component: FacciamoNoiAdmin },
    { path: '/app/cora/admin', component: CoraAdmin },
    /*ALTRO*/
    { path: '/credenziali-assistenza', component: AssistanceLogin },
    /*DAC7*/
    { path: '/app/dac7/dashboard', component: Dac7Refresh }
  ],
  unprotected: [
    { path: '/', component: Login },
    { path: '/login', component: Login },
    { path: '/error', component: ErrorPage },
    { path: '/app/facciamo-noi/accreditamento', component: FormAccreditamento },
    { path: '/app/facciamo-noi/certificati', component: FormGestioneCertificati },
    { path: '/app/facciamo-noi/tutto/benvenuto', component: FormBenvenuto },
    { path: '/app/facciamo-noi/tutto/cora', component: FormCora },
    { path: '/app/facciamo-noi/tutto/cora/thankyou', component: FacciamoNoiTuttoCoraThankYou },
    { path: '/test', component: TestPage }
  ]
}
