import { removeKeysFromObject } from '../Utility'
import { Link } from 'react-router-dom'

export const fieldTypes = {
  NUMBER: 'number',
  DATE: 'date',
  DATERANGE: 'daterange',
  TEXT: 'text',
  CURRENCY: 'currency',
  SELECTION: 'selection'
}

export const sortDirections = { ASC: 'ASC', DESC: 'DESC' }

/**
 * oggetto di base per le ricerche.
 * è possibile fare l'override di tutti i campi
 * la chiave "version" deve essere specificata nelle liste, poiché viene controllata in fase di lettura del local storage
 * se la version è diversa da quella specificata in localstorage, quest'ultimo viene sovrascritto.
 * Questo serve a evitare conflitti in caso di modifiche alla ricerca di default
 */
export const baseSearchObject = {
  page: 0,
  pageSize: 20,
  sortField: 'id',
  sortDirection: 'DESC'
}

export const TableUtils = {
  /**
   * utilizza questa funzione per creare un oggetto con le istruzioni per creare una cella header
   * @param {*} sortable mostrerà una icona di ordinamento accanto al nome
   * @param {*} searchable  mostrerà una casella di testo per cercare in quella colonna
   * @param {*} fieldName nome tecnico del campo
   * @param {*} displayedName nome della colonna per l'utente
   * @param {*} type  tipo del campo, di default TEXT
   * @param options
   * @param minWidth
   * @param maxWidth
   * @param pinRight
   * @param pinLeft
   * @param additionalClass
   * @returns
   */
  composeHeader: ({
    fieldName = '',
    displayedName = '',
    orderingName = undefined,
    sortable = true,
    searchable = true,
    type = fieldTypes.TEXT,
    options = [{ id: null, description: '' }],
    minWidth = '0',
    maxWidth = '1fr',
    pinRight = false,
    pinLeft = false,
    additionalClass = '',
    component = () => {
      return false
    }
  }) => {
    return {
      sortable,
      searchable,
      fieldName,
      displayedName,
      orderingName,
      type,
      options,
      minWidth,
      maxWidth,
      pinRight,
      pinLeft,
      additionalClass,
      component
    }
  },

  /**
   * utilizza questa funzione per creare un oggetto con le istruzioni per creare una cella di riga
   * @param {*} fieldName nome tecnico del campo
   * @param {*} fieldValue  valore del campo da mostrare
   * @param {*} onClick callback su click della cella, fornisce i dati della riga e della cella. Disabilitato se presente un componente
   * @param {*} editable rende il campo modificabile. Da lasciare a false se la cella contiene un componente
   * @param {*} onEdit callback su onChange della cella, fornisce id della riga, nome del campo, valore della cella
   * @param {*} additionalClass classi aggiuntive alla cella
   * @param {*} component componente React, da mostrare al posto del valore
   * @returns
   */
  composeCell: ({
    fieldName = '',
    fieldValue = '',
    onClick = (rowData, cellData) => {},
    editable = false,
    onEdit = (rowId, fieldName, value) => {},
    additionalClass = '',
    component = () => {
      return false
    }
  } = {}) => {
    return {
      fieldName,
      fieldValue,
      onClick,
      editable,
      onEdit,
      component,
      additionalClass
    }
  },

  /**
   * @param id id dell'oggetto rappresentato
   * @param {*} cellData oggetto composto da valori composti tramite composeCell(), rappresenta i valori da visualizzare
   * @param {*} checkable determina se la riga è selezionabile tramite checkbox
   * @param {*} additionalClass classi aggiuntive alla riga
   * @returns
   */
  composeRow: ({
    id = null,
    rowData = {},
    cellData = {},
    checkable = true,
    additionalClass = ''
  } = {}) => {
    return {
      id,
      cellData,
      rowData,
      checkable,
      additionalClass
    }
  },

  /**
   * restituisce la lista con la riga aggiornata.
   * @param {*} param0
   * @returns
   */
  updateList: ({ list = [], rowId, fieldName = '', value = '' }) => {
    return list.map((element) => {
      if (element.id === rowId) {
        element[fieldName] = value
      }
      return element
    })
  },

  updateHeadersOrder: (original = [], reordered = [], tableConfiguration) => {
    let newArr = [...original]
    let columnsChanged = false
    reordered.forEach((r, index) => {
      const el = original.find((h) => {
        return h?.fieldName === r?.fieldName
      })
      if (el) newArr[index] = el
      else columnsChanged = true
    })
    if (columnsChanged || (original?.length !== reordered?.length && reordered?.length > 0)) {
      tableConfiguration.cleanSearchObject()
      window.location.reload(false)
      return original
    }
    return newArr
  },

  isHiddenColumn: (hiddenColumns = [], column) => {
    return hiddenColumns.find((hidden) => column.fieldName === hidden.fieldName)
  },

  //imposta le dimensioni delle colonne, a partire dalle impostazioni date da composeHeader()
  prepareHeaderSizes: (
    columns = [],
    hiddenColumns = [],
    withCheckBoxes = false,
    tableConfiguration
  ) => {
    const colLenghts = columns.map((col) => {
      if (!TableUtils.isHiddenColumn(hiddenColumns, col)) {
        const size = `minmax(${col.minWidth}, ${col.maxWidth})`
        return size
      }
    })
    const sizes = ` ${withCheckBoxes ? ' 40px ' : ''} ${colLenghts.join(' ')}`
    if (tableConfiguration) {
      tableConfiguration.setColumnSizes(sizes)
    }
    return sizes
  },

  //restituisce il searchObject, sottraendo i campi NON modificati del baseSearchObject
  //questo serve per avere una base dalla quale è possibile resettare la ricerca
  getCleanSearchObject: (searchObject = { baseSearchObject }) => {
    return removeKeysFromObject(searchObject, Object.keys(baseSearchObject))
  },

  /**
   *
   * @param {*} value
   * @param {*} path path della app
   * @param {*} textPosition posizione del testo
   * @returns
   */
  createLink: (value = '', path = '#', textPosition = 'left') => {
    return (
      <Link
        className={`text-decoration-none text-body d-block w-100 text-truncate text-${textPosition}`}
        to={path}>
        {value}
      </Link>
    )
  }
}
