import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'
export function computeIsError(communicationStatus) {
  return (
    communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED ||
    communicationStatus === CommunicationStatus.ERROR_SID ||
    communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
  )
}

export function computeStatusText(communication) {
  const labels = getText(localStorage.getItem('language') || 'it')

  let status = ''
  if (communication.communicationStatus === CommunicationStatus.CREATED) {
    if (isNotEmpty(communication?.scheduledPecDate)) {
      status = `${labels.CREATED_DELAYED_SENDING_ON} ${formatDateForDisplay(communication?.scheduledPecDate)} (${labels.FILE} ATPECZC.${communication.pecatFilename}.p7m)`
    } else if (isNotEmpty(communication?.pecSentDate)) {
      status = `${labels.CREATED_PEC_SENT_ON} ${formatDateForDisplay(communication?.pecSentDate)} (${labels.FILE} ATPECZC.${communication.pecatFilename}.p7m)`
    } else {
      status = `${labels.CREATED_WAITING_FOR_RECEIPT} (${labels.FILE} ATPECZC.${communication.pecatFilename}.p7m)`
    }
  } else if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
    status = labels.ERRORS_PRESENT_IN_COMMUNICATION
  } else if (communication?.communicationStatus === CommunicationStatus.ERROR_SID) {
    status = `${labels.WARNING} ${communication?.communicationError}.`
  } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
    status = `${labels.RECEIPT_PROCESSED_ACCEPTED} (${labels.FILE} ${communication.pecatFilename})`
  } else if (
    communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR
  ) {
    status = `${labels.RECEIPT_PROCESSED_ACCEPTED_WITH_ERRORS} (${labels.FILE} ${communication.pecatFilename})`
  } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
    status = labels.RECEIPT_PROCESSED_REJECTED
  }
  return status
}

export function isReceipt(communication) {
  return (
    communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED ||
    communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR ||
    communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED
  )
}
export function isCreatedWithError(communication) {
  return communication?.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
}

export function CommunicationModalRow(props) {
  const labels = getText(localStorage.getItem('language') || 'it')

  const communication = props.communication
  const status = props.status
  const isError = props.isError
  const createdWithError = isCreatedWithError(communication)

  return (
    <div className="text-start row-table" key={'communication-modal-row-' + communication.id}>
      <div
        title={communication.referencePeriod}
        className={`${props.sizes.registrationDate} div-td`}>
        {communication.referencePeriod}
      </div>
      <div
        title={`${communication.communicationType?.code} - ${communication.communicationType?.description}`}
        className={`${props.sizes.communicationType} div-td text-truncate`}>
        {communication.communicationType?.code} - {communication.communicationType?.description}
      </div>
      <div
        title={communication.dispatchType === 'ORDINARY' ? labels.ORDINARY : labels.STRAORDINARY}
        className={`${props.sizes.sendType} div-td text-truncate`}>
        {communication.dispatchType === 'ORDINARY' ? labels.ORDINARY : labels.STRAORDINARY}
      </div>
      <div
        className={
          isError
            ? `${props.sizes.status} div-td text-truncate color-red`
            : `${props.sizes.status} div-td text-truncate`
        }>
        {status}
      </div>
      <div className={`${props.sizes.download} div-td files text-center containerPopOver`}>
        {createdWithError && (
          <span
            data-tip={labels.DOWNLOAD_ERROR_REPORT}
            onClick={() => props.downloadErrorReport(communication)}>
            <i className="thx-triangle thx-icon" />
          </span>
        )}
        <div
          data-tip={labels.DOWNLOAD_COMMUNICATION_TXT}
          onClick={() => props.downloadTxt(communication)}>
          <i className="thx-text-file thx-icon" />
        </div>
        {communication.communicationStatus === CommunicationStatus.CREATED && (
          <>
            <div data-tip={labels.DOWNLOAD_PDF} onClick={() => props.downloadPdf(communication)}>
              <i className="thx-pdf thx-icon" />
            </div>
            <div
              data-tip={labels.DOWNLOAD_PEC_TXT}
              onClick={() => props.downloadPecTz(communication)}>
              <i className="thx-zip thx-icon" />
            </div>
          </>
        )}
        {communication?.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR && (
          <>
            <div data-tip={labels.DOWNLOAD_PDF} onClick={() => props.downloadPdf(communication)}>
              <i className="thx-pdf thx-icon" />
            </div>
            <div data-tip={labels.DOWNLOAD_DGN} onClick={() => props.downloadDgn(communication)}>
              <i className="thx-list-file thx-icon" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
