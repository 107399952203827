import { useState } from 'react'
import { useSelector } from 'react-redux'
import { testEmail, isEmpty } from '../../../shared/Utility'
import axiosService from '../../../../services/axios-service'
import getText from '../../../shared/i18n/labels'

// MODALS
import Modal from 'react-bootstrap/Modal'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export function ArcoSubjectExportModal(props) {
  const emailAddressToProps = props.emailCustomer

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseModal, setResponseModal] = useState(false)
  const [responseKo, setResponseKo] = useState('')
  const [responseOk, setResponseOk] = useState('')
  const [responseBody, setResponseBody] = useState('')
  const [emailAddress, setEmailAddress] = useState(emailAddressToProps || '')
  const [emailModified, setEmailModified] = useState(false)
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })

  let email = !emailModified ? props.emailCustomer : emailAddress

  const closeModal = () => {
    setShowModal(false)
    setEmailAddress(props.emailCustomer)
  }

  const closeResponseModal = () => {
    setResponseModal(false)
    setEmailAddress(props.emailCustomer)
  }

  const openModal = () => {
    setShowModal(true)
    handlerValidation(email)
  }

  const handlerEmail = (e) => {
    handlerValidation(e.target.value)
    setEmailAddress(e.target.value)
    setEmailModified(true)
  }

  const handlerValidation = (_emailAddress) => {
    if (isEmpty(_emailAddress)) {
      setErrorEmail({ isValid: false, msg: labels.REQUIRED_FIELD })
      return false
    }
    const emailToTest = emailModified ? _emailAddress : props.emailCustomer
    if (!testEmail(emailToTest)) {
      setErrorEmail({ isValid: false, msg: labels.EMAIL_NOT_FORMALLY_CORRECT })
      return false
    }
    setErrorEmail({ isValid: true, msg: '' })
    return true
  }

  const exportArcoSubjects = () => {
    if (handlerValidation(emailAddress)) {
      setLoading(true)
      const axiosInstance = axiosService.getInstance()
      let subjectsIds = props.checkedItems.map((r) => r)
      axiosInstance({
        data: subjectsIds,
        url: `/api/arco/custom/arco-subjects/export/${emailModified ? emailAddress : props.emailCustomer}`,
        method: 'POST'
      })
        .then(
          (response) => {
            setResponseOk('OK')
            setResponseModal(true)
            setResponseBody(response.data)
            props.setCheckedItems()
          },
          (err) => {
            setResponseKo("Errore durante l'esportazione")
            PopupError({ text: "Errore durante l'invio dei documenti" })
          }
        )
        .then(() => {
          closeModal()
          setLoading(false)
        })
    }
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-outline-primary ${props.disabled && 'disabled'}`}
        data-tip={labels.EXPORT_SUBJECTS}
        onClick={openModal}>
        <i className="thx-export thx-icon me-2 mt-1" />
        {labels.EXPORT}
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.EXPORT_SUBJECTS}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p style={{ fontSize: '16px' }}>{labels.EXPORT_SUBJECTS_DESCRIPTION}</p>
          </div>
          <div className="mt-2 d-flex radius">
            <div className="tab-content col-12">
              <div className="form-row">
                <div className="col-12 subDetailComurei">
                  <div className="row">
                    <div className="col-12">
                      <BwmInput
                        id="emailAddress"
                        type="email"
                        className="form-control"
                        maxLength="100"
                        showErrorMessage={true}
                        label={labels.EMAIL_ADDRESS_TO_SEND_EXPORT_FILE}
                        value={email}
                        error={errorEmail}
                        onChange={(e) => handlerEmail(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={errorEmail?.isValid === false}
              onClick={exportArcoSubjects}
              type="button"
              className="btn btn-primary">
              {labels.EXPORT}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {responseModal && (
        <Modal
          backdrop="static"
          show={responseModal}
          onHide={closeResponseModal}
          dialogClassName="modal-70w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>{labels.START_EXPORT_SUBJECTS_PROCEDURE}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseOk ? (
              <>
                {labels.START_EXPORT_SUBJECTS_PROCEDURE} (
                <span className="font-weight-bold">{email}</span>)
              </>
            ) : (
              <div>
                <div className="row">
                  <div className="col-12">
                    <p className={'color-red'}>
                      {labels.ERROR_OCCURRED_DURING_OPERATION} {responseKo}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end mt-5">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => closeResponseModal()}>
                {labels.OK}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
