import { useSelector } from 'react-redux'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { ArcoRegistrationContractForm } from './NewArcoRegistrationContractForm'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export function ArcoRegistrationContractModal(props) {
  const lang = useSelector((state) => state.language.language)

  registerLocale(lang, lang === 'it' ? it : en)

  return (
    <>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{props.arcoSubject?.companyName}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ArcoRegistrationContractForm
              disabled={props.disabled}
              selectedContract={props.selectedContract || {}}
              arcoSubject={props.arcoSubject}
              arcoCausalOperations={props.arcoCausalOperations}
              currencies={props.currencies}
              addArcoContract={props.addArcoContract}
              onHide={props.onHide}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
