import { confirmAlert } from 'react-confirm-alert'
import getText from '../../shared/i18n/labels'

// BUTTONS
import { BtnEmpty } from '../BtnEmpty'

import 'react-confirm-alert/src/react-confirm-alert.css'

export function PopupSuccess(props) {
  const labels = getText(localStorage.getItem('language') || 'it')

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button type="button" className="close btn-close" onClick={onClose}>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <div className={'row show-grid popup-title-success text-center'}>
              <div className="col-12 py-3">
                <h5>{props?.title || labels.SUCCESS_TITLE}</h5>
              </div>
            </div>
            <div className={'row show-grid text-center'}>
              <div className="col-12" dangerouslySetInnerHTML={{ __html: props?.text }}></div>
              {props?.dataList && props?.dataList?.length > 0 ? (
                <div className="col-12">
                  <div className={'h300Scroll'}>
                    {props.dataList.map((data, index) => {
                      return <p key={index}>{data}</p>
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="pt-4 text-center d-flex justify-content-center">
              <BtnEmpty
                text={props?.ok || labels.OK}
                handlerOnClick={() => {
                  if (props?.handleClickConfirm) {
                    props.handleClickConfirm()
                  }
                  onClose()
                }}
              />
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
