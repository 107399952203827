import { useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadFile, isEmpty, isNotEmpty } from '../../../shared/Utility'
import * as actions from '../../../../actions'
import { testEmail } from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// FORM
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'
import { baseSearchObject } from '../../../shared/tables/TableUtils'

export function CoraSubjectsExportModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emailAddress, setEmailAddress] = useState()
  const [requestCompleteExport, setRequestCompleteExport] = useState(true)
  const [requestTxt, setRequestTxt] = useState(false)
  const [requestCsv, setRequestCsv] = useState(false)
  const [totalRelationships, setTotalRelationships] = useState(0)
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })
  const [currentIndex, setCurrentIndex] = useState(0)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const customer = useSelector((state) => state.auth.customer)

  const closeModal = () => {
    setShowModal(false)
    setEmailAddress(customer.user.email)
    setTotalRelationships(0)
    setCurrentIndex(0)
  }

  const openModal = () => {
    handlerEmail(customer.user.email)
    setShowModal(true)
    setCurrentIndex(0)
    countTotalRelationships(props.checkedItems[0])
  }

  const countTotalRelationships = (currentSubject) => {
    actions
      .searchCoraRelationships({
        ...baseSearchObject,
        parentId: currentSubject?.id,
        pageSize: 1
      })
      .then((res) => {
        setTotalRelationships(res.totalElements)
      })
  }

  const handlerEmail = (value) => {
    handlerValidation(value)
    setEmailAddress(value)
  }

  const handlerValidation = (_emailAddress) => {
    if (isEmpty(_emailAddress)) {
      setErrorEmail({ isValid: false, msg: labels.REQUIRED_FIELD })
      return false
    }
    if (!testEmail(_emailAddress)) {
      setErrorEmail({ isValid: false, msg: labels.EMAIL_NOT_FORMALLY_CORRECT })
      return false
    }
    setErrorEmail({ isValid: true, msg: '' })
    return requestTxt || requestCsv || requestCompleteExport
  }

  const advanceSubjectOrCloseModal = () => {
    const advancedIndex = currentIndex + 1
    setCurrentIndex(advancedIndex)
    const advancedSubject = props.checkedItems[advancedIndex]
    if (isNotEmpty(advancedSubject)) countTotalRelationships(props.checkedItems[advancedIndex])
    else closeModal()
  }

  const onClickExport = () => {
    if (handlerValidation(emailAddress)) {
      setLoading(true)
      let formats = []
      if (requestCsv) formats.push('CSV')
      if (requestTxt) formats.push('TXT')
      let email = ''
      if (requestCompleteExport) email = emailAddress
      actions
        .exportCoraRelationshipsSync(props.checkedItems[currentIndex].id, formats, email)
        .then(
          (res) => {
            if (res && (requestTxt || requestCsv)) {
              downloadFile(res.data, `EXPORT_${props.checkedItems[currentIndex].completeName}.zip`)
            }
            //avanzare il soggetto corrente
            advanceSubjectOrCloseModal()
          },
          (err) => {
            PopupError({ text: labels.ERROR })
          }
        )
        .then(() => setLoading(false))
    }
  }

  return (
    <>
      {loading ? <PageSpinner /> : ''}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-outline-primary ${props.disabled && 'disabled'}`}
        data-tip={labels.EXPORT_SELECTED_SUBJECTS}
        onClick={openModal}>
        <i className="thx-export thx-icon me-2 mt-1" />
        {labels.EXPORT}
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {labels.EXPORT_SUBJECTS} {props.checkedItems[currentIndex]?.completeName || ''}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row-detail d-flex">
            <div className="tab-content col-md-12 col-lg-12">
              <div className="form-row">
                <div className="col-md-8 col-lg-8 subDetailComurei">
                  <div className="row">
                    <div className="input-group mt-2 mb-2">
                      <BwmCheckbox
                        name="completo"
                        label={labels.DOWNLOAD_ALL_DATA}
                        className="form-control"
                        checked={requestCompleteExport}
                        onChange={() => setRequestCompleteExport(!requestCompleteExport)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-11 col-lg-11">
                      <BwmInput
                        id="emailAddress"
                        type="email"
                        className="form-control"
                        maxLength="100"
                        label={`${labels.EMAIL_ADDRESS_TO_SEND_EXPORT_FILE}*`}
                        value={emailAddress}
                        error={errorEmail}
                        onChange={(e) => handlerEmail(e.target.value)}
                      />
                      <span className="text-danger">{errorEmail?.msg}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5 className="mt-4">
              <strong>{labels.DOWNLOAD_CORA_RELATIONSHIPS_ONLY_REPORTS}</strong>
            </h5>
            ({labels.DETECTED}: {totalRelationships}. {labels.EXECUTION_MAY_TAKE_A_FEW_MINUTES})
            <div className="row">
              <div className="col-6">
                <div className="input-group mt-2">
                  <BwmCheckbox
                    name="TXT"
                    label={labels.TXT_FORMAT}
                    checked={requestTxt}
                    className="form-control"
                    onChange={() => setRequestTxt(!requestTxt)}
                  />
                </div>
                <div className="input-group mt-2">
                  <BwmCheckbox
                    name="CSV"
                    label={labels.CSV_FORMAT}
                    checked={requestCsv}
                    className="form-control"
                    onChange={() => setRequestCsv(!requestCsv)}
                  />
                </div>
              </div>
              {!requestTxt && !requestCsv && !requestCompleteExport && (
                <span className="text-danger pt-2">{labels.SELECT_ONE_FORMAT}</span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button onClick={onClickExport} type="button" className="btn btn-primary px-5">
              {labels.CONFIRM}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
