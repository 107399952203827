import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'
import { CreateCommunicationModal } from './NewCreateCommunicationModal'

// FORM
import EmailForm from './EmailForm'

// COMPONENTS
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

const defaultSize = (size = null) => ({
  checkbox: Utility.getSize(size, { default: '3' }),
  creationDate: Utility.getSize(size, { default: '10' }),
  referenceYear: Utility.getSize(size, { default: '6' }),
  subject: Utility.getSize(size, { default: '22' }),
  type: Utility.getSize(size, { default: '10' }),
  status: Utility.getSize(size, { default: '37' }),
  download: Utility.getSize(size, { default: '12' })
})

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '140%'
  return '100%'
}

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const CommunicationsHistoryList = (props) => {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [show, setShow] = useState(false)
  const [emailFormOpen, setEmailFormOpen] = useState(false)
  const [communicationToSend, setCommunicationToSend] = useState(null)
  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [subject, setSubject] = useState()
  const [communicationsHistory, setCommunicationsHistory] = useState([])
  const lastErrorCommunication = useSelector((state) => state.lastErrorCommunication.data)
  const currencies = useSelector((state) => state.currencies.data)
  const communicationHistoryRef = useRef()

  const [sizesClass, setSizesClass] = useState(getSize())

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    getHistory(props.subjectId)
    getSubject(props.subjectId)
    getAllCurrency()

    handleResize()
    window.addEventListener('resize', handleResize)

    //cleanup degli eventi
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleResize = () => {
    const newHeight = window.innerHeight - 230
    if (communicationHistoryRef) communicationHistoryRef.current.style.height = `${newHeight}px`
  }

  const getHistory = (subjectId, showPopup = false) => {
    setLoading(true)
    actions.getCrsCommunicationsHistoryBySubjectId(subjectId).then((res) => {
      if (showPopup) {
        PopupSuccess({
          text: labels.REMOVED_SUCCESSFULLY
        })
      }
      setLoading(false)
      setCommunicationsHistory(res)
    })
  }

  const getSubject = (subjectId) => {
    actions.getCrsSubject(subjectId).then((res) => {
      setSubject(res)
    })
  }

  const getAllCurrency = () => {
    if (!currencies?.length) {
      dispatch(actions.getAllCurrency())
    }
  }

  const downloadReceiptTxt = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadReceiptTxt/${props.subjectId}/${communication.id}`
    callDownloadService(url, 'ATPECRQ.' + communication.sidFilename + '.run.txt')
  }

  const downloadDgn = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadDgn/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.xmlFilename.replace('.xml', '.dgn'))
  }

  const downloadXml = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadXml/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.xmlFilename)
  }

  const downloadPecTz = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadPecTz/${props.subjectId}/${communication.id}`
    callDownloadService(url, 'PECAT.' + communication.sidFilename + '.zip')
  }

  const downloadPdf = (communication) => {
    Utility.downloadFile(
      new Blob([Utility.base64ToArrayBuffer(communication.pdf)], { type: 'application/pdf' }),
      communication.xmlFilename.replace('.xml', '.pdf')
    )
    PopupSuccess({ text: labels.FILE_DOWNLOADED })
  }

  const callDownloadService = (url, fileName) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        if (response?.data?.size > 0) {
          Utility.downloadFile(new Blob([response.data]), fileName)
          PopupSuccess({ text: labels.FILE_DOWNLOADED })
        } else {
          PopupError({ text: labels.FILE_NOT_FOUND })
        }
      }
    })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: `${Object.keys(checkedItems)?.length || 0} ${labels.COMMUNICATIONS}?`,
      handleClickConfirm: onConfirmDeleteSelectedCommunications
    })
  }

  const onConfirmDeleteSelectedCommunications = () => {
    setLoading(true)
    let idArray = Object.keys(checkedItems)
    actions.deleteCrsCommunications(props.subjectId, idArray).then(
      (res) => {
        getHistory(props.subjectId, true)
        setAllCheck(false)
        setCheckedItems({})
      },
      (err) => {
        let error = labels.GENERIC_ERROR
        if (err?.data?.title) {
          error = err.data.title
        }
        PopupError({
          text: error,
          labels
        })
      }
    )
  }

  const showDetail = () => {
    setShow(!show)
  }

  const closeEmailModal = () => {
    setEmailFormOpen(false)
    setCommunicationToSend(null)
  }

  const openEmailModal = (communication) => {
    setEmailFormOpen(true)
    setCommunicationToSend(communication)
  }

  const renderRowHeader = () => {
    return (
      <>
        <div className={`${sizesClass.checkbox} div-td text-center`}>
          <input
            disabled={!(communicationsHistory?.length > 0)}
            name="tablecheckbox"
            type="checkbox"
            checked={allCheck}
            onClick={(e) => handleAllCheckChange(e)}
          />
        </div>
        <div className={`${sizesClass.creationDate} div-td`}>{labels.CREATION_DATE}</div>
        <div className={`${sizesClass.referenceYear} div-td`}>{labels.PERIOD}</div>
        <div className={`${sizesClass.subject} div-td`}>{labels.SUBJECT}</div>
        <div className={`${sizesClass.type} div-td`}>{labels.TYPE}</div>
        <div className={`${sizesClass.status} div-td`}>{labels.STATUS}</div>
        <div className={`${sizesClass.download} div-td`}>{labels.DOWNLOAD}</div>
      </>
    )
  }

  const renderErrorRowHeader = () => {
    return (
      <>
        <div className="w-10 div-td">{labels.CREATION_DATE}</div>
        <div className="w-8 div-td">{labels.PERIOD}</div>
        <div className="w-15 div-td">{labels.SUBJECT}</div>
        <div className="w-10 div-td">{labels.TYPE_OF_SENDING}</div>
        <div className="w-8 div-td">{labels.COMMUNICATION}</div>
        <div className="w-15 div-td">{labels.STATUS}</div>
        <div className="w-50 div-td">{labels.ERRORS}</div>
        <div className="w-10 div-td">{labels.DOWNLOAD}</div>
      </>
    )
  }

  const downloadPdfComponent = (communication) => {
    const iconPdf =
      communication?.pdf !== null && communication?.pdf !== '' && communication !== undefined
    if (!iconPdf) {
      return null
    }
    return (
      <div className="pdf me-3 cursor-pointer">
        <span onClick={() => downloadPdf(communication)}>
          <i className="thx-pdf thx-icon text-primary" />
        </span>
      </div>
    )
  }

  const resetcheckbox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(communicationsHistory)
      newRows.forEach((row) => (items[row.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === communicationsHistory?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetcheckbox()
      }
    }
  }

  const renderRows = (communications) => {
    if (loading) {
      return <PageSpinner />
    } else if (!communications || communications?.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center border pt-2">
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '30px', height: '30px' }}
          />
          <h6>{labels.NO_RESULTS_TO_SHOW}</h6>
        </div>
      )
    }
    return communications.map((communication) => {
      /**
       * Se la comunicazione è creata e in attesa: puoi scaricare tutti i file. 
          Se è creata con errori, puoi scaricare l'xml,il pdf ed i log. i Pecat in questo caso non ci sono.
          Se carichi una ricevuta di una comunicazione su crs, puoi scaricare tutti i file 
          Se carichi una ricevuta di una comunicazione che non esiste, puoi scaricare solo l'xml e la ricevuta in chiaro. 
       */
      let status = ''
      let receipt = false // indica se la ricevuta esiste o abbiamo solo la comunicazione
      let communication_exist = false //indica se la comunicazione esiste, o se è stata elaborata solo la ricevuta
      let error = false
      let created =
        communication.communicationStatus === CommunicationStatus.CREATED ||
        communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
      if (communication.communicationStatus === CommunicationStatus.CREATED) {
        status =
          labels.CREATED_WAITING_FOR_RECEIPT +
          ' (file ATPECRQ.' +
          communication.sidFilename +
          '.p7m)'
        communication_exist = true
      } else if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
        communication_exist = true
        error = true
        status = labels.CREATED_WITH_ERRORS
      } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
        communication_exist = true
        status = labels.COMMUNICATION_ACCEPTED_TOTALLY
        receipt = true
      } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
        communication_exist = true
        status = labels.COMMUNICATION_SCARTED_TOTALLY
        receipt = true
      } else if (
        communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR
      ) {
        status = labels.COMMUNICATION_ACCEPTED_PARTIALLY
        communication_exist = true
        receipt = true
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FIND
      ) {
        status = labels.RECEIPT_ACCEPTED_WITH_ERRORS_AND_COMMUNICATION_NOT_FOUND_ON_CRS_WEB
        receipt = true
        communication_exist = false
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
      ) {
        status = labels.RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FOUND_ON_CRS_WEB
        receipt = true
        communication_exist = false
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
      ) {
        status = labels.RECEIPT_SCARTED_AND_COMMUNICATION_NOT_FOUND_ON_CRS_WEB
        receipt = true
        communication_exist = false
      }
      return (
        <div key={'communicationHistory-' + communication.id} className={`text-start row-table`}>
          <div className={`${sizesClass.checkbox} text-center div-td`}>
            <input
              type="checkbox"
              id={`checkbox_${communication.id || ''}`}
              checked={checkedItems[communication.id] || false}
              onChange={(e) => handleCheckChange(e, communication.id)}
            />
          </div>
          <div className={`${sizesClass.creationDate} div-td text-truncate`}>
            {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
          </div>
          <div className={`${sizesClass.referenceYear} div-td text-truncate`}>
            {communication.referenceYear}
          </div>
          <div className={`${sizesClass.subject} div-td text-truncate`}>
            {communication.subject.companyName}
          </div>
          <div className={`${sizesClass.type} div-td text-truncate`}>
            {communication.relationships ? labels.WITH_REPORTS : labels.EMPTY}
          </div>
          <div className={`${sizesClass.status} div-td text-truncate`} title={status}>
            {status}
          </div>
          <div
            className={`${sizesClass.download} div-td text-truncate d-flex containerPopOver justify-content-start`}>
            {(created || receipt) && (
              <span
                className="cursor-pointer ms-2 me-3"
                data-tip={labels.SEND_COMMUNICATION}
                onClick={() => openEmailModal(communication)}>
                <i className="thx-envelope thx-icon text-primary" />
              </span>
            )}
            {receipt && (
              <div className="cursor-pointer me-3 receipt">
                <span onClick={() => downloadReceiptTxt(communication)}>
                  <i className="thx-text-file thx-icon text-primary" />
                </span>
              </div>
            )}
            {communication_exist && (
              <>
                <div className="cursor-pointer me-3 xml">
                  <span onClick={() => downloadXml(communication)}>
                    <i className="thx-text-file thx-icon text-primary" />
                  </span>
                </div>
                {!error && (
                  <div className="cursor-pointer me-3 zip">
                    <span onClick={() => downloadPecTz(communication)}>
                      <i className="thx-zip thx-icon text-primary" />
                    </span>
                  </div>
                )}

                {downloadPdfComponent(communication)}

                <div className="cursor-pointer me-3 dgn">
                  <span onClick={() => downloadDgn(communication)}>
                    <i className="thx-list-file thx-icon text-primary" />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )
    })
  }

  const renderErrorRows = (communication) => {
    let status = ''
    if (communication.communicationStatus === CommunicationStatus.CREATED) {
      status = labels.CREATED_WAITING_FOR_RECEIPT
    } else if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
      status = labels.CREATED_WITH_ERRORS
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
      status = labels.RECEIPT_ACCEPTED
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
      status = labels.RECEIPT_NOT_ACCEPTED
    }
    return (
      <>
        <div
          className="text-start row-table"
          onClick={(e) => {
            if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR)
              showDetail()
          }}>
          <div className="w-10 div-td text-truncate">
            {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
          </div>
          <div className="w-8 div-td text-truncate">{communication.subject.referenceYear}</div>
          <div className="w-15 div-td text-truncate">{subject.companyName}</div>
          <div className="w-10 div-td text-truncate">CRS</div>
          <div className="w-8 div-td text-truncate">XML</div>
          <div className="w-15 div-td text-truncate">{status}</div>
          <div className="w-50 div-td text-truncate">
            {communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
              ? communication.communicationError
                ? communication.communicationError
                : labels.ERRORS_PRESENT_IN_REPORTS_INCLUDED_IN_THE_COMMUNICATION
              : ''}
          </div>
          <div className="w-10 div-td text-truncate d-flex justify-content-around pe-4 ps-4">
            <button alt={labels.DOWNLOAD_XML} onClick={() => downloadXml(communication)}>
              <i className="thx-text-file thx-icon" />
            </button>
          </div>
        </div>
      </>
    )
  }

  const renderLastCommunicationError = () => {
    return (
      <>
        {lastErrorCommunication.creationDate ? (
          <>
            <div className="row header-btn align-items-left">
              {labels.LAST_COMMUNICATION_IN_ERROR}
            </div>
            <div className="text-start header-table error">{renderErrorRowHeader()}</div>
            {renderErrorRows(lastErrorCommunication)}
          </>
        ) : (
          ''
        )}
      </>
    )
  }

  return (
    <>
      {renderLastCommunicationError()}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="row header-btn-v2 align-items-left">{labels.COMMUNICATIONS_HISTORY}</div>
        <div className="d-flex pb-1">
          <button
            type="button"
            className={`btn btn-primary me-2`}
            onClick={() => openPopupDelete()}
            disabled={Object.keys(checkedItems).length === 0}>
            <i className="thx-icon thx-trash me-2" />
            {labels.DELETE}
          </button>
          <div>
            <CreateCommunicationModal
              upload={true}
              subjectId={subject?.id}
              subject={subject}
              emailAddress={subject?.coraSubjectSid?.email}
              getHistory={getHistory}
            />
          </div>
        </div>
      </div>
      <div className="star-table-recipient">
        <div
          ref={communicationHistoryRef}
          className="star-table communication-history-ref"
          style={{ width: getTableContainerWidth() }}>
          <div className="text-start header-table">{renderRowHeader()}</div>
          {renderRows(communicationsHistory)}
        </div>
      </div>
      <Modal
        backdrop="static"
        show={emailFormOpen}
        onHide={closeEmailModal}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.SEND_SELECTED_COMMUNICATION}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailForm
            subject={subject}
            closeModal={closeEmailModal}
            emailAddress={subject?.coraSubjectSid?.email}
            flagPec={subject?.coraSubjectSid?.flagPec}
            sentCommunications={[communicationToSend]}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
