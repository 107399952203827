import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLE
import { ComureiSubjectList } from './NewComureiSubjectList'

// MODALS
// import { ComureiSubjectModal } from './NewComureiSubjectModal'
import { CreateComureiCommunicationModal } from '../communications/NewCreateCommunicationModal'

import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'

import '../../../../styles/dac7NewStyles.css'

export function ComureiSubject() {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const auth = useSelector((state) => state.auth)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const [historicSubjects, setHistoricSubjects] = useState(0)
  const [customerApplication, setCustomerApplication] = useState()
  const [resetForm, setResetForm] = useState(false)
  const [subjectSummary, setSubjectSummary] = useState(null)

  const [communication, setCommunication] = useState({
    show: false,
    user: null,
    subject: null,
    communication: null
  })

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => ({
      name: location.location,
      key: index
    }))
  )

  const subjectTypes = useSelector((state) => state.comureiSubjectTypes.data)

  const _subjects = useSelector((state) => state.crsSubjects.data)
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    getSubjectSummary()
    getLocations()
    getSubjectTypes()
    getCommunicationTypes()
    getHistoricSubjects()
  }, [])

  useEffect(() => {
    setSubjects(_subjects)
  }, [_subjects])

  useEffect(() => {
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_COMUREI
    )
    const customerApplication = auth?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application?.id
    )
    setCustomerApplication(customerApplication)
  }, [auth])

  const getSubjectSummary = () => {
    CheckRemainingSubjects(
      Constants.APPLICATION_COMUREI,
      false,
      (subjectSummary) => {
        setDisableNewSubjectButtons(subjectSummary?.available < 1)
        setSubjectSummary(subjectSummary)
      },
      labels,
      lang
    )
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getSubjectTypes = () => {
    dispatch(actions.getAllComureiSubjectTypes())
  }

  const getCommunicationTypes = () => {
    dispatch(actions.getAllComureiCommunicationTypes())
  }

  const reloadList = () => {
    getSubjectSummary()
    getHistoricSubjects()
  }

  const closeCreateCommunicationModal = () => {
    setCommunication({
      show: false,
      user: null,
      subject: null,
      communication: null
    })
    getSubjectSummary()
  }

  const openCreateCommunicationModal = (subject, communications) => {
    setCommunication({
      show: true,
      user: auth.user,
      subject: subject,
      communication: communications
    })
  }

  const getHistoricSubjects = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: '/api/comurei/custom/comurei-subjects/historic',
      method: 'GET'
    }).then((response) => {
      setHistoricSubjects(response.data)
    })
  }

  return (
    <>
      <div className="table-custom">
        <div className="tab-content tab-rel">
          <div className="d-flex my-2 header-btn-relationship justify-content-between">
            <div className="d-flex align-items-center ps-0 mb-2">
              <SubjectTotalAndCallToAction
                total={subjectSummary?.total}
                remaining={subjectSummary?.available}
                used={subjectSummary?.used}
                licenseType={customerApplication?.licenseType}
                customerApplication={customerApplication}
                language={lang}
              />
              {window.innerWidth > Constants.BREAKPOINT_SM && (
                <span
                  className="ms-2 font-italic fw-light text-secondary"
                  style={{ fontSize: '1rem' }}>
                  {labels.HISTORIC_SUBJECTS_NOT_COUNTED}: {historicSubjects}
                </span>
              )}
            </div>

            <div>
              {/* <ComureiSubjectModal
                locations={locations}
                subjectTypes={subjectTypes}
                getSubjects={reloadList}
                openCreateCommunicationModal={openCreateCommunicationModal}
              /> */}
              {!disableNewSubjectButtons && (
                <button
                  type="button"
                  className="btn btn-primary mb-1"
                  disabled={disableNewSubjectButtons}
                  onClick={() => setResetForm(!resetForm)}>
                  <i className="thx-plus thx-icon me-2" />
                  {labels.NEW_SUBJECT}
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="px-0 z-index-0">
              <ComureiSubjectList
                resetForm={resetForm}
                subjects={subjects}
                subjectTypes={subjectTypes}
                locations={locations}
                getSubjects={getSubjectSummary}
                setSubjects={setSubjects}
                reloadList={reloadList}
                user={auth.user}
                openCreateCommunicationModal={openCreateCommunicationModal}
              />
            </div>
          </div>
        </div>
      </div>

      {communication.show && (
        <CreateComureiCommunicationModal
          showModal={communication.show}
          user={communication.user}
          subject={communication.subject}
          communications={communication.communication}
          closeModalComm={closeCreateCommunicationModal}
        />
      )}
    </>
  )
}
