import { useEffect, useState } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Navbar from './components/shared/Navbar'
import * as actions from './actions'
import './App.css'
import { Provider } from 'react-redux'
import { ProtectedRoute } from './components/shared/auth/ProtectedRoute'
import Footer from './components/shared/Footer'
import ReactGA from 'react-ga'
import getText from './components/shared/i18n/labels'
import { ApplicationPaths } from './services/ApplicationPaths'
import { MIN_SCREEN_SIZE } from './config/Constants'

const store = require('./reducers').init()

const App = () => {
  const labels = getText(localStorage.getItem('language') || 'it')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    getCurrentUser()

    const unsubscribe = store.subscribe(() => {
      if (store.getState().auth?.user?.email) {
        ReactGA.initialize('UA-148800686-1')
        ReactGA.pageview(window.location.pathname)
        ReactGA.set({
          userId: store.getState().auth.user.email
        })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getCurrentUser = () => {
    store.dispatch(actions.getAccount())
  }

  const logout = () => {
    store.dispatch(actions.logout())
  }

  const renderUnprotectedPaths = () => {
    return ApplicationPaths.unprotected.map((p) => {
      return <Route key={p.path} exact path={p.path} component={p.component} />
    })
  }

  const renderProtectedPaths = () => {
    return ApplicationPaths.protected.map((p) => {
      return (
        <ProtectedRoute
          key={p.path}
          exact={!p.withChildren}
          path={p.path}
          component={p.component}
        />
      )
    })
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <div className={windowWidth >= MIN_SCREEN_SIZE ? '' : 'd-none'}>
            <Navbar logout={logout} />
            <div className="page-body">
              {renderUnprotectedPaths()}
              {renderProtectedPaths()}
            </div>
            <Footer />
          </div>
          {windowWidth < MIN_SCREEN_SIZE && (
            <div className="App bg-primary screen-size-warning">
              <h1 className="text-white text-center mb-5">{labels.SCREENSIZEWARNING.title}</h1>
              <h2 className="fw-400 text-white text-center">
                {labels.SCREENSIZEWARNING.description}
              </h2>
            </div>
          )}
        </div>
      </BrowserRouter>
    </Provider>
  )
}

export default App
