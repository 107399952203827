import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import * as Constants from '../../../../config/Constants'
import { APPLICATION_CRS } from '../../../../config/Constants'
import { IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import { downloadFile } from '../../../shared/Utility'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLE
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'

// FORM
// import { CrsModalRelationship } from './NewModalRelationship'
import { RelationshipForm } from './NewCrsRelationshipForm'

// COMPONENTS
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear'
import { CreateCommunicationModal } from '../../application-crs/communications/NewCreateCommunicationModal'

import '../../../../styles/relationship-form.css'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '180%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '130%'
  return '100%'
}

const getOffset = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return 563
  return 550
}

export const CrsRelationshipList = (props) => {
  const { subjectId } = props
  const [rows, setRows] = useState([])
  const [relationships, setRelationships] = useState([])
  const [relationship, setRelationship] = useState(null)
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const auth = useSelector((state) => state.auth)
  const history = useHistory()
  const [expired, setExpired] = useState(false)
  const createCommunicationRefData = useRef()

  const [checkedItems, setCheckedItems] = useState([])
  const [checkedItemsRows, setCheckedItemsRows] = useState([])

  const [relationshipHolderTypes, setRelationshipHolderTypes] = useState([])
  const [relationshipNumbersTypes, setRelationshipNumbersTypes] = useState([])

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject
  })

  const tableName = 'crs-relationships-subject'

  const getRelationships = () => {
    searchRelationships(searchObject).then((res) => {
      setRelationships(res.content, res)
    })
  }

  const tableUtils = TableUtils

  useEffect(() => {
    setExpired(isExpired())
    getRelationshipNumberTypes()
    getRelationshipHolderTypes()
  }, [])

  useEffect(() => {
    setExpired(isExpired())
  }, [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, APPLICATION_CRS)
    }
  }

  useEffect(() => {
    if (expired) history.push(`/app/crs/dashboard/`)
  }, [expired])

  const searchRelationships = (newSearchObject) => {
    return actions.getCrsRelationshipsBySubjectId(subjectId, newSearchObject).then((res) => {
      setRelationships(res?.content || res?.data, res)
      setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
      return res
    })
  }

  const getRelationshipHolderTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-holder-types').then((res) => {
      setRelationshipHolderTypes(res.data)
    })
  }

  const getRelationshipNumberTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-relationship-number-types').then((res) => {
      setRelationshipNumbersTypes(res.data)
    })
  }

  const setNewRelationships = (content, res) => {
    setRelationships(content)
    setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Rapporto',
      handleClickConfirm: handlerDeleteRelationship
    })
  }

  const handlerDeleteRelationship = () => {
    setLoading(true)
    const promises = []
    checkedItemsRows.forEach((el) => {
      promises.push(actions.deleteCrsRelationship(el, subjectId))
    })
    Promise.all(promises).then(
      () => {
        searchRelationships(searchObject)
        resetCheckBox()
        setLoading(false)
        PopupSuccess({ text: labels.REMOVED_SUCCESSFULLY })
        setRelationship()
      },
      () => PopupError({ text: labels.GENERIC_ERROR })
    )
  }

  const resetCheckBox = () => {
    setCheckedItems([])
    setCheckedItemsRows([])
    setDisabled(true)
  }

  const downloadRelationshipPdfList = () => {
    const labels = getText(localStorage.getItem('language') || 'it')

    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: `/api/crs/custom/crs-communications/downloadRelationshipPdfList/${subjectId}`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        downloadFile(response.data, props.subject?.companyName + '_RELATIONSHIPS.pdf')
        PopupSuccess({ text: labels.DOWNLOAD_RELATIONSHIPS_PDF })
      }
    })
  }

  // const closeModal = () => {
  //   setRelationship(null)
  // }

  const sendCommunication = () => {
    if (props.subject) {
      const lastYear = new Date().getFullYear() - 1
      const referenceYear = props.subject.referenceYear
      if (referenceYear < lastYear) {
        PopupAlertCommunicationYear(
          referenceYear,
          lastYear,
          () => createCommunicationRefData.current.clickButtonSend(),
          () => {
            let subject = props.subject
            subject.referenceYear = lastYear
            actions.addCrsSubject(subject).then(
              (res) => {
                createCommunicationRefData.current.clickButtonSend()
              },
              (err) => PopupError({ text: labels.GENERIC_ERROR })
            )
          }
        )
      } else {
        createCommunicationRefData.current.clickButtonSend()
      }
    }
  }

  const renderHeaderButtons = () => {
    let containsRelationships =
      relationships?.length > 0 &&
      moment(
        auth?.user?.customer?.customerApplications.filter((ca) => ca?.application?.id === 3)[0]
          .expiration
      ).diff(moment()) > 0
    return (
      <>
        <div className="d-flex align-items-center justify-content-between header-btn-relationship mb-2">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className={`btn btn-outline-primary me-2 ${disabled ? 'disabled' : ''}`}
              onClick={openPopupDelete}
              disabled={disabled}>
              <i className="thx-trash thx-icon me-2" />
              {labels.DELETE}
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary me-2`}
              onClick={() => downloadRelationshipPdfList()}>
              <i className="thx-pdf thx-icon me-2" />
              {labels.PRINT_RELATIONSHIPS}
            </button>

            <CreateCommunicationModal
              ref={createCommunicationRefData}
              label={
                !containsRelationships
                  ? labels.CREATE_EMPTY_COMMUNICATION
                  : labels.CREATE_COMMUNICATION
              }
              generateEmpty={!containsRelationships}
              subjectId={props.subject?.id}
              subject={props.subject}
              emailAddress={props.subject?.coraSubjectSid?.email}
              reloadRelationships={getRelationships}
              classCustom={'display-none'}
              selectedRelationship={checkedItems}
            />
            <button onClick={() => sendCommunication()} className="btn btn-primary me-2">
              <i className="thx-text-file thx-icon me-2" />
              {labels.CREATE_COMMUNICATION}
              {!containsRelationships && ' vuota'}
            </button>
          </div>
          <div>
            {/* <CrsModalRelationship
              disabled={!containsRelationships}
              subject={props.subject}
              getRelationships={getRelationships}
              closeModal={closeModal}
            /> */}
            <button
              type="button"
              className="btn btn-primary float-end"
              disabled={expired}
              onClick={() => setRelationship()}>
              <i className="thx-plus thx-icon me-2" />
              {labels.NEW_RELATIONSHIP}
            </button>
          </div>
        </div>
      </>
    )
  }

  const updateCheckedItems = (_checkedItems = []) => {
    const checkedItemsRows = cloneDeep(_checkedItems).map(
      (el) => rows.find((row) => row.id === el)?.rowData
    )
    setDisabled(!_checkedItems.length)
    setCheckedItemsRows(checkedItemsRows)
    setCheckedItems(_checkedItems)
  }

  const onClickRow = (row) => {
    setRelationship(row.rowData)
  }

  const formatCurrency = (amount, currencyCode) => {
    let body = {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
    return amount.toLocaleString('it-IT', body)
  }

  const header = [
    tableUtils.composeHeader({
      fieldName: 'sequentialCode',
      orderingName: 'progressive',
      displayedName: labels.PROGRESSIVE,
      maxWidth: '1fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalIdentifier',
      orderingName: 'fiscalIdentifier',
      displayedName: labels.FISCAL_IDENTIFIER,
      maxWidth: '1fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'completeName',
      orderingName: 'completeName',
      displayedName: labels.HOLDER,
      maxWidth: '2fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'holderType',
      orderingName: 'holderType',
      displayedName: labels.HOLDER_TYPE,
      type: fieldTypes.SELECTION,
      options: relationshipHolderTypes,
      maxWidth: '2fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'relationshipNumber',
      orderingName: 'relationshipNumber',
      displayedName: labels.NUMBER,
      maxWidth: '1fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'numberType',
      orderingName: 'numberType',
      options: relationshipNumbersTypes,
      displayedName: labels.NUMBER_TYPE,
      type: fieldTypes.SELECTION,
      maxWidth: '3fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'balance',
      displayedName: labels.BALANCE,
      maxWidth: '1fr'
    })
  ]

  const prepareRows = (fromRelationships = []) => {
    return fromRelationships.map((relationship) => {
      return tableUtils.composeRow({
        id: relationship.id,
        rowData: relationship,
        cellData: {
          sequentialCode: tableUtils.composeCell({
            fieldName: 'sequentialCode',
            fieldValue: relationship.progressive
          }),
          fiscalIdentifier: tableUtils.composeCell({
            fieldName: 'fiscalIdentifier',
            fieldValue: relationship?.fiscalIdentifier
          }),
          completeName: tableUtils.composeCell({
            fieldName: 'completeName',
            fieldValue: relationship?.completeName
          }),
          holderType: tableUtils.composeCell({
            fieldName: 'holderType',
            fieldValue: relationship?.holderType
          }),
          relationshipNumber: tableUtils.composeCell({
            fieldName: 'relationshipNumber',
            fieldValue: relationship?.relationshipNumber
          }),
          numberType: tableUtils.composeCell({
            fieldName: 'numberType',
            fieldValue: relationship?.numberType
          }),
          balance: tableUtils.composeCell({
            fieldName: 'balance',
            additionalClass: 'text-end',
            fieldValue: formatCurrency(
              parseInt(relationship.balance == null ? '0.00' : relationship.balance),
              relationship?.currency?.isoCode || 'EUR'
            )
          })
        }
      })
    })
  }

  useEffect(() => {
    setRows(prepareRows(relationships))
    setRelationship(relationship ? relationship : relationships?.[0])
  }, [relationships])

  return (
    <>
      {renderHeaderButtons()}
      <StarTable
        id={relationship?.id}
        version="1"
        headerColumns={header}
        checkedItems={checkedItems}
        rows={rows}
        getOffset={getOffset}
        tableContainerWidth={getTableContainerWidth()}
        formOnBottom={true}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setRelationship()
          return searchRelationships(searchObject)
        }}
        onExecutedSearch={(content, res) => setNewRelationships(content, res)}
        usePagination={true}
        withCheckBoxes={true}
        onClickCheck={updateCheckedItems}
        onClickRow={onClickRow}
        formComponent={RelationshipForm}
        formComponentProps={{
          index: relationship?.id || '0',
          disabled: expired || loading,
          relationshipNumbersTypes: relationshipNumbersTypes,
          relationshipHolderTypes: relationshipHolderTypes,
          formOnBottom: true,
          id: relationship?.id,
          subject: props.subject,
          setLoading: setLoading,
          getRelationships: () => searchRelationships(searchObject),
          close: (newRelationship) =>
            setRelationship(
              newRelationship ? newRelationship : relationship ? relationship : undefined
            )
        }}
        // dialogComponent={
        //   relationship?.id !== null &&
        //   relationship?.id !== undefined && (
        //     <CrsModalRelationship
        //       id={relationship.id}
        //       hideButton={true}
        //       showModal={!!relationship?.id}
        //       relationship={relationship}
        //       subject={props.subject}
        //       getRelationships={getRelationships}
        //       closeModal={closeModal}
        //     />
        //   )
        // }
      />
    </>
  )
}
