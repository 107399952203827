import { confirmAlert } from 'react-confirm-alert'
import getText from '../../shared/i18n/labels'

// BUTTONS
import { BtnEmpty } from '../BtnEmpty'
import { ButtonAssistance } from '../form/ButtonAssistance'

import 'react-confirm-alert/src/react-confirm-alert.css'

export function PopupError(props) {
  const labels = getText(localStorage.getItem('language') || 'it')

  return confirmAlert({
    customUI: ({ onClose }) => {
      const ButtonClose =
        props?.buttonClose ||
        (() => (
          <>
            <BtnEmpty
              text={props?.close || labels.CLOSE || 'Chiudi'}
              classCustom="m-0"
              handlerOnClick={onClose}
            />
          </>
        ))
      return (
        <>
          <div className="bar"></div>
          <div className="popup modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button type="button" className="close" onClick={onClose}>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <div className={'row show-grid popup-title-delete text-center'}>
              <div className="col-12">
                <h5>{props?.title || labels.WARNING}</h5>
              </div>
            </div>
            <div className={'row show-grid text-center'}>
              <div className="col-12 d-flex align-items-center flex-column">
                <div dangerouslySetInnerHTML={{ __html: props?.text }}></div>
              </div>
            </div>
            {props?.second_title !== undefined && (
              <div className={'row show-grid text-center'}>
                <div
                  className="col-12 d-flex align-items-center flex-column"
                  dangerouslySetInnerHTML={{ __html: props?.second_title }}></div>
              </div>
            )}

            <div className="d-flex pt-4 justify-content-between">
              <ButtonClose />
              <div className="ms-4">
                <ButtonAssistance className="mb-0" buttonTitle={props?.request} />
              </div>
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}

export function closePopupError() {
  const closeButton = document.querySelector('.react-confirm-alert .popup .close')
  if (closeButton) closeButton.click()
}
