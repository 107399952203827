import { useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODALS
import Modal from 'react-bootstrap/Modal'
import ModalProcessingOutComeReceipt from './ModalProcessingOutRecepts/ModalProcessingOutComeReceipt'

// FORM
import { Form } from 'react-bootstrap'

// COMPONENTS
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/popups/PopupError'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { checkReceiptSize } from '../../../shared/UploadReceipt'

const CONSISTENCY = 'CONSISTENCY'
const RECEIPT = 'RECEIPT'

function FileRow({ file }) {
  return (
    <div className="d-flex green-label text-truncate align-items-center">
      <i className="thx-check thx-icon me-2" />
      {file.name}
    </div>
  )
}

function isAtpec05(fileName = '') {
  return ['ATPEC05'].includes(fileName.toUpperCase().slice(0, 7))
}

export function UploadReceiptModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loadedFiles, setLoadedFiles] = useState([])
  const [errorReceipt, setErrorReceipt] = useState({ isValid: true, msg: '' })
  const [loading, setLoading] = useState(false)
  const [communication, setCommunication] = useState([])
  const [receiptType, setReceiptType] = useState(null)
  const [consistencyStartingYear, setConsistencyStartingYear] = useState('')

  const closeModal = () => {
    setShowModal(false)
    setLoadedFiles([])
    setErrorReceipt({ isValid: true, msg: '' })
    setCommunication([])
  }

  const openModal = () => {
    setShowModal(true)
    setLoadedFiles([])
    setErrorReceipt({ isValid: true, msg: '' })
    setReceiptType(null)
    setConsistencyStartingYear('')
    setCommunication([])
  }

  const determineModalType = (files = []) => {
    let modalType = null
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      if (isAtpec05(file.name)) {
        if (modalType === RECEIPT) {
          setErrorReceipt({
            isValid: false,
            msg: labels.SELECT_A_FILE_TYPE_AT_A_TIME
          })
          return null
        } else {
          modalType = CONSISTENCY
        }
      } else {
        if (modalType === CONSISTENCY) {
          setErrorReceipt({
            isValid: false,
            msg: labels.SELECT_A_FILE_TYPE_AT_A_TIME
          })
          return null
        } else {
          modalType = RECEIPT
        }
      }
    }
    return modalType
  }

  const checkFileName = (file) => {
    let name = file.name
    return Constants.ATPEC_REGEXP.test(name)
  }

  const handleSendReceipt = () => {
    closeModal()
    setLoading(true)
    const formData = new FormData()
    for (var x = 0; x < loadedFiles.length; x++) {
      let file = loadedFiles[x]
      formData.append('receiptFile', file)
    }
    if (receiptType === RECEIPT) {
      actions
        .createCoraReceipt(formData)
        .then(
          (communication) => {
            if (props.refreshSubjects) props.refreshSubjects()
            if (communication?.length > 0) {
              setCommunication(communication)
            } else {
              PopupError({
                text: labels.UNEXPECTED_ERROR_PROCESSING_THE_RECEIPT,
                labels
              })
            }
          },
          (errors) => {
            PopupError({ text: errors })
          }
        )
        .then(() => setLoading(false))
    } else if (receiptType === CONSISTENCY) {
      if (isNotEmpty(consistencyStartingYear)) {
        formData.append('startingYear', consistencyStartingYear)
      }
      actions
        .consistencyPhotoReceipt(formData)
        .then(
          (communication) => {
            if (communication?.length > 0) {
              props.refreshSubjects()
              setCommunication(communication)
            } else {
              PopupError({
                text: labels.UNEXPECTED_ERROR_PROCESSING_THE_CONSISTENCY_PHOTOGRAPHY,
                labels
              })
            }
          },
          (errors) => {
            PopupError({
              text: labels.UNEXPECTED_ERROR_PROCESSING_THE_CONSISTENCY_PHOTOGRAPHY,
              labels
            })
          }
        )
        .then(() => setLoading(false))
    }
  }

  const renderOptions = () => {
    const currYear = new Date().getFullYear() - 1
    let options = []
    options.push(
      <option key={`year-select-empty`} value={''}>
        {labels.ALL}
      </option>
    )
    for (let index = 0; index < 9; index++) {
      const year = currYear - index
      options.push(
        <option key={`year-select-${year}`} value={year}>
          {year}
        </option>
      )
    }
    return options
  }

  const onFileInput = (files) => {
    //valida nome, estensione e dimensioni files
    //popola stato, con oggetti di tipo {file, anno(se atpec05)}, sovrascrivendo duplicati
    let hasInvalidFile = false
    let invalidFileName = ''
    let loadedFiles = []
    let modalType = determineModalType(files)
    if (!modalType) {
      return
    }

    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      if (!checkFileName(file) || !checkReceiptSize(file)) {
        hasInvalidFile = true
        invalidFileName = file.name
      }
    }
    let error = { isValid: true, msg: '' }
    if (hasInvalidFile) {
      error = {
        isValid: false,
        msg: `Il file ${invalidFileName || ''} ${labels.IS_NOT_VALID_VERIFY_EXTENSION}`
      }
      modalType = null //azzero il tipo modale
    } else {
      loadedFiles = files
      error = { isValid: true, msg: '' }
    }
    setErrorReceipt(error)
    setLoadedFiles(loadedFiles)
    setReceiptType(modalType)
  }

  return (
    <>
      {loading && <PageSpinner />}

      <button
        type="button"
        className={`btn btn-outline-primary`}
        data-tip={labels.UPLOAD_FILES_TRANSMISSION_RECEIPT_TEXT}
        onClick={openModal}
        disabled={props.disabled}>
        <i className="thx-text-file thx-icon me-2" />
        {labels.ELABORATE_RECEIVED_FILES}
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {receiptType === CONSISTENCY && <h2>{labels.ELABORATE_CONSISTENCY_PHOTOGRAPHY}</h2>}
            {receiptType === RECEIPT && <h2>{labels.ELABORATE_RECEIVED_FILES_AND_RESULTS}</h2>}
            {isEmpty(receiptType) && <h2>{labels.ELABORATE_RECEIVED_FILES}</h2>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-between">
            <div className="image-upload text-start">
              <UploadFileLabel label={labels.UPLOAD_FILE_RECEIVED} forLabel={'receiptFile'} />
              <input
                id={`receiptFile`}
                type="file"
                name={`receiptFile`}
                multiple
                accept=".run,.enc,.p7m"
                onChange={(e) => onFileInput(e.target.files)}
              />
              <div className="certificatiLoaded text-start">
                {!errorReceipt.isValid && (
                  <div className="certificatiLoaded-error">{errorReceipt.msg}</div>
                )}

                {Array.from(loadedFiles).map((file, index) => {
                  return (
                    <div key={`file-row-${index}`}>
                      <FileRow file={file} />
                    </div>
                  )
                })}
              </div>
              {receiptType === CONSISTENCY && (
                <>
                  <div className={`form-group d-flex mt-4 align-items-center mb-4`}>
                    <label>{labels.SELECT_THE_STARTING_YEAR_OF_ELABORATION}: </label>
                    <Form.Control
                      className="form-control form-control-cell ms-2"
                      style={{ maxWidth: '4rem' }}
                      onChange={(e) => setConsistencyStartingYear(e.target.value)}
                      as="select"
                      defaultValue={consistencyStartingYear}>
                      {renderOptions()}
                    </Form.Control>
                  </div>
                  <div className="form-group">
                    <label>
                      {
                        labels.IF_NOTHING_IS_INDICATED_ALL_THE_REPORTS_PRESENT_IN_THE_PHOTOGRAPHY_WILL_BE_ELABORATED
                      }
                    </label>
                  </div>
                  <div className="form-group">
                    <label>
                      {labels.FOR_THE_SELECTION_THE_START_DATE_OF_THE_REPORTS_WILL_BE_READ}
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorReceipt.isValid || loadedFiles.length < 1}
              onClick={handleSendReceipt}
              type="button"
              className="btn btn-primary">
              {labels.CONFIRM}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {communication.map((communication, index) => {
        return (
          <ModalProcessingOutComeReceipt
            communication={communication.coraCommunication}
            key={communication.filename + '-' + index}
            fileName={communication.filename}
            subjectName={communication.coraCommunication?.subject?.completeName}
            receiptType={receiptType}
          />
        )
      })}
    </>
  )
}
