import { LANGUAGE_CHANGE } from '../../../actions/types'

const detectLanguage = () => {
  if (localStorage.getItem('language') || 'it') return localStorage.getItem('language') || 'it'
  if (/^en\b/.test(navigator.language)) return 'en'
  return 'it'
}

const INITIAL_STATE = {
  language: detectLanguage()
}

export const languageReducer = (state = INITIAL_STATE, action) => {
  if (action.type === LANGUAGE_CHANGE) {
    return { language: action.language }
  }
  return state
}
