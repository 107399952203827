import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axiosService from '../../../../services/axios-service'
import getText from '../../../shared/i18n/labels'

// MODALS
import Modal from 'react-bootstrap/Modal'

// FORM
import UploadReceiptEmailForm from './UploadReceiptEmailiForm'

// COMPONENTS
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'

/**
 * Questa funzione serve per visualizzare il popup, con i parametri passati in input
 * @param {*} props {props: tutte le props del padre, fileName: nome del file analizzato, communication: dati di ritorno dal bakend}
 * @returns popup creato
 */
export const ModalProcessingOutComeReceipt = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showReceiptModal, setshowReceiptModal] = useState(true)
  const [errorModal, setErrorModal] = useState(false)
  const [errorModalMessage, setErrorModalMessage] = useState('')

  const companyName = props.nameSubject
  useEffect(() => {
    let communication = props.communication
    if (!communication?.id) {
      setErrorModal(true)
      setErrorModalMessage(communication?.communicationError)
    }
  }, [])

  const closeReceiptModal = () => {
    setshowReceiptModal(false)
    setErrorModal(false)
    setErrorModalMessage('')
  }

  const downloadReceiptTxt = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadReceiptTxt/${communication.subject.id}/${communication.id}`
    callDownloadService(url, 'ATPECRQ.' + communication.sidFilename + '.run.txt')
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file)
        document.body.appendChild(link)
        link.click()
        PopupSuccess({ text: labels.FILE_DOWNLOADED })
      }
    })
  }
  let status = ''
  let communicationStatus = props.communication.communicationStatus
  if (communicationStatus === CommunicationStatus.CREATED) {
    status = labels.CREATED_WAITING_RECEIPT
  } else if (communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
    status = labels.CREATED_WITH_ERRORS
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
    status = labels.COMMUNICATION_ACCEPTED_TOTAL
  } else if (communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR) {
    status = labels.COMMUNICATION_ACCEPTED_PARTIALLY_WITH_ERRORS
  } else if (communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
    status = labels.COMMUNICATION_SCARTED_TOTAL
  } else if (
    communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
  ) {
    status = labels.RECEIPT_ACCEPTED_WITH_ERRORS
  } else if (
    communicationStatus ===
    CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FIND
  ) {
    status = labels.RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FOUND
  } else if (
    communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
  ) {
    status = labels.RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FOUND
  }

  return (
    <Modal
      backdrop="static"
      show={showReceiptModal}
      onHide={closeReceiptModal}
      dialogClassName="modal-70w"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{labels.PROCESSING_RESULT_OF_SID_RECEIPT}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>{'file elaborato: "' + props.fileName + '"'}</h6>
        <h6>
          {labels.SUBJECT_NAME}: {companyName}
        </h6>
        {errorModal ? (
          <div>
            <div className="row">
              <>
                {labels.PROCESSING_RESULT}:&nbsp;
                <strong>{errorModalMessage}</strong>
                <div className={'text-center'}></div>
              </>
            </div>
            <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
              <button className="btn btn-outline-primary " onClick={(e) => closeReceiptModal()}>
                {labels.CANCEL}
              </button>
              <button onClick={(e) => closeReceiptModal()} className="btn btn-primary">
                {labels.OK}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div>
                {labels.PROCESSING_RESULT}:&nbsp;<strong>{status}</strong>
                <div className="w-10 div-td text-truncate d-flex justify-content-around pe-4 ps-4 containerPopOver">
                  <button onClick={() => downloadReceiptTxt(props.communication)}>
                    <div className="txt">
                      <i className="thx-text-file thx-icon" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <UploadReceiptEmailForm
              closeModal={closeReceiptModal}
              subjectId={props.communication?.subject?.id}
              communication={props.communication}
              emailAddress={props.communication?.subject?.coraSubjectSid?.email}
              flagPec={props.communication?.subject?.coraSubjectSid?.flagPec}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
